var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BaseList } from '../../components/BasicList';
import { theme } from '../../theme';
import { FetchInvoices, FetchInvoiceStats } from '../../graphql';
import { MainButton } from '../../components';
import { Link } from 'react-router-dom';
import InvoiceItem from './InvoiceItem';
import { InvoiceFilters } from './filters';
import AddOrderInvoice from './AddOrderInvoice';
import BaseListTitle from '../../components/BasicList/Header/BaseListTitle';
import { BaseUserAppTYpe } from '../../stores';
import AddInvoiceSplitButton from './AddButton';
import { getQueryParams } from '../../utils';
export default inject('store')(observer(function (_a) {
    var config = _a.config, store = _a.store, isTemplate = _a.isTemplate;
    var defaultFilter = __assign({}, (config && config.variables));
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    React.useEffect(function () {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }, []);
    if (!defaultFilter.sortBy)
        defaultFilter.sortBy = 'startDate';
    if (!defaultFilter.fields)
        defaultFilter.fields = isTemplate ? [{ name: 'isTemplate', value: true }] : [];
    var match = getQueryParams(location.href);
    if (match.process) {
        defaultFilter.fields.push({ name: 'process', value: match.process.split(',') });
    }
    // defaultFilter.fields.push({ name: 'startDate', value: toDateOnly((new Date() as any).addDays(-30)) });
    // defaultFilter.fields.push({ name: 'endDate', value: toDateOnly((new Date() as any).addDays(30)) });
    var baseListProps = {
        client: config && config.client,
        query: FetchInvoices,
        defaultFilter: defaultFilter,
        searchFieldsMatches: [
            { key: 'typeSerial', text: langForm.t('form_invoice_serial_prefix') },
            { key: 'serial', text: langForm.t('form_invoice_serial') },
        ],
        searchFields: ['typeSerial', 'serial'],
        sortFields: [
            { name: langForm.t('form_invoice_issue_date_invoice'), value: 'startDate' },
            { name: langForm.t('form_status'), value: 'status' },
            { name: langForm.t('form_invoice_serial'), value: 'serial' },
            {
                name: langForm.t('form_invoice_total'),
                value: 'total',
            },
        ],
    };
    var _defaultFilter = JSON.parse(JSON.stringify(defaultFilter));
    var headerColumns = [
        { size: 1, name: langForm.t('form_invoice_issue_date_invoice'), sortKey: 'startDate' },
        { size: 2, name: langForm.t('form_status'), sortKey: 'status' },
        { size: 2, name: langForm.t('form_invoice_type_invoice_short') },
        { size: 1, name: langForm.t('form_invoice_serial').split(' ')[1], sortKey: 'serial' },
        { size: 2, name: langApp.t('main_order') },
        {
            size: 2,
            name: langForm.t('form_invoice_client'),
        },
        {
            size: 2,
            name: langForm.t('form_invoice_total'),
            sortKey: 'total',
            style: {
                padding: theme.spacing(0, isSmall ? 0 : 1),
                display: 'flex',
                justifyContent: isSmall ? 'flex-start' : 'flex-end',
            },
        },
    ].filter(function (e) { return e; });
    var isCA_FO = store.accountStore.currentUser.appType.find(function (t) {
        return [BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.CARRIER].includes(t);
    });
    var buttons = [
        {
            button: function (buttonProps) {
                if (buttonProps === void 0) { buttonProps = {}; }
                return React.createElement(AddOrderInvoice, { v1: isCA_FO, config: config, buttonProps: buttonProps });
            },
        },
        {
            button: function (buttonProps) {
                if (buttonProps === void 0) { buttonProps = {}; }
                return (React.createElement(Link, { to: "/finance/invoices/create", style: __assign(__assign({}, (isCA_FO ? { width: '100%' } : { paddingLeft: theme.spacing(1) })), { display: 'flex' }) },
                    React.createElement(MainButton, __assign({}, buttonProps, { style: __assign(__assign({}, ((isCA_FO && { width: '100%' }) || {})), ((buttonProps === null || buttonProps === void 0 ? void 0 : buttonProps.style) || {})), id: 'btn-add-invoice', title: langForm.t('form_btn_add') + " " + langApp.t('main_invoice').toLowerCase() }))));
            },
        },
    ];
    var _config = {
        id: "Invoices-" + (isTemplate ? 'templates' : ''),
        hideSort: !isSmall,
        searchMDSize: 4,
        title: (React.createElement(Grid, { container: true, justify: 'space-between' },
            React.createElement(BaseListTitle, { title: isTemplate ? langApp.t('main_invoices-templates') : langApp.t('main_invoices') }),
            !isTemplate ? (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } }, isCA_FO ? React.createElement(AddInvoiceSplitButton, { v2: true, buttons: buttons }) : null)) : null)),
        noData: (React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(5), flexDirection: 'column', alignItems: 'center' } },
            React.createElement(Typography, { variant: 'subtitle1', style: { textAlign: 'center' } }, isTemplate ? langForm.t('form_no_invoice_template_data_text') : langForm.t('form_no_invoice_data_text')))),
        dontSaveFilters: !!match.process,
        hasHeaderSort: true,
        headerColumns: headerColumns,
        rowContent: function (record, onChange) {
            return React.createElement(InvoiceItem, { headerColumns: headerColumns, record: record, onChange: onChange, config: config });
        },
        filters: function (filter, onChange, opt) { return (React.createElement(InvoiceFilters, { isTemplate: isTemplate, config: config, options: opt, filter: filter, emptyFilter: _defaultFilter, defaultFilter: defaultFilter, onChange: onChange, statsQuery: FetchInvoiceStats })); },
    };
    return React.createElement(BaseList, { options: baseListProps, config: _config });
}));
