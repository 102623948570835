var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { OrderRouteType, OrderStatus, } from '../../../../models';
import { ACTION_CHANGE, BaseListItem } from '../../../BasicList';
import { DeleteDialog } from '../../../Dialog';
import OrderQuoteItem from './OrderQuoteItem';
import { useTheme } from '@material-ui/core';
import { toDateOnly } from '../../../../utils';
export default function OrderListItem(_a) {
    var _this = this;
    var _b, _c, _d, _e;
    var store = _a.store, onChange = _a.onChange, record = _a.record, onDeleteItem = _a.onDeleteItem, expandContent = _a.expandContent, style = _a.style, props = __rest(_a, ["store", "onChange", "record", "onDeleteItem", "expandContent", "style"]);
    var currentUser = store.accountStore.currentUser;
    var isToDo = (_c = (_b = props === null || props === void 0 ? void 0 : props.defaultFilter) === null || _b === void 0 ? void 0 : _b.fields) === null || _c === void 0 ? void 0 : _c.find(function (e) { return ['withP44Issues', 'to-do-list'].includes(e.name); });
    var _f = __read(React.useState(!!isToDo), 2), expanded = _f[0], setExpanded = _f[1];
    var _g = __read(React.useState(!!isToDo), 2), wasExpanded = _g[0], setWasExpanded = _g[1];
    var theme = useTheme();
    var _onChange = function () {
        var e = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            e[_i] = arguments[_i];
        }
        store === null || store === void 0 ? void 0 : store.accountStore.refreshMenu();
        onChange && onChange.apply(void 0, __spread(e, [{ refreshFilter: 1 }]));
    };
    var orderId = props.match.params.orderId;
    var isDraft = (orderId && OrderStatus.Draft.toLowerCase() === orderId.toLowerCase()) ||
        (!orderId &&
            (props.match.url.toLowerCase().startsWith('/orders') ||
                props.match.url.toLowerCase().startsWith('/drafts') ||
                props.match.url.toLowerCase().startsWith('/templates')));
    var threeDaysFromNow = new Date();
    threeDaysFromNow.setDate(threeDaysFromNow.getDate() + 3);
    var isOpenOrders = !!window.location.href.match('open-orders');
    var allowToHighlight = isOpenOrders;
    var loading = (record === null || record === void 0 ? void 0 : record.transportations) && (record === null || record === void 0 ? void 0 : record.transportations.length) && ((_e = (_d = record === null || record === void 0 ? void 0 : record.transportations[0]) === null || _d === void 0 ? void 0 : _d.routes) === null || _e === void 0 ? void 0 : _e.find(function (e) { return e.type === OrderRouteType.Preferred; }));
    var pickupAt = ((record === null || record === void 0 ? void 0 : record.sorting_pickupAt) && record.sorting_pickupAt) || (loading === null || loading === void 0 ? void 0 : loading.endDate);
    var isHighlightedWarn = allowToHighlight && pickupAt && pickupAt < toDateOnly(threeDaysFromNow);
    var isHighlightedError = allowToHighlight && pickupAt && (loading === null || loading === void 0 ? void 0 : loading.endDate) < toDateOnly(new Date());
    React.useEffect(function () {
        if (expanded) {
            setWasExpanded(true);
        }
    }, [expanded]);
    var _props = {
        listOptions: props.listOptions,
        defaultFilter: props.defaultFilter,
        config: props.config,
        order: record,
        inProgressView: props.inProgressView,
        onChange: _onChange,
        expanded: expanded,
        setExpanded: setExpanded,
    };
    if (expandContent)
        Object.assign(_props, { expandContent: expandContent });
    if (onDeleteItem) {
        Object.assign(_props, {
            onDeleteItem: function () {
                var e = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    e[_i] = arguments[_i];
                }
                return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, onDeleteItem.apply(void 0, __spread(e))];
                            case 1:
                                _a.sent();
                                _onChange(record, ACTION_CHANGE.SOFT_REFRESH);
                                return [2 /*return*/];
                        }
                    });
                });
            },
        });
        if (isDraft) {
            _props.moreActions = (React.createElement(DeleteDialog, { withoutWrapper: true, withText: true, value: record.orderId, onOk: function (e) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, onDeleteItem(e, record)];
                            case 1:
                                _a.sent();
                                _onChange(record, ACTION_CHANGE.SOFT_REFRESH);
                                return [2 /*return*/];
                        }
                    });
                }); } }));
        }
    }
    return (React.createElement(BaseListItem, { v1: true, record: record, style: __assign(__assign(__assign({}, ((!wasExpanded && style) || {})), (isHighlightedWarn && { border: "0.5px " + theme.palette.warning.light + " solid" })), (isHighlightedError && { border: "1.5px " + theme.palette.error.main + " solid" })) },
        React.createElement(OrderQuoteItem, __assign({}, _props, { status: orderId }))));
}
