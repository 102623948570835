var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { TextOverflow } from '../../../components';
import { i18n, KEYS } from '../../../i18n';
var MAX_TO_REFRESH = 7;
export var TimerToRefresh = function (props) {
    var _a = __read(React.useState(0), 2), timer = _a[0], setTimer = _a[1];
    var _b = __read(React.useState(null), 2), timeOutTimer = _b[0], setTimeOut1 = _b[1];
    React.useEffect(function () {
        if (timeOutTimer)
            clearTimeout(timeOutTimer);
        setTimeOut1(setTimeout(function () {
            setTimer(timer + 1);
            if (timer + 1 === MAX_TO_REFRESH) {
                clearTimeout(timeOutTimer);
                props.onChange();
            }
        }, 1000));
        return function () {
            if (timeOutTimer)
                clearTimeout(timeOutTimer);
        };
    }, [timer]);
    return (React.createElement(Grid, { container: true, xs: 12 },
        React.createElement(TextOverflow, { style: { marginRight: 8 } }, i18n.getResource(i18n.language, KEYS.APP, 'auto_refresh')),
        React.createElement(TextOverflow, { style: { fontWeight: 900 } }, MAX_TO_REFRESH - timer)));
};
