import { CompletePaperLayout } from '@izi-logistics/common/lib/components/CompletePaperLayout';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import SetPassword from './pages/Auth/SetPassword/SetPassword';
import SidebarMenu from './components/common/SidebarMenu';
import { client } from './api/apolloClient';
import Account from './pages/Account/Account';
import ChatContentComponent from './pages/Chat';
import LocationsList from './pages/Locations';

import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
const Companies = React.lazy(() => import('./pages/Companies'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Activity = React.lazy(() => import('./pages/Activity'));
const Users = React.lazy(() => import('./pages/Users'));
const Notifications = React.lazy(() => import('./pages/Notifications'));
const Orders = React.lazy(() => import('./pages/Orders'));
const Guides = React.lazy(() => import('./pages/Guides'));
const Warehouses = React.lazy(() => import('./pages/Warehouse'));
const Fullfilment = React.lazy(() => import('./pages/Fullfilment'));
const Issues = React.lazy(() => import('./pages/Issues'));
const Ratings = React.lazy(() => import('./pages/Ratings'));
const Langs = React.lazy(() => import('./pages/Langs'));
const Terms = React.lazy(() => import('./pages/Terms'));
const FastPayments = React.lazy(() => import('./pages/FastPayments'));
const TermRequests = React.lazy(() => import('./pages/CompanyTerms'));
const OrderPreparingStatusesComponent = React.lazy(() => import('./pages/OrderPreparing'));

export default function RootRoutes() {
  const routes = (
    <Switch>
      <Route exact path="/change-password" component={SetPassword} />
      <Route
        path="/fullfilment"
        render={(p: any) => (
          <PreloadingComponent>
            <Fullfilment {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/ratings"
        render={(p: any) => (
          <PreloadingComponent>
            <Ratings {...p} />
          </PreloadingComponent>
        )}
      />
      <Route path="/locations" component={LocationsList} />
      <Route
        path="/issues"
        render={(p: any) => (
          <PreloadingComponent>
            <Issues {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/terms"
        render={(p: any) => (
          <PreloadingComponent>
            <Terms {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/term-requests"
        render={(p: any) => (
          <PreloadingComponent>
            <TermRequests {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/fast-payments"
        render={(p: any) => (
          <PreloadingComponent>
            <FastPayments {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/warehouses"
        render={(p: any) => (
          <PreloadingComponent>
            <Warehouses {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/users"
        render={(p: any) => (
          <PreloadingComponent>
            <Users {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/companies"
        render={(p: any) => (
          <PreloadingComponent>
            <Companies {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/notifications"
        render={(p: any) => (
          <PreloadingComponent>
            <Notifications {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/activity"
        render={(p: any) => (
          <PreloadingComponent>
            <Activity {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/orders"
        render={(p: any) => (
          <PreloadingComponent>
            <Orders {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/guides"
        render={(p: any) => (
          <PreloadingComponent>
            <Guides {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/langs"
        render={(p: any) => (
          <PreloadingComponent>
            <Langs {...p} />
          </PreloadingComponent>
        )}
      />
      <Route exact path="/account/details" component={Account} />
      <Route exact path="/account/settings" component={Account} />
      <Route path="/account">
        <Redirect to={'/account/details'} />
      </Route>
      <Route path="/chats" component={ChatContentComponent} />
      <Route
        path="/statistics"
        render={(p: any) => (
          <PreloadingComponent>
            <Dashboard {...p} />
          </PreloadingComponent>
        )}
      />
      <Route
        path="/preparation"
        render={(p: any) => (
          <PreloadingComponent>
            <OrderPreparingStatusesComponent {...p} />
          </PreloadingComponent>
        )}
      />
      <Route path="*">
        <Redirect to="/statistics/orders" />
      </Route>
    </Switch>
  );
  return <CompletePaperLayout config={{ client }} sideBarMenuContent={<SidebarMenu />} isAdmin pageContent={routes} />;
}
