var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Typography, Grid, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { i18n, KEYS } from '../../../../i18n';
import { OrderQuoteStatusState, OrderStatus } from '../../../../models';
import { OrderPaymentStatus } from '../../../../models/OrderPayment';
import { onPreventEvent } from '../../../Dialog';
import { DeleteOrder, DeleteOrders, FetchOrders } from '../../../../graphql/order';
import { OrderListFilters } from '../ListFiltes';
import { PrimaryButton } from '../../../Buttons';
import { ACTION_CHANGE, BaseList } from '../../../BasicList';
import { BaseUserAppTYpe, CompanyState, USER_ROLE } from '../../../../stores/common';
import { BASE_TABLE_EVENTS } from '../../../BasicList/types';
import { withChannelListStore } from '../../../../pages/Chat/stores/ChannelList';
import OrderItemRecord from './OrderItemRecord';
import Fab from '@material-ui/core/Fab';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import BaseTooltip from '../../../BaseTooltip';
import { toDateOnly } from '../../../../utils';
import { checkIfOrderDetailsIsHide } from '../../../../utils1';
import { canEdit } from '../../form/utils';
import { USER_ROLE_TOOLS } from '../../../../models/UserRoles';
import { TextOverflow } from '../../../TextOverflow';
import { GuideTypes, getTourSteps } from '../../../../guides';
import PreloadingComponent from '../../../PreloadingComponent';
import OrderUserRating from '../OrderUserRating';
var BasicTour = React.lazy(function () { return import('../../../BasicTour'); });
// const OrderUserRating = React.lazy(() => import('../OrderUserRating'));
var isNoneBrand = !!process.env.REACT_APP_NONE_BRAND;
export var OrderListComponentMain = inject('store')(observer(function (_a) {
    var _b;
    var _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var channelListStore = _a.channelListStore, configDetails = _a.configDetails, bookingRestricted = _a.bookingRestricted, propsComponent = __rest(_a, ["channelListStore", "configDetails", "bookingRestricted"]);
    var urlSearch = new URLSearchParams(location.search);
    var _q = __read(React.useState(null), 2), timeout = _q[0], setTimeout1 = _q[1];
    var isSearch = !!urlSearch.get('search');
    var store = propsComponent.store, statsQuery = propsComponent.statsQuery, searchFields = propsComponent.searchFields, props = __rest(propsComponent, ["store", "statsQuery", "searchFields"]);
    var _r = store.accountStore, isLoggedIn = _r.isLoggedIn, currentUser = _r.currentUser;
    var langMenu = useTranslation(KEYS.MENU);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langToast = useTranslation(KEYS.TOAST);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var _s = __read(React.useState(1), 2), updated = _s[0], setUpdated = _s[1];
    var _t = __read(React.useState(false), 2), isGuideAvailable = _t[0], setGuideAvailable = _t[1];
    var defaultFilter = {};
    var orderId = props.match.params.orderId;
    var theme = useTheme();
    // const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var listItemProps = __assign({}, propsComponent);
    var isCarrier = store.accountStore.currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isCustomer = store.accountStore.currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isForwarder = store.accountStore.currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var approvedQuotes = props.match.url.toLowerCase().startsWith('/in-progress');
    var openOrders = props.match.url.toLowerCase().startsWith('/open-orders');
    var finished = props.match.url.toLowerCase().startsWith('/archive');
    var orderAnalytics = props.match.url.toLowerCase().startsWith('/all-approved');
    var isTemplate = props.match.url.toLowerCase().startsWith('/templates');
    var isPreparation = props.match.url.toLowerCase().startsWith('/preparation');
    // const isDrafts = props.match.url.toLowerCase().startsWith('/drafts');
    var isRating = (_d = (_c = props.defaultFilter) === null || _c === void 0 ? void 0 : _c.fields) === null || _d === void 0 ? void 0 : _d.find(function (e) { return ['to-do-ca-rating-list'].includes(e.name); });
    var lastDayPrevMonth = new Date();
    lastDayPrevMonth.setDate(0);
    var firstDayPrevMonth = new Date(lastDayPrevMonth.getFullYear(), lastDayPrevMonth.getMonth(), 1);
    var restrictedOrdersAccess = store.accountStore.currentCompany.state.includes(CompanyState.ORDER_ACCESS_RESTRICTION);
    var wizardBackground = document.getElementById('wizard-clickable-background');
    var guides = currentUser.settings.guides;
    var carrierCountries = (_e = (JSON.parse(JSON.stringify(currentUser.settings.filters)) || []).find(function (e) { return e.name === 'countries'; })) === null || _e === void 0 ? void 0 : _e.value;
    var toDoNotAvailableOrClose = store.accountStore.totalToDo === 0 ||
        (store.accountStore.totalToDo > 0 && store.accountStore.isToDoClosed) ||
        (store.accountStore.totalToDoRatingCa > 0 && store.accountStore.isToDoCARatingClosed);
    var wizardNotAvailableOrClose = !wizardBackground || (wizardBackground && store.accountStore.isSettingsWizardClosed);
    var showOpenOrdersGuide = isCarrier &&
        carrierCountries &&
        openOrders &&
        isLoggedIn &&
        isGuideAvailable &&
        toDoNotAvailableOrClose &&
        wizardNotAvailableOrClose &&
        (!guides || (guides && !guides[GuideTypes.CARRIER_OPEN_ORDERS_LIST]));
    var events = restrictedOrdersAccess
        ? [
            {
                ctx: document.body,
                name: BASE_TABLE_EVENTS.REFRESH_ON_NEW_CA_OFFER,
                callback: function () {
                    setUpdated(Date.now());
                },
            },
        ]
        : openOrders
            ? [
                {
                    ctx: document.body,
                    name: BASE_TABLE_EVENTS.REFRESH_ON_ACCEPT_OFFER,
                    callback: function () {
                        setUpdated(Date.now());
                    },
                },
            ]
            : [];
    React.useEffect(function () {
        events.forEach(function (ev) { return ev.ctx.addEventListener(ev.name, ev.callback); });
        return function () {
            events.forEach(function (ev) { return ev.ctx.removeEventListener(ev.name, ev.callback); });
        };
    }, []);
    React.useEffect(function () {
        setTimeout1(setTimeout(function () {
            setGuideAvailable(true);
        }, 1000));
        return function () {
            if (timeout)
                clearTimeout(timeout);
        };
    }, []);
    var isDraft = (orderId && OrderStatus.Draft.toLowerCase() === orderId.toLowerCase()) ||
        (!orderId &&
            (props.match.url.toLowerCase().startsWith('/orders') ||
                props.match.url.toLowerCase().startsWith('/drafts') ||
                isTemplate));
    var title = props.title ||
        (!orderId &&
            ((approvedQuotes && langMenu.t("menu_sidebar_in_progress_title")) ||
                (openOrders && langMenu.t("menu_sidebar_open_orders_title")) ||
                '')) ||
        ((finished || isDraft) &&
            langMenu.t("menu_sidebar_" + ((finished && 'archive') ||
                (isDraft &&
                    ((props.match.url.toLowerCase().startsWith('/drafts') && 'draft') || (isTemplate && 'template')))) + "_title")) ||
        OrderStatus.Draft ||
        langMenu.t('menu_sidebar_open_orders_offer_requested');
    if (finished) {
        defaultFilter.sortBy = 'sorting_pickupAt';
        defaultFilter.fields = [
            {
                name: 'status',
                value: OrderStatus.Finished,
            },
        ];
    }
    else if (approvedQuotes) {
        defaultFilter.sortBy = 'sorting_pickupAt';
        defaultFilter.fields = [
            {
                name: 'status',
                value: OrderStatus.ApprovedQuote,
            },
            {
                name: 'all-in-progress',
                value: 1,
            },
        ];
        title = langMenu.t('menu_sidebar_in_progress_title');
    }
    else if (openOrders) {
        defaultFilter.sortBy = 'sorting_pickupAt';
        defaultFilter.sortDir = 'asc';
        defaultFilter.fields = [
            {
                name: 'all-open',
                value: 1,
            },
            isCarrier &&
                !isSearch && {
                name: 'hidePlanned',
                value: 1,
            },
            isCarrier && !isSearch && { name: 'useMyPreferences', value: true },
        ].filter(function (e) { return e; });
        title = langMenu.t('menu_sidebar_open_orders_title');
    }
    else if (isPreparation) {
        defaultFilter.fields = [
            {
                name: 'all-preparation',
                value: 1,
            },
        ].filter(function (e) { return e; });
        title = langApp.t('app_preparation');
    }
    else {
        defaultFilter.fields = [
            {
                name: 'status',
                value: OrderStatus.Draft,
            },
            isTemplate && {
                name: 'isTemplate',
                value: isTemplate,
            },
        ].filter(function (e) { return e; });
    }
    if (!defaultFilter.sortBy && (approvedQuotes || openOrders || finished)) {
        defaultFilter.sortBy = 'publishedAt';
    }
    if ((_f = configDetails === null || configDetails === void 0 ? void 0 : configDetails.defaultFilterV1) === null || _f === void 0 ? void 0 : _f.fields) {
        (_b = defaultFilter.fields).push.apply(_b, __spread((_g = configDetails === null || configDetails === void 0 ? void 0 : configDetails.defaultFilterV1) === null || _g === void 0 ? void 0 : _g.fields));
    }
    if (!isPreparation && ((_k = (_j = (_h = store.accountStore) === null || _h === void 0 ? void 0 : _h.currentCompany) === null || _j === void 0 ? void 0 : _j.settings) === null || _k === void 0 ? void 0 : _k.isViewAllOrders)) {
        defaultFilter.fields.push({ name: 'viewAll', value: true });
    }
    var orderAnalyticsDefaultFields = [
        { name: 'status', value: ['finished', 'approved'], category: 1 },
        { name: 'all-finished', value: 1 },
    ];
    if (orderAnalytics) {
        defaultFilter.fields = __spread(orderAnalyticsDefaultFields, [
            { name: 'factualPickupRange', value: [toDateOnly(firstDayPrevMonth), toDateOnly(lastDayPrevMonth)] },
        ]);
        title = langMenu.t('all-approved');
    }
    if (((_m = (_l = store.accountStore.currentUser.settings.filters) === null || _l === void 0 ? void 0 : _l.find(function (e) { return e.name === 'hidePlanned'; })) === null || _m === void 0 ? void 0 : _m.value) === '0') {
        defaultFilter.fields = defaultFilter.fields.filter(function (e) { return e.name !== 'hidePlanned'; });
        defaultFilter.fields.push({ name: 'hidePlanned', value: false });
    }
    var _defaultFilter = JSON.parse(JSON.stringify(__assign(__assign({}, defaultFilter), (orderAnalytics
        ? {
            fields: orderAnalyticsDefaultFields,
        }
        : {}))));
    var queries = ['status', 'payment', 'state'];
    queries.forEach(function (query) {
        var canHaveFilter = false;
        var val = urlSearch.get(query);
        if (query === queries[0]) {
            canHaveFilter = Object.values(OrderStatus).includes(val);
        }
        else if (query === queries[1]) {
            canHaveFilter = Object.values(OrderPaymentStatus).includes(val);
        }
        else if (query === queries[2]) {
            canHaveFilter = Object.values(OrderQuoteStatusState).includes(val);
        }
        if (canHaveFilter) {
            Object.assign(defaultFilter, {
                fields: [
                    {
                        name: query,
                        value: [val],
                        temp: true,
                    },
                ],
            });
        }
    });
    var onLoad = function (_a) {
        var data = _a.data;
        channelListStore === null || channelListStore === void 0 ? void 0 : channelListStore.setChannelReadStatusData(data.map(function (_a) {
            var _id = _a._id, totalUnread = _a.totalUnread;
            return ({ _id: _id, totalUnread: totalUnread });
        }));
    };
    var onMultiDelete = function (_ids) { return __awaiter(void 0, void 0, void 0, function () {
        var client_1, e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    client_1 = props.config && props.config.client;
                    return [4 /*yield*/, client_1.mutate({ mutation: DeleteOrders, variables: { _ids: _ids } })];
                case 1:
                    _a.sent();
                    toast.success(langToast.t('toast_items_deleted').replace(/_X_/, langApp.t('main_order')));
                    store.accountStore.refreshMenu();
                    setUpdated(Date.now());
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var isDelete = openOrders || isDraft;
    var client = props.config && props.config.client;
    var isToDo = (_p = (_o = props.defaultFilter) === null || _o === void 0 ? void 0 : _o.fields) === null || _p === void 0 ? void 0 : _p.find(function (e) {
        return ['to-do-list', 'to-do-ca-rating-list', 'withP44Issues'].includes(e.name);
    });
    var baseListProps = {
        isNotReloading: true,
        onLoad: onLoad,
        canSelect: function (record) {
            return ((record.createdBy && record.createdBy._id) || record.createdBy) === store.accountStore.currentUser._id ||
                store.accountStore.currentUser.roles.includes(USER_ROLE.ADMIN);
        },
        client: client,
        useUrlQuery: !isToDo,
        query: FetchOrders,
        onSaveFilters: function (opt) {
            var _a;
            if (openOrders) {
                var filters = JSON.parse(JSON.stringify(currentUser.settings.filters)) || [];
                var hidePlanned = ((_a = opt.updateFilter.fields.find(function (e) { return e.name === 'hidePlanned'; })) === null || _a === void 0 ? void 0 : _a.value) || false;
                var item = filters.find(function (e) { return e.name === 'hidePlanned'; });
                if (!item) {
                    filters.push({ name: 'hidePlanned', value: hidePlanned ? '1' : '0', type: 'boolean' });
                }
                else {
                    item.value = hidePlanned ? '1' : '0';
                }
                currentUser.updateSettings({
                    filters: filters,
                }, '', null, 1);
            }
        },
        defaultFilter: props.defaultFilter || defaultFilter,
        searchFieldsMatches: [
            {
                name: 'orderId',
                text: langOrderForm.t('create_order_general_id').toLowerCase(),
            },
            { name: 'orderCustomId', text: langOrderForm.t('order_form_customer_order_number').toLowerCase() },
            {
                name: 'searchKey',
                text: isCarrier
                    ? [langForm.t('form_input_name_address').toLowerCase()].join(', ')
                    : [langApp.t('company_label').toLowerCase(), langForm.t('form_input_name_address').toLowerCase()].join(', '),
            },
        ],
        searchFields: __spread(['orderId', 'orderCustomId', 'searchKey'], (searchFields || [])),
        sortFields: __spread([
            approvedQuotes || openOrders || finished
                ? { name: langOrderForm.t('create_order_status_published'), value: 'publishedAt' }
                : { name: langOrderList.t('order_list_filter_sort_created_at'), value: 'createdAt' },
            {
                name: langOrderList.t('order_list_filter_sort_loading_at'),
                value: 'sorting_pickupAt',
            },
            {
                name: langOrderForm.t('create_order_route_origin_to_date'),
                value: 'sorting_deliveryAt',
            }
        ], (!orderAnalytics
            ? [
                {
                    name: langForm.t('form_input_country'),
                    value: 'sorting_countryPickup',
                },
                { name: langOrderList.t('order_list_filter_manager'), value: 'manager.firstName' },
                {
                    name: langOrderList.t('in_progress_has_cmr'),
                    value: 'sorting_hasCMR',
                },
                {
                    name: langOrderList.t('in_progress_has_invoice'),
                    value: 'sorting_hasInvoice',
                },
            ]
            : [
                {
                    name: langOrderForm.t('order_form_actual_pickup_date'),
                    value: 'sorting_hasLoadedAt',
                },
                {
                    name: langOrderForm.t('order_form_actual_delivery_date'),
                    value: 'sorting_hasUnloadedAt',
                },
            ])).filter(function (e) { return e; }),
    };
    function titleHtml() {
        return {
            __html: openOrders && (isCustomer || isForwarder)
                ? langOrderList.t('order_open_orders_list_no_data_text')
                : langOrderList.t('order_list_no_data_text').replace(/_X_/, "<b>" + title.toLowerCase() + "</b>"),
        };
    }
    var rowContent = function (record, onChange, options) {
        var onDeleteItem = function (e, order) { return __awaiter(void 0, void 0, void 0, function () {
            var client_2, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        onPreventEvent(e);
                        client_2 = props.config && props.config.client;
                        return [4 /*yield*/, client_2.mutate({ mutation: DeleteOrder, variables: { _id: order._id } })];
                    case 1:
                        _a.sent();
                        toast.success(langToast.t('toast_items_deleted').replace(/_X_/, langApp.t('main_order')));
                        store.accountStore.refreshMenu();
                        if (onChange)
                            onChange(record, ACTION_CHANGE.SOFT_REFRESH);
                        setTimeout(function () {
                            //Update Apollo Cache
                            client_2.resetStore();
                            //Update Apollo Cache
                        }, 1000);
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        toast.error(e_2.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        return (React.createElement(OrderItemRecord, __assign({}, {
            onChange: function () {
                var e = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    e[_i] = arguments[_i];
                }
                console.log('onChange', e);
                if (props.onChange)
                    props.onChange.apply(props, __spread(e));
                onChange.apply(void 0, __spread(e));
            },
            record: record,
            config: props.config,
            listItemProps: __assign(__assign({}, listItemProps), { defaultFilter: baseListProps.defaultFilter, inProgressView: orderAnalytics, listOptions: options }),
            isDelete: isDelete,
            onDeleteItem: onDeleteItem,
        })));
    };
    if (isRating) {
        rowContent = function (record) { return (
        // <PreloadingComponent>
        React.createElement(Grid, { container: true, style: { alignItems: 'center', justifyContent: 'center' } },
            React.createElement(OrderUserRating, { quote: record.latestQuote, noneModal: true, order: record, config: props.config, onChange: props.onChange }))
        // </PreloadingComponent>
        ); };
    }
    var config = __assign(__assign(__assign(__assign({}, configDetails === null || configDetails === void 0 ? void 0 : configDetails.config), { showFilters: !isToDo, hideHeader: isToDo, hideAllFilters: isToDo || bookingRestricted, isSelectable: !isToDo && (isDraft || isTemplate), useOrderId: true }), (isToDo && { additionalPaginationOptions: ['2'] })), { canSelect: function (record) {
            return (!checkIfOrderDetailsIsHide(store, record) &&
                canEdit(record, store, { inProgress: true }) &&
                store.accountStore.currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); }));
        }, title: (configDetails === null || configDetails === void 0 ? void 0 : configDetails.title) || ((configDetails === null || configDetails === void 0 ? void 0 : configDetails.noTitle) ? null : title), onMultiDelete: onMultiDelete,
        rowContent: rowContent, availableFilters: __spread((((isDraft || isTemplate) && [
            {
                name: 'pickupRangeDelivery',
            },
        ]) ||
            []), ((openOrders && [
            {
                name: 'pickupRangeDelivery',
            },
            {
                name: 'hideUnFinishedPreparationOrders',
            },
            isCarrier && {
                name: 'hidePlanned',
            },
        ]) ||
            []), ((isPreparation && [
            {
                name: 'pickupRangeDelivery',
            },
            {
                name: 'hideFinishedPreparationOrders',
            },
        ]) ||
            []), (approvedQuotes && [
            {
                name: 'hideDeliveredOrders',
            },
            {
                name: 'approvedCarrier',
            },
        ]), ((approvedQuotes || finished) && [
            {
                name: 'pickupRangeDelivery',
            },
            {
                name: 'hideInnerOrders',
            },
        ]), (orderAnalytics && [{ name: 'factualPickupRangeDelivery' }]), [
            {
                name: 'countryFromTo',
            },
            {
                name: 'useMyPreferences',
            },
        ]).filter(function (e) { return e; }), dontSaveFilters: true, filtersV1: function (filter, onChange, opt) { return (React.createElement(OrderListFilters, __assign({ config: { client: client }, options: opt, filter: filter, emptyFilter: _defaultFilter, defaultFilter: defaultFilter, onChange: onChange, isDraft: isDraft, isCarrier: isCarrier, finished: finished, orderAnalytics: orderAnalytics, openOrders: openOrders, approvedQuotes: approvedQuotes, statsQuery: statsQuery }, configDetails === null || configDetails === void 0 ? void 0 : configDetails.filters))); }, noData: isToDo ? (React.createElement(TextOverflow, { style: {
                color: theme.palette.success.light,
            } }, langOrderList.t('in_progress_you_are_doing_great'))) : !isCarrier ? (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: 'subtitle1', dangerouslySetInnerHTML: titleHtml(), style: { margin: theme.spacing(2, 0), textAlign: 'center' } }),
            (isCustomer || isForwarder) && (React.createElement(Link, { to: '/orders/create', style: { textDecoration: 'none' } },
                React.createElement(PrimaryButton, null, langMenu.t('menu_sidebar_new_order_title')))))) : (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: 'subtitle1', style: { margin: theme.spacing(2, 0), textAlign: 'center' } }, langForm.t('form_no_orders_filter_text')))) });
    var key = store.accountStore.refreshList + "-" + orderId + "-" + updated;
    var list = (React.createElement(React.Fragment, null,
        React.createElement(BaseList, { key: key, options: baseListProps, config: config }),
        showOpenOrdersGuide ? (React.createElement(PreloadingComponent, null,
            React.createElement(BasicTour
            // hideClose
            , { 
                // hideClose
                client: client, steps: getTourSteps(GuideTypes.CARRIER_OPEN_ORDERS_LIST, i18n, KEYS), updateSettings: true }))) : null));
    if (isNoneBrand && approvedQuotes) {
        return (React.createElement(Grid, { container: true, direction: 'column', style: { position: 'relative' } },
            list,
            React.createElement(BaseTooltip, { title: 'importuoti/eksportuoti (*CSV)' },
                React.createElement(Fab, { color: "primary", "aria-label": "add", style: { position: 'fixed', zIndex: 99999, bottom: 20, right: 50 } },
                    React.createElement(ImportExportIcon, null)))));
    }
    return list;
}));
export var OrderListComponent = withChannelListStore(OrderListComponentMain);
