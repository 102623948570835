var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect } from 'react';
import { withBaseListStore } from './store';
import { BASE_TABLE_EVENTS } from './types';
import { BaseListHeader } from './Header';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BaseListBody } from './Body';
import { BaseListFooter } from './Footer';
import ResetFilter from './ResetFilter';
import BoxComponent from '-!svg-react-loader!../assets/OrderEmptyState.svg';
import { usePreloadederBlocker } from '../../styles';
var BaseListComponent = function (_a) {
    var _b, _c;
    var loadData = _a.loadData, state = _a.state, config = _a.config, isDefaultFilter = _a.isDefaultFilter, orderListItemProps = _a.orderListItemProps, props = __rest(_a, ["loadData", "state", "config", "isDefaultFilter", "orderListItemProps"]);
    var langApp = useTranslation(KEYS.APP);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var spreloaderBlocker = usePreloadederBlocker();
    var style = {
        width: '100%',
        maxWidth: "calc(100vw - " + (config.isSelectable ? 161 : 104) + "px)",
    };
    var events = [
        (config === null || config === void 0 ? void 0 : config.withSoftRefresh) && {
            name: BASE_TABLE_EVENTS.REFRESH_DATA,
            ctx: document.body,
            callback: function (e) {
                var _a, _b;
                if (((_b = (_a = e.detail) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.id) === config.id)
                    loadData();
            },
        },
    ].filter(function (e) { return e; });
    useEffect(function () {
        events.forEach(function (ev) { return ev.ctx.addEventListener(ev.name, ev.callback); });
        loadData();
        return function () {
            events.forEach(function (ev) { return ev.ctx.removeEventListener(ev.name, ev.callback); });
        };
    }, []);
    var theme = useTheme();
    // const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var noDataComponent = (React.createElement(BoxComponent, { style: __assign({ height: theme.spacing(20), margin: theme.spacing(5, 0, 3, 0) }, (config.noDataImageStyle || {})) }));
    if (useMediaQuery(theme.breakpoints.down('sm'))) {
        Object.assign(style, {
            maxWidth: "100%",
        });
    }
    return (React.createElement("div", { style: style, id: "base-table-" + (config.id || config.title), "data-total": state.count },
        (((_b = state.error) === null || _b === void 0 ? void 0 : _b.message) && React.createElement(Typography, { color: 'error' }, (_c = state.error) === null || _c === void 0 ? void 0 : _c.message)) || null,
        config.hideHeader ? null : React.createElement(BaseListHeader, { config: config }),
        state.loaded && (state.count === 0 || (state.count && !state.data.length)) ? (isDefaultFilter() ? (React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', id: "base-table-" + config.title + "-no-data" },
            config.hideNoDataIcon ? null : config.noDataImage || noDataComponent,
            config.noData ? (config.noData) : (React.createElement(Typography, null, langOrderList.t('order_list_filter_no_data_general'))))) : (React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', id: "base-table-" + config.title + "-filters", style: { marginTop: theme.spacing(5) } },
            config.hideNoDataIcon ? null : config.noDataImage || noDataComponent,
            state.hasUserFilters ? (React.createElement(Typography, { "data-id": "no-data-found", variant: 'subtitle1' }, langApp.t('app_no_data_found'))) : config.noData ? (config.noData) : (React.createElement(React.Fragment, null,
                React.createElement(Typography, { variant: 'subtitle1' }, langOrderList.t('order_list_filter_no_data_general')))),
            React.createElement(Grid, { container: true, style: { width: 'fit-content', marginTop: theme.spacing(1) } },
                React.createElement(ResetFilter, __assign({}, props, { config: config })))))) : (React.createElement(Grid, { container: true, justify: 'center', id: "base-table-" + config.title + "-loader", style: { position: 'relative' } },
            state.loading ? (React.createElement(Grid, { container: true, justify: 'center', className: spreloaderBlocker.root },
                React.createElement(CircularProgress, null))) : null,
            React.createElement(BaseListBody, { config: config }),
            config.footer,
            (!config.hideFooter && (React.createElement(BaseListFooter, { disabled: state.data.length && state.loading, id: "base-table-" + config.title + "-footer", config: config }))) ||
                null)),
        config.bottomTitle));
};
export default withBaseListStore(BaseListComponent);
