var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { withBaseListStore } from '../store';
import { theme } from '../../../theme';
import { BaseSelect } from '../../Formik/FormikSelect';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../../i18n';
import BaseTooltip from '../../BaseTooltip';
import BaseIconButton from '../../BaseIconButton';
export var BaseListFooter = withBaseListStore(function (_a) {
    var state = _a.state, onChangeFilter = _a.onChangeFilter, config = _a.config, disabled = _a.disabled, props = __rest(_a, ["state", "onChangeFilter", "config", "disabled"]);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    if (!state.hasFooterPagination) {
        return null;
    }
    // if (state.count <= state.filter.limit) return null;
    var onChange = function (e, page) {
        onChangeFilter({ page: page });
    };
    var onChangePerPage = function (e) {
        var _a;
        var _b = e.target, value = _b.value, name = _b.name;
        onChangeFilter((_a = {},
            _a[name] = parseInt((value || '10').toString()),
            _a));
    };
    return config.isShort ? (React.createElement(Grid, { id: props.id, container: true, justify: 'center', style: { paddingLeft: 6 } },
        React.createElement(BaseTooltip, { title: (i18n.getResource(i18n.language, KEYS.APP, 'calendar_label_previous_period') || '').replace('_X_', (i18n.getResource(i18n.language, KEYS.APP, 'page') || '').toLowerCase()) },
            React.createElement(BaseIconButton, { style: { padding: 2 }, onClick: function (e) { return !disabled && onChange(e, state.filter.page - 1); }, disabled: disabled || state.filter.page - 1 <= 0 },
                React.createElement(ArrowBackIosIcon, __assign({}, (state.filter.page - 1 > 0 && { color: 'primary' }))))),
        React.createElement(BaseTooltip, { title: (i18n.getResource(i18n.language, KEYS.APP, 'calendar_label_next_period') || '').replace('_X_', (i18n.getResource(i18n.language, KEYS.APP, 'page') || '').toLowerCase()) },
            React.createElement(BaseIconButton, { style: { padding: 2 }, onClick: function (e) { return !disabled && onChange(e, state.filter.page + 1); }, disabled: disabled || state.filter.page * state.filter.limit + 1 >= state.count },
                React.createElement(ArrowForwardIosIcon, __assign({}, (state.filter.page * state.filter.limit + 1 < state.count && { color: 'primary' }))))))) : (React.createElement(Grid, { id: props.id, container: true, justify: 'flex-end', alignItems: 'center', style: { padding: theme.spacing(2) } },
        state.filter.limit === 1 ? null : (React.createElement(BaseSelect, { v18: true, fullWidth: false, name: "limit", disabled: disabled, style: { marginLeft: theme.spacing(1), width: 'initial' }, title: langOrderList.t('order_list_filter_pagination_per_page'), value: state.filter.limit, onChange: !disabled && onChangePerPage, options: __spread((config.additionalPaginationOptions || []), ['10', '25', '50']).filter(function (e) { return e; })
                .sort(function (a, b) { return parseInt(a) - parseInt(b); }) })),
        React.createElement(Pagination, { disabled: disabled, style: { marginLeft: theme.spacing(1) }, count: Math.ceil(state.count / state.filter.limit), page: state.filter.page, onChange: !disabled && onChange, color: "primary" })));
});
