import React from 'react';
import { useTranslation } from 'react-i18next';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { BasicSideBar } from '@izi-logistics/common/lib/components';
import { BasicSideBarMenuItem } from '@izi-logistics/common/lib/components';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import BusinessIcon from '@material-ui/icons/Business';
import { KEYS } from '@izi-logistics/common/lib/i18n';
import ChannelChatIcon from '@izi-logistics/common/lib/pages/Chat/ChannelChatIcon';
import UserSettingsPaymentTermIcon from '@izi-logistics/common/lib/components/UserSettingsPaymentTerm/icon';
import { PaymentTermChannelsSocket } from '@izi-logistics/common/lib/components/UserSettingsPaymentTerm/store';
import ListAltIcon from '@material-ui/icons/ListAlt';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import { WarehouseIcon, DashboardIcon, PreparationIcon } from '@izi-logistics/common/lib/components/icons';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';
import { client } from '../../api/apolloClient';
import GradeIcon from '@material-ui/icons/Grade';
import LanguageIcon from '@material-ui/icons/Language';
import LayersIcon from '@material-ui/icons/Layers';
import PaymentIcon from '@material-ui/icons/Payment';
import ReportIssue from '@izi-logistics/common/lib/components/ReportIssue/icon';

const SidebarMenu = () => {
  const { t } = useTranslation('SidebarMenu');
  const langMenu = useTranslation(KEYS.MENU);
  const langForm = useTranslation(KEYS.FORM);
  const langApp = useTranslation(KEYS.APP);

  const menu: BasicSideBarMenuItem[] = [
    {
      icon: (
        <span>
          <DashboardIcon title={langMenu.t('menu_sidebar_dashboard')} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_dashboard'),
      path: '/',
      _id: 'menu-sidebar-dashboard',
    },
    {
      icon: <GroupOutlinedIcon />,
      title: t('users'),
      path: '/users',
      _id: 'menu-sidebar-users',
    },
    {
      icon: <BusinessIcon />,
      title: langMenu.t('menu_sidebar_companies_title'),
      path: '/companies',
      _id: 'menu-sidebar-companies',
    },
    {
      icon: <EqualizerIcon />,
      title: 'Activity',
      path: '/activity',
      _id: 'menu-sidebar-activity',
    },
    {
      icon: <NotificationImportantIcon />,
      title: 'Notifications',
      path: '/notifications',
      _id: 'menu-sidebar-notifications',
    },
    {
      icon: <ListAltIcon />,
      title: 'Orders',
      path: '/orders',
      _id: 'menu-sidebar-orders',
    },
    {
      icon: (
        <span>
          <ChannelChatIcon title={langMenu.t('menu_sidebar_chat_title')} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_chat_title'),
      path: '/chats',
      _id: 'menu-sidebar-chats',
    },

    {
      icon: (
        <span>
          <WarehouseIcon title={langMenu.t('menu_sidebar_warehouse') as string} />
        </span>
      ),
      title: langMenu.t('menu_sidebar_warehouse'),
      path: '/warehouses',
      _id: 'menu-sidebar-warehouses',
    },
    {
      icon: <HourglassFullIcon />,
      title: langForm.t('form_terms_fullfilment'),
      path: '/fullfilment',
      _id: 'menu-sidebar-fullfilment',
    },
    {
      icon: <ReportIssue isAdmin />,
      title: langApp.t('main_issue'),
      path: '/issues',
      _id: 'menu-sidebar-issues',
    },
    {
      icon: <GradeIcon />,
      title: langForm.t('form_input_rating_label'),
      path: '/ratings',
      _id: 'menu-sidebar-ratings',
    },
    {
      icon: <LanguageIcon />,
      title: 'Langs',
      path: '/langs',
      _id: 'menu-sidebar-langs',
    },

    {
      icon: <LayersIcon />,
      title: langApp.t('main_terms'),
      path: '/terms',
      _id: 'menu-sidebar-terms',
    },
    {
      icon: <PaymentIcon />,
      title: langApp.t('main_fast_payments'),
      path: '/fast-payments',
      _id: 'menu-sidebar-fast-payments',
    },
    {
      icon: (
        <PaymentTermChannelsSocket>
          <UserSettingsPaymentTermIcon v1 />
        </PaymentTermChannelsSocket>
      ),
      title: 'Company term requested',
      path: '/term-requests',
      _id: 'menu-sidebar-term-requests',
    },
    {
      icon: (
        <span>
          <PreparationIcon title={langApp.t('app_preparation')} />
        </span>
      ),
      title: langApp.t('app_preparation'),
      path: '/preparation',
      _id: 'menu-sidebar-preparation',
    },

    {
      icon: <LocationOnIcon />,
      title: langMenu.t('menu_sidebar_locations_title'),
      path: '/locations',
      _id: 'menu-sidebar-locations',
      isHiddenUnderOther: true,
    },
    {
      icon: <HelpOutlineIcon />,
      title: langApp.t('app_guides'),
      path: '/guides',
      _id: 'menu-sidebar-guides',
    },
  ];

  return <BasicSideBar menu={menu} config={{ client }} isAdmin />;
};
export default SidebarMenu;
