var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid, Typography, useMediaQuery } from '@material-ui/core';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BaseList } from '../../components/BasicList';
import { theme } from '../../theme';
import { MainButton } from '../../components';
import CostItem from './CostItem';
import { FetchCosts, FetchCostsStats } from '../../graphql/invoices/costs';
import { Link } from 'react-router-dom';
import { InvoiceFilters } from '../Invoices/filters';
import BaseListTitle from '../../components/BasicList/Header/BaseListTitle';
export default inject('store')(observer(function (_a) {
    var config = _a.config, isTemplate = _a.isTemplate;
    var defaultFilter = __assign({}, (config && config.variables));
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    React.useEffect(function () {
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
    }, []);
    if (!defaultFilter.sortBy)
        defaultFilter.sortBy = 'startDate';
    if (!defaultFilter.fields)
        defaultFilter.fields = isTemplate ? [{ name: 'isTemplate', value: true }] : [];
    // defaultFilter.fields.push({ name: 'startDate', value: toDateOnly((new Date() as any).addDays(-30)) });
    // defaultFilter.fields.push({ name: 'endDate', value: toDateOnly((new Date() as any).addDays(30)) });
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var _defaultFilter = JSON.parse(JSON.stringify(defaultFilter));
    var baseListProps = {
        client: config && config.client,
        query: FetchCosts,
        defaultFilter: defaultFilter,
        searchFieldsMatches: [
            { key: 'typeSerial', text: langForm.t('form_invoice_serial_prefix') },
            { key: 'serial', text: langForm.t('form_invoice_serial') },
        ],
        searchFields: ['typeSerial', 'serial'],
        sortFields: [
            { name: langForm.t('form_invoice_issue_date_invoice'), value: 'startDate' },
            { name: langForm.t('form_status'), value: 'status' },
            { name: langForm.t('form_invoice_serial'), value: 'serial' },
            {
                name: langForm.t('form_invoice_total'),
                value: 'total',
            },
        ],
    };
    var headerColumns = [
        { size: 1, name: langForm.t('form_invoice_issue_date_invoice'), sortKey: 'startDate' },
        { size: 2, name: langForm.t('form_status'), sortKey: 'status' },
        { size: 2, name: langForm.t('form_invoice_type_invoice_short') },
        { size: 1, name: langForm.t('form_invoice_serial').split(' ')[0], sortKey: 'serial' },
        { size: 2, name: langApp.t('main_order') },
        {
            size: 2,
            name: langForm.t('form_invoice_seller'),
        },
        {
            size: 2,
            sortKey: 'total',
            name: langForm.t('form_invoice_total'),
            style: {
                padding: theme.spacing(0, isSmall ? 0 : 1),
                display: 'flex',
                justifyContent: isSmall ? 'flex-start' : 'flex-end',
            },
        },
    ].filter(function (e) { return e; });
    var _config = {
        id: "Costs-" + (isTemplate ? 'templates' : ''),
        hideSort: !isSmall,
        hasHeaderSort: true,
        searchMDSize: 4,
        title: (React.createElement(Grid, { container: true, justify: 'space-between' },
            React.createElement(BaseListTitle, { title: isTemplate ? langApp.t('main_costs-templates') : langApp.t('main_costs') }),
            !isTemplate ? (React.createElement(Link, { to: "/finance/costs/create" },
                React.createElement(MainButton, { id: 'btn-add-invoice', title: langForm.t('form_btn_add') + " " + langApp.t('main_costs').toLowerCase() }))) : null)),
        noData: (React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(5), flexDirection: 'column', alignItems: 'center' } },
            React.createElement(Typography, { variant: 'subtitle1', style: { textAlign: 'center' } }, isTemplate ? langForm.t('form_no_cost_template_data_text') : langForm.t('form_no_cost_data_text')))),
        headerColumns: headerColumns,
        rowContent: function (record, onChange) {
            return React.createElement(CostItem, { headerColumns: headerColumns, record: record, onChange: onChange, config: config });
        },
        filters: function (filter, onChange, opt) { return (React.createElement(InvoiceFilters, { config: config, options: opt, filter: filter, emptyFilter: _defaultFilter, defaultFilter: defaultFilter, onChange: onChange, statsQuery: FetchCostsStats })); },
    };
    return React.createElement(BaseList, { options: baseListProps, config: _config });
}));
