var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { BaseSearchComponentRef } from './BaseSearch';
import React, { useEffect } from 'react';
import { Grid, Collapse, Divider, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import { TextOverflow } from './TextOverflow';
import { NoData } from './NoData';
import { FetchGlobalSearch } from '../graphql/globalSearch';
import { SearchOrderView } from './Order/search/OrderView';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../i18n';
import { theme } from '../theme';
import { browserStorage } from '../browserStorage';
import { googleTagManager } from '../utils1';
import { sleep, IS_CYPRESS_ENV } from '../utils';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return ({
        root: (_a = {
                position: 'relative'
            },
            _a[theme.breakpoints.down('sm')] = {
                position: 'initial',
                zIndex: 1300,
            },
            _a),
        content: (_b = {
                padding: theme.spacing(1),
                position: 'absolute',
                top: '100%',
                width: '100%',
                background: theme.palette.common.white,
                border: "1px solid " + theme.palette.grey[300],
                borderBottomRightRadius: theme.spacing(1),
                borderBottomLeftRadius: theme.spacing(1),
                maxHeight: "calc(100vh - 100px)",
                overflow: 'auto'
            },
            _b[theme.breakpoints.down('sm')] = {
                width: '100vw',
                top: 72,
                left: 0,
                zIndex: 1301,
            },
            _b),
        previous: {
            lineHeight: 'initial',
            marginBottom: theme.spacing(1),
            '&:hover': {
                color: theme.palette.success.light,
                cursor: 'pointer',
            },
        },
    });
});
var delaySearch;
var delayClose;
var LOCAL_STORE_KEY = 'previous-search';
export var GlobalSearch = function (_a) {
    var _this = this;
    var config = _a.config;
    var langApp = useTranslation(KEYS.APP);
    var _b = __read(React.useState(1), 2), updated = _b[0], setUpdated = _b[1];
    var _c = __read(React.useState(''), 2), search = _c[0], setSearch = _c[1];
    var _d = __read(React.useState(false), 2), show = _d[0], setShow = _d[1];
    var _e = __read(React.useState(null), 2), results = _e[0], setResults = _e[1];
    var _f = __read(React.useState(false), 2), checked = _f[0], setChecked = _f[1];
    var _g = __read(React.useState(JSON.parse(browserStorage.getItem(LOCAL_STORE_KEY) || '[]')), 2), previousSearch = _g[0], setPreviousSearch = _g[1];
    var _h = __read(React.useState(false), 2), loading = _h[0], setLoading = _h[1];
    var searchRef = React.useRef();
    var classes = useStyles();
    var _j = __read(React.useState([
        {
            elem: document.body,
            name: 'click,touchend',
            callback: function (e) {
                var node = e.target;
                var canFind = true;
                var delay = 0;
                do {
                    if (node && node.getAttribute && node.getAttribute('id') === 'global-search') {
                        canFind = false;
                        delay = 1000;
                    }
                    else {
                        node = node.parentNode;
                        if (!node || (node.tagName && node.tagName.toString().toUpperCase() === 'BODY')) {
                            canFind = false;
                        }
                    }
                } while (canFind);
                if (!delay) {
                    return hideDropdown();
                }
            },
        },
    ]), 1), events = _j[0];
    /*eslint-disable */
    useEffect(function () {
        return function () {
            if (delayClose)
                clearTimeout(delayClose);
            if (delaySearch)
                clearTimeout(delaySearch);
            delayClose = null;
            delaySearch = null;
            removeEvents();
        };
    }, []);
    /*eslint-enable */
    var initEvents = function () {
        removeEvents();
        events.forEach(function (_a) {
            var elem = _a.elem, name = _a.name, callback = _a.callback;
            name.split(',').forEach(function (name) {
                elem.addEventListener(name, callback);
            });
        });
    };
    var removeEvents = function () {
        events.forEach(function (_a) {
            var elem = _a.elem, name = _a.name, callback = _a.callback;
            name.split(',').forEach(function (name) {
                elem.removeEventListener(name, callback);
            });
        });
    };
    var _loadData = function (filter) { return __awaiter(_this, void 0, void 0, function () {
        var data, _a, newRes, newList, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 7]);
                    if (loading)
                        return [2 /*return*/];
                    setLoading(true);
                    setShow(true);
                    setChecked(true);
                    _a = config;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.query({
                            query: FetchGlobalSearch,
                            variables: { filter: filter },
                        })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    data = (_a).data;
                    newRes = (data.globalSearch && data.globalSearch.orders && data.globalSearch.orders.length && data.globalSearch) || null;
                    setResults(newRes);
                    if (newRes) {
                        newList = __spread([filter.fields[0].value], previousSearch.filter(function (e) { return e !== filter.fields[0].value; }));
                        setPreviousSearch(newList);
                        browserStorage.setItem(LOCAL_STORE_KEY, JSON.stringify(newList));
                    }
                    return [3 /*break*/, 7];
                case 3:
                    e_1 = _b.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 7];
                case 4:
                    if (!IS_CYPRESS_ENV) return [3 /*break*/, 6];
                    return [4 /*yield*/, sleep(1000)];
                case 5:
                    _b.sent();
                    _b.label = 6;
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onRemove = function (e, delay) {
        if (delay === void 0) { delay = 1000; }
        if (delaySearch)
            clearTimeout(delaySearch);
        setSearch('');
        setPreviousSearch([]);
        setUpdated(Date.now());
        if (location.href.match(/search/gi)) {
            location.href = location.href.split('?')[0];
            var newurl = location.href.split('?')[0];
            window.history.pushState({ path: newurl }, '', newurl);
        }
        setTimeout(function () {
            document.querySelector('#globalSearch').focus();
        }, 300);
        // location.href
    };
    var onSearch = function (e, delay) {
        if (delay === void 0) { delay = 1000; }
        if (delaySearch)
            clearTimeout(delaySearch);
        var _a = e.target, name = _a.name, value = _a.value;
        setSearch(value);
        var search = (e.target.value || '').toLowerCase().trim();
        if (!search)
            return setChecked(false);
        var updates = { fields: [{ name: name, value: search }] };
        googleTagManager('event', 'izi-global-search', { search_term: value });
        return (delaySearch = setTimeout(function () { return _loadData(updates); }, delay));
    };
    var onKeyUp = function (e) {
        if (e.keyCode === 13) {
            onSearch(e, 1);
        }
    };
    var _onBlur = function (e) {
        delayClose = setTimeout(hideDropdown, 1000);
    };
    var hideDropdown = function () {
        setShow(false);
        if (delayClose)
            clearTimeout(delayClose);
        removeEvents();
    };
    var _onFocus = function () {
        setShow(true);
        if (delayClose)
            clearTimeout(delayClose);
        initEvents();
    };
    var content = null;
    if (checked) {
        if (results) {
            content = (React.createElement(Grid, { container: true, direction: 'column' }, Object.keys(results)
                .filter(function (e) { return !['__typename'].includes(e); })
                .map(function (e) { return (React.createElement(Grid, { container: true, direction: 'column', id: "result-" + e },
                React.createElement(TextOverflow, { variant: 'h5' }, e.charAt(0).toUpperCase() + e.substr(1).toLowerCase()),
                React.createElement(Divider, { style: { width: '100%' } }),
                React.createElement(Grid, { container: true, direction: 'column', id: "search-results" }, results[e].map(function (order) { return (React.createElement(SearchOrderView, { id: "global-search-orderId-" + order._id, key: order._id, order: order, search: search })); })))); })));
        }
        else {
            content = React.createElement(NoData, { title: 'global-search' });
        }
    }
    else {
        if (previousSearch && previousSearch.length) {
            content = (React.createElement(Grid, { container: true, direction: 'column' },
                React.createElement(TextOverflow, { variant: 'h5' }, langApp.t('main_global_search_previous_on_focus')),
                React.createElement(Divider, { style: { width: '100%' } }),
                React.createElement(Grid, { container: true, direction: 'column' }, previousSearch.map(function (value) { return (React.createElement(TextOverflow, { key: value, onClick: function () {
                        clearTimeout(delayClose);
                        setUpdated(Date.now());
                        setSearch(value);
                        _loadData({
                            fields: [{ name: 'search', value: value }],
                        });
                        setTimeout(function () {
                            var item = document.querySelector('#global-search input');
                            item.focus();
                        }, 100);
                    }, variant: 'subtitle1', className: classes.previous }, value)); }))));
        }
        else {
            content = React.createElement(TextOverflow, { variant: 'subtitle1' }, langApp.t('main_global_search_default_on_focus'));
        }
    }
    if (loading) {
        content = (React.createElement(Grid, { container: true, justify: 'center' },
            React.createElement(CircularProgress, { style: { color: theme.palette.success.light }, size: 20 })));
    }
    return (React.createElement(Grid, { container: true, alignItems: 'center', className: classes.root, id: 'global-search' },
        React.createElement(BaseSearchComponentRef, { id: 'globalSearch', ref: searchRef, key: updated, style: { width: '100%' }, placeholder: langApp.t('main_global_search_placeholder'), name: 'search', defaultValue: search, isGlobal: true, onChange: onSearch, onRemove: onRemove, onFocus: _onFocus, onBlur: _onBlur, onKeyUp: onKeyUp }),
        React.createElement(Collapse, { "data-id": "globalSearch-collapse", in: show, timeout: "auto", unmountOnExit: true, style: { width: '100%' } },
            React.createElement(Grid, { container: true, direction: 'column', className: classes.content }, content))));
};
