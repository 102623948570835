var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { inject } from 'mobx-react';
import { BasicDialog, FormikAutocompleteAsync, MainButton } from '../../components';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BaseUserAppTYpe } from '../../stores/common';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FetchOrders } from '../../graphql/order';
export default inject('store')(function AddOrderInvoice(props) {
    var _this = this;
    var _a = props.store, invoiceStore = _a.invoiceStore, appType = _a.accountStore.currentUser.appType;
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var formRef = React.useRef();
    if (!appType.find(function (t) { return [BaseUserAppTYpe.FORWARDER, BaseUserAppTYpe.CARRIER].includes(t); })) {
        return null;
    }
    var dialogBody = (React.createElement(React.Fragment, null,
        React.createElement(Formik, { innerRef: formRef, validationSchema: Yup.object({
                order: Yup.object({
                    _id: Yup.string(),
                }).test('_id', langForm.t('form_input_validate_required'), function (val) {
                    return !!val._id;
                }),
            }), initialValues: { order: { _id: '' } }, onSubmit: function (e) { return e; } }, function (formik) {
            var onChange = function (e, v) {
                formik.setFieldValue(e.target.name, v);
            };
            return (React.createElement(Form, { style: { width: '100%' }, id: 'form-order-add-invoice' },
                React.createElement(FormikAutocompleteAsync, { v17: true, query: FetchOrders, variables: {
                        filter: {
                            fields: [
                                {
                                    name: 'all-approved',
                                    value: 1,
                                },
                            ],
                        },
                    }, title: langApp.t('main_order'), name: "order", client: props.config.client, isClient: true, variant: "outlined", margin: 'dense', filterFields: ['orderId'], fullWidth: true, autocompleteProps: {
                        onlySelect: true,
                        width: '100%',
                        getOptionSelected: function (option, value) { return option._id === value._id; },
                        getOptionLabel: function (option) {
                            return [
                                option.orderId,
                                option.sorting_totalWeight,
                                option.sorting_countryPickup,
                                option.sorting_pickupAt,
                                option.sorting_countryDelivery,
                                option.sorting_deliveryAt,
                            ]
                                .filter(function (e) { return e || ''; })
                                .join(', ');
                        },
                    }, onChange: onChange })));
        })));
    var onOk = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    if (!(_a.sent()))
                        throw 'invalid form';
                    return [4 /*yield*/, invoiceStore.createQuoteInvoice(formRef.current.values.order.latestQuote._id)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(BasicDialog, { onOk: onOk, withoutWrapper: true, title: "" + langForm.t('form_btn_add_order_invoice'), isMiddleBody: true, body: dialogBody, actionInit: function (onOpen) {
            var _a;
            return (React.createElement(MainButton, __assign({}, props.buttonProps, { style: __assign(__assign({}, ((props.v1 && { width: '100%' }) || {})), (((_a = props === null || props === void 0 ? void 0 : props.buttonProps) === null || _a === void 0 ? void 0 : _a.style) || {})), onClick: onOpen, loading: invoiceStore.isLoading, id: 'btn-add-order-invoice', title: "" + langForm.t('form_btn_add_order_invoice') })));
        } }));
});
