var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { Grid } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { DialogTitle } from './DialogTitle';
import { dialogActionsStyles, dialogBackDropActionsStyles, dialogContentStyles, dialogStyles } from './styles';
import { onPreventEvent } from './utill';
import { Transition } from './Transition';
import { MainButton } from '../MainButton';
import { TIME_OUT } from '../types';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { useStyles } from './types';
import { theme } from '../../theme';
export var BasicDialogPrompt = function (_a) {
    var _b, _c;
    var body = _a.body, dialogProps = _a.dialogProps, parentStyles = _a.parentStyles, withoutWrapper = _a.withoutWrapper, isMiddleBody = _a.isMiddleBody, hidActionOkBth = _a.hidActionOkBth, pending = _a.pending, props = __rest(_a, ["body", "dialogProps", "parentStyles", "withoutWrapper", "isMiddleBody", "hidActionOkBth", "pending"]);
    var langApp = useTranslation(KEYS.APP);
    var classes = useStyles();
    var classesDialogStyles = dialogStyles();
    var classesDialogBackDropActionsStyles = dialogBackDropActionsStyles();
    var classesDialogActionsStyles = dialogActionsStyles();
    var classesDialogContentStyles = dialogContentStyles();
    var _d = __read(React.useState(false), 2), open = _d[0], setOpen = _d[1];
    var _e = __read(React.useState(false), 2), isOpen = _e[0], setIsOpen = _e[1];
    var _f = __read(React.useState(null), 2), timeout = _f[0], setTimeout1 = _f[1];
    var _g = __read(React.useState(false), 2), loading = _g[0], setLoading = _g[1];
    // useEffect(() => {
    //   const events: any = [
    //     {
    //       name: 'keydown',
    //       context: document.body,
    //       callback: (e: KeyboardEvent) => {
    //         switch (e.key) {
    //           case 'Enter': {
    //             // onOk(e);
    //             // if (e) onPreventEvent(e);
    //             break;
    //           }
    //         }
    //       },
    //     },
    //   ];
    //   events.forEach((e) => e.context.addEventListener(e.name, e.callback));
    //   return () => {
    //     events.forEach((e) => e.context.removeEventListener(e.name, e.callback));
    //   };
    // }, []);
    var handleClickOpen = function (e) {
        if (e)
            onPreventEvent(e);
        if (timeout)
            clearTimeout(timeout);
        setOpen(true);
        setIsOpen(true);
        if (e)
            return onPreventEvent(e);
    };
    var handleClose = function (e, action) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    if (!(action === 'cancel' && props.onCancel)) return [3 /*break*/, 2];
                    setLoading(true);
                    return [4 /*yield*/, props.onCancel(e)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [3 /*break*/, 5];
                case 3:
                    e_1 = _a.sent();
                    return [3 /*break*/, 5];
                case 4:
                    setOpen(false);
                    setLoading(false);
                    if (timeout)
                        clearTimeout(timeout);
                    setTimeout1(setTimeout(function () { return setIsOpen(false); }, TIME_OUT));
                    if (props.onClose)
                        props.onClose(e);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var _onHandleClose = function () {
        handleClose();
    };
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    setLoading(true);
                    if (!props.onOk) return [3 /*break*/, 2];
                    return [4 /*yield*/, props.onOk(e)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2:
                    handleClose(e);
                    return [3 /*break*/, 5];
                case 3:
                    e_2 = _a.sent();
                    console.log(e_2);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    /*eslint-disable */
    React.useEffect(function () {
        return function () {
            if (timeout)
                clearTimeout(timeout);
        };
    }, []);
    /*eslint-enable */
    var styles = props.fullWidth ? { width: '100%' } : {};
    var wrapperContent = (React.createElement(React.Fragment, null,
        props.actionInit && props.actionInit(handleClickOpen, handleClose, { loading: loading }),
        (open || isOpen) && (React.createElement(Dialog, __assign({}, dialogProps, { disableEnforceFocus: true, open: open, TransitionComponent: Transition, keepMounted: true, scroll: "body", onClose: _onHandleClose, "aria-labelledby": "alert-dialog-slide-title", "aria-describedby": "alert-dialog-slide-description", BackdropProps: {
                classes: {
                    root: clsx(classesDialogBackDropActionsStyles.root, (_b = {},
                        _b[classesDialogBackDropActionsStyles.rootMiddle] = hidActionOkBth,
                        _b)),
                },
            }, classes: {
                root: classesDialogStyles.root,
                paper: classesDialogStyles.paper,
                scrollPaper: classes.paperScrollPaper,
            } }),
            React.createElement(DialogTitle, { id: props.title || 'delete-item', onClose: handleClose }, props.title || langApp.t('modal_delete_title')),
            open ? (React.createElement(DialogContent, { classes: {
                    root: clsx(classesDialogContentStyles.root, (_c = {},
                        _c[classesDialogContentStyles.rootMiddle] = isMiddleBody,
                        _c)),
                } }, (body && typeof body === 'function' && body(open)) || body)) : null,
            React.createElement(DialogActions, { classes: {
                    root: classesDialogActionsStyles.root,
                } }, props.actions ? (props.actions({ handleClose: handleClose })) : (React.createElement(Grid, { container: true, direction: "row", justify: 'flex-end', spacing: 2, style: { padding: theme.spacing(2) } },
                React.createElement(Grid, { container: true, style: { width: 'fit-content', marginRight: theme.spacing(2) } },
                    React.createElement(MainButton, { onClick: function (e) { return handleClose(e, 'cancel'); }, disabled: pending || loading, title: props.actionCancelTitle || langApp.t('modal_actions_close'), color: 'secondary', id: 'modal_close_button' })),
                !hidActionOkBth ? (React.createElement(MainButton, { disabled: pending || loading, onClick: onOk, title: props.actionOkTitle || langApp.t('modal_actions_ok'), id: 'modal_success_button' })) : null)))))));
    return withoutWrapper ? (wrapperContent) : (React.createElement("span", { onClick: onPreventEvent, style: __assign(__assign({}, styles), parentStyles) }, wrapperContent));
};
