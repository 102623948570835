import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useSettingsStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            maxWidth: '100%',
            padding: theme.spacing(1.5),
        },
        appBar: {
            position: 'relative',
        },
    });
});
