import { makeStyles, createStyles } from '@material-ui/core/styles';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            maxWidth: "calc(100% - 42px)",
            '& .MuiCard-root ': {
                flex: 1,
            },
        },
    });
});
export var useExpandRowStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        root: (_a = {
                maxWidth: "calc(100% - 36px)"
            },
            _a[theme.breakpoints.down('xs')] = {
                maxWidth: '100%',
            },
            _a),
        container: {},
        expand: {
            // marginTop: theme.spacing(-1),
            transform: 'rotate(0deg)',
            // marginLeft: "auto",
            padding: theme.spacing(1),
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
            color: theme.palette.primary.main,
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        badge: {
            color: theme.palette.secondary.main,
        },
    });
});
