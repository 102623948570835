var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { BaseSocketProvider, withBaseGlobalSocketStore } from './store';
import { inject } from 'mobx-react';
var GlobalSocketClientComponent = function (_a) {
    var connect = _a.connect, disconnect = _a.disconnect, props = __rest(_a, ["connect", "disconnect"]);
    /*eslint-disable */
    React.useEffect(function () {
        connect();
        return function () {
            disconnect();
        };
    }, []);
    /*eslint-enable */
    return props.children;
};
var GlobalSocketClientComponentConsumer = withBaseGlobalSocketStore(GlobalSocketClientComponent);
export var GlobalSocketClient = inject('store')(function (_a) {
    var config = _a.config, store = _a.store, props = __rest(_a, ["config", "store"]);
    var content = React.createElement(GlobalSocketClientComponentConsumer, __assign({}, props));
    return (React.createElement(BaseSocketProvider, { mobxStore: store, config: config }, content));
});
