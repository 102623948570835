var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { theme } from '../theme';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BaseTooltip from './BaseTooltip';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../i18n';
import { copyLink } from '../utils';
import { onPreventEvent } from './Dialog';
import clsx from 'clsx';
import BaseIconButton from './BaseIconButton';
export var useTextOverflowStyles = makeStyles(function (theme) {
    var _a;
    return createStyles({
        root: {
            // whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            '&:hover': {
                '& .copy-item': {
                    display: 'block',
                },
            },
        },
        rootSmallBreakSpace: (_a = {},
            _a[theme.breakpoints.down('xs')] = {
                whiteSpace: 'break-spaces!important',
            },
            _a),
        icon: {
            position: 'absolute',
            right: -4,
            top: -4,
            backgroundColor: theme.palette.common.white + "!important",
            display: 'none',
            padding: 4,
        },
    });
});
export var useTextOverflowGlitchStyles = function (props) {
    return makeStyles({
        rootGlitchBefore: {
            display: 'block',
            content: 'Read this: ',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            opacity: 0.8,
            color: '#00ffff',
            animation: 'glitch .3s cubic-bezier(.25, .46, .45, .94) 2s both infinite ',
        },
        rootGlitchAfter: {
            display: 'block',
            content: 'attr(data-glitch)',
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            opacity: 0.8,
            color: '#ff00ff',
        },
        rootGlitchAnimationBefore: {
            animation: '$glitch .3s cubic-bezier(.25, .46, .45, .94) 2s both infinite',
        },
        rootGlitchAnimationAfter: {
            animation: '$glitch .3s cubic-bezier(.25, .46, .45, .94) 2s reverse both infinite',
        },
        '@keyframes glitch': {
            '0%': {
                transform: 'translate(0)',
            },
            '20%': {
                transform: "translate(-" + (props.glitchSize || '0.75px') + ", " + (props.glitchSize || '0.75px') + ")",
            },
            '40%': {
                transform: "translate(-" + (props.glitchSize || '0.75px') + ", -" + (props.glitchSize || '0.75px') + ")",
            },
            '60%': {
                transform: "translate(" + (props.glitchSize || '0.75px') + ", " + (props.glitchSize || '0.75px') + ")",
            },
            '80%': {
                transform: "translate(" + (props.glitchSize || '0.75px') + ", -" + (props.glitchSize || '0.75px') + ")",
            },
            to: {
                transform: 'translate(0)',
            },
        },
    });
};
export var TextOverflow = function (_a) {
    var _b;
    var noTitle = _a.noTitle, v1 = _a.v1, v2 = _a.v2, v3 = _a.v3, v4 = _a.v4, v5 = _a.v5, v6 = _a.v6, v7 = _a.v7, v8 = _a.v8, warn = _a.warn, className = _a.className, props = __rest(_a, ["noTitle", "v1", "v2", "v3", "v4", "v5", "v6", "v7", "v8", "warn", "className"]);
    // const [seeGlitch, setSeeCglitch] = React.useState(true);
    // const [interval, setIntervalItem]: any = React.useState(null);
    // const classesAnimation = useAnimationStyles();
    var classes = useTextOverflowStyles();
    // const glitchClasses = useTextOverflowGlitchStyles({ glitchSize: props.glitch || '1.5px' })();
    var title = props.title;
    var titleVal = props.children || '';
    if (!title && Array.isArray(titleVal) && titleVal.filter(function (e) { return typeof e === 'string'; }).length === titleVal.length)
        titleVal = titleVal.join('') || '';
    var langApp = useTranslation(KEYS.APP);
    if (typeof titleVal === 'string' && !title)
        title = titleVal;
    var onCopy = function (e) {
        copyLink(props.valueToCopy || title, langApp.t('main_copy_text_copied'));
        return onPreventEvent(e);
    };
    var icon = (React.createElement(BaseTooltip, { title: props.titleToCopy || langApp.t('main_copy_text') },
        React.createElement(BaseIconButton, { basic: true, onClick: onCopy, className: clsx('copy-item', (_b = {}, _b[classes.icon] = v3, _b)), style: __assign({}, (((v5 || props.v9) && { padding: theme.spacing(0.5) }) || null)) },
            React.createElement(FileCopyIcon, { style: __assign({ width: 16, height: 16 }, (props.v9 && { width: 12, height: 12 })) }))));
    // React.useEffect(() => {
    //   if (props.glitch) {
    //     if (interval) {
    //       clearInterval(interval);
    //     }
    //     setIntervalItem(
    //       setInterval(() => {
    //         setSeeCglitch(!seeGlitch);
    //         console.log('text overflow setInterval potential memory leak--');
    //       }, 4000)
    //     );
    //   }
    //   return () => {
    //     if (interval) clearInterval(interval);
    //   };
    // }, [props.glitch, seeGlitch]);
    var fileIcon = (v3 && icon) || null;
    var typographyProps = function (_props) {
        var _a, _b;
        if (_props === void 0) { _props = {}; }
        return (__assign(__assign(__assign(__assign(__assign({}, props), (!noTitle &&
            !props.dangerouslySetInnerHTML &&
            (((props.glitch || v5) && (_a = {}, _a['data-title-1'] = title, _a)) || { title: title }))), { style: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ maxWidth: '100%' }, (!(_props.glitchBefore || _props.glitchAfter) && { position: 'relative' })), (v6 && { cursor: 'pointer', textDecoration: 'underline', color: theme.palette.primary.light })), (!v1 && { whiteSpace: 'nowrap' })), (v2 && { color: theme.palette.error.main, fontSize: '0.75rem', lineHeight: 1 })), (warn && { color: theme.palette.warning.light, fontSize: '0.75rem', fontWeight: 800, lineHeight: 1 })), ((v3 || v4) && { lineHeight: 1 })), (v5 && { maxWidth: 'calc(100% - 24px)' })), (v7 && { fontWeight: 600, textTransform: 'uppercase' })), (props.v71 && { fontWeight: 600, fontFamily: 'sans-serif' })), (v8 && { color: theme.palette.common.black })), (props.primary && { color: theme.palette.primary.main })), props.style), _props.style), classes: {
                root: clsx(classes.root, (_b = {}, _b[classes.rootSmallBreakSpace] = !props.v10, _b)),
            } }), (!props.v31 && (v3 || v5) && { onClick: onCopy })), { className: className }));
    };
    var propsText = typographyProps();
    ['data-title-1', 'title'].forEach(function (key) {
        var _a;
        if (((_a = propsText[key]) === null || _a === void 0 ? void 0 : _a.length) < 2) {
            delete propsText[key];
        }
    });
    var typography = (React.createElement(Typography, __assign({}, propsText),
        (!props.dangerouslySetInnerHTML && props.children) || null,
        fileIcon));
    if (props.glitch) {
        typography = (React.createElement(Grid, { style: { position: 'relative' } },
            React.createElement(Typography, __assign({}, typographyProps({ style: { color: theme.palette.common.white } })),
                (!props.dangerouslySetInnerHTML && props.children) || null,
                fileIcon)));
    }
    if (v5) {
        return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', style: { width: 'initial' } },
            typography,
            icon));
    }
    return typography;
};
