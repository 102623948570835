var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { createStyles, Menu, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MoreIcon } from './icons';
import { makeStyles } from '@material-ui/core/styles';
import { KEYS } from '../i18n';
import { onPreventEvent } from './Dialog';
import ReactDOM from 'react-dom';
import BaseTooltip from './BaseTooltip';
import clsx from 'clsx';
import { useIconStyles } from '../styles';
import BaseIconButton from './BaseIconButton';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        item: {
            minHeight: 'auto',
            padding: 0,
            overflow: 'visible',
            '&:hover': {
                backgroundColor: 'transparent',
                fill: theme.palette.primary.light,
            },
            '& div[id^="menu-"]': {
                height: 40,
            },
            '&>.MuiGrid-root,&>a>.MuiGrid-root,&>span>.MuiGrid-root': {
                height: 40,
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
            '& button': {
                padding: 0,
                marginLeft: theme.spacing(1),
            },
        },
    });
});
export default function MenuListItem(props) {
    var classes = useStyles();
    var langApp = useTranslation(KEYS.APP);
    var iconStyles = useIconStyles();
    var _a = __read(React.useState(null), 2), anchorElOnEnter = _a[0], setAnchorElOnEnter = _a[1];
    var _b = __read(React.useState(null), 2), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = __read(React.useState(null), 2), timeout = _c[0], setTimeout1 = _c[1];
    var _d = __read(React.useState(false), 2), opened = _d[0], setOpened = _d[1];
    var style = props.style;
    var isAdmin = 0; // window.location.href.match(/admin|3002/i);
    React.useEffect(function () {
        return function () {
            if (timeout)
                clearTimeout(timeout);
        };
    }, []);
    var handleClick = function (event) {
        onPreventEvent(event);
        var anchor = event.currentTarget;
        setAnchorEl(anchor);
        setOpened(true);
        if (!event.target.files)
            return onPreventEvent(event);
    };
    var handleClose = function (event) {
        setAnchorEl(null);
        setAnchorElOnEnter(null);
        return onPreventEvent(event);
    };
    var onMouseEnter = function (e) {
        if (isAdmin) {
            setAnchorElOnEnter(e.target);
            handleClick(e);
            if (props.onMouseEnter) {
                props.onMouseEnter();
            }
        }
    };
    var onMouseEnterV1 = function (e) {
        if (timeout)
            clearTimeout(timeout);
    };
    var onMouseLeave = function (e) {
        if (anchorElOnEnter) {
            // handleClose(e);
        }
    };
    var onMouseLeaveTemp = function (e) {
        setTimeout1(setTimeout(function () { return handleClose(new Event('click')); }, 100));
    };
    var open = Boolean(anchorEl);
    var list = props.list.filter(function (e) { return e === null || e === void 0 ? void 0 : e.available; });
    if (!list.length) {
        return (React.createElement("div", { style: { position: 'relative', width: 22 } },
            React.createElement("span", { style: __assign({ position: 'relative', width: 22, height: 22, display: 'flex', alignItems: 'center', justifyContent: 'center' }, props.iconWrapperStyle) },
                React.createElement(BaseTooltip, { title: langApp.t('no_action') },
                    React.createElement(BaseIconButton, { style: __assign({ padding: 4, position: 'absolute', cursor: 'initial', opacity: '0.7' }, style), id: 'menu-list' },
                        React.createElement(MoreIcon, { style: { width: 22, height: 22 } }))))));
    }
    return (React.createElement("div", { style: __assign({ position: 'relative', width: 22 }, (props.v1 && { display: 'flex', alignItems: 'center' })), onMouseLeave: onMouseLeave },
        React.createElement("span", { style: __assign({ position: 'relative', width: 22, height: 22, display: 'flex', alignItems: 'center', justifyContent: 'center' }, props.iconWrapperStyle) },
            React.createElement(BaseTooltip, { title: langApp.t('order_actions') },
                React.createElement(BaseIconButton, { id: "menu-list-" + (props.id || 'default'), "aria-controls": "order-actions", "aria-haspopup": "true", onMouseEnter: onMouseEnter, onClick: handleClick, className: clsx(iconStyles.orderListMenu), style: __assign({ padding: 4, position: 'absolute' }, style) },
                    React.createElement(MoreIcon, { style: __assign({ width: 22, height: 22 }, (props.v1 && { width: 19, height: 19 })) })))),
        props.additional,
        React.createElement(React.Fragment, null,
            ReactDOM.createPortal(React.createElement("div", { "data-id": 'izi-menu-list-fixed', onMouseLeave: onMouseLeaveTemp, style: {
                    width: 28,
                    height: 40,
                    zIndex: 9999,
                    position: 'fixed',
                    left: (anchorElOnEnter && anchorElOnEnter.getBoundingClientRect().left) || -29,
                    top: (anchorElOnEnter && anchorElOnEnter.getBoundingClientRect().top) || -41,
                } }), document.body),
            React.createElement(Menu, { id: "menu-actions-" + (props.id || 'default'), disableScrollLock: true, MenuListProps: {
                    onMouseLeave: onMouseLeave,
                    onMouseEnter: onMouseEnterV1,
                }, anchorEl: anchorEl, keepMounted: true, open: open, onClose: handleClose, 
                // onBlur={props.v2 && handleClose}
                getContentAnchorEl: null, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }, PaperProps: {
                    style: {
                        maxHeight: "calc(100vh - 50px)",
                    },
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                } }, list.map(function (e, index) { return (React.createElement(MenuItem, { key: index, onClick: handleClose, className: classes.item, id: "menu-" + e.id }, open || opened ? (typeof e.content === 'function' ? (e.content(open)) : (e.content)) : (React.createElement("div", { style: { minWidth: Math.min(window.innerWidth, 270), padding: 20 } })))); })))));
}
