var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useReducer } from 'react';
import { MainFilterSortDir } from '../BasicList';
import { ACTIONS } from '../Socket/Chat/List/store.types';
import { SOCKET_CHAT_CUSTOM_EVENT_NAME } from '../../pages/Chat/stores/constants';
import { withBaseGlobalSocketStoreActiveClient } from '../Socket/global/store';
import { GlobalSocketComponent } from '../Socket';
var initialState = {
    loading: false,
    loaded: false,
    error: null,
    data: [],
    count: 0,
    totalUnread: 0,
    filter: {
        fields: [],
        sortBy: 'createdAt',
        sortDir: MainFilterSortDir.DESC,
        limit: 10,
        page: 1,
        skip: 0,
    },
};
var ChatStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = ChatStore.Provider, Consumer = ChatStore.Consumer;
export var ChatProvider = function (_a) {
    var children = _a.children, client = _a.client, socketClient = _a.socketClient;
    var initState = __assign({}, JSON.parse(JSON.stringify(initialState)));
    var _b = __read(useReducer(function (state, action) {
        switch (action.type) {
            case ACTIONS.FINISH_LOAD_DATA: {
                return __assign(__assign(__assign({}, state), action.payload), { error: null, loading: true });
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _b[0], dispatch = _b[1];
    var events = socketClient
        ? [
            {
                client: socketClient,
                name: 'channel',
                callback: function (data) {
                    // console.log(data);
                    switch (data.event) {
                        case SOCKET_CHAT_CUSTOM_EVENT_NAME.CHAT_PAYMENT_TERM_TOTAL_UNREAD_MESSAGES: {
                            dispatch({
                                type: ACTIONS.FINISH_LOAD_DATA,
                                payload: data,
                            });
                            break;
                        }
                    }
                },
            },
        ]
        : [];
    var listen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.on(ev.name, ev.callback); });
        if (!socketClient) {
            console.error('%c*******************____+++++++UserSettingsPaymentTerm channels no client', 'color:orange;font-size:18px');
        }
        else {
            console.log('%c=======____+++++++ UserSettingsPaymentTerm channels notifications listen', 'color:green;font-size:22px');
        }
    };
    var unListen = function () {
        events.forEach(function (ev) { return ev.client && ev.client.off(ev.name, ev.callback); });
        if (!socketClient) {
            console.error('%c*******************____+++++++UserSettingsPaymentTerm channels un listen', 'color:orange;font-size:18px');
        }
        else {
            console.log('%c=======____+++++++UserSettingsPaymentTerm channels notifications un listen', 'color:red;font-size:22px');
        }
    };
    var value = {
        state: state,
        listen: listen,
        unListen: unListen,
    };
    return React.createElement(Provider, { value: value }, children);
};
export var withPaymentTermChannelsStore = function (Component) {
    return function (props) { return (React.createElement(Consumer, { key: props.socketClient ? 1 : -1 }, function (_a) {
        var listen = _a.listen, unListen = _a.unListen, storeProps = __rest(_a, ["listen", "unListen"]);
        return (React.createElement(Component, __assign({}, __assign(__assign({}, props), { listen: listen, unListen: unListen }), { paymentTermChannelStore: storeProps })));
    })); };
};
var ChannelComponent = withPaymentTermChannelsStore(GlobalSocketComponent);
export var PaymentTermChannelsSocket = withBaseGlobalSocketStoreActiveClient(function (p) {
    var client = p.client, props = __rest(p, ["client"]);
    var content = React.createElement(ChannelComponent, __assign({}, props));
    return (React.createElement(ChatProvider, __assign({}, p, { client: client || props.configData().client, socketClient: p.globalSocketState.client }), content));
});
