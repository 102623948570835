var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { USER_BASE_MIN } from './user';
export var FAST_PAYMENT_BASE = "\n  _id\n  amount\n  prevAmount\n  createdAt\n  order{\n    _id\n    orderId\n    sorting_totalWeight\n    sorting_deliveryAt\n    sorting_countryDelivery\n    sorting_countryPickup\n    sorting_pickupAt\n}\n  quote{\n    currency\n  }\n  createdBy{\n    " + USER_BASE_MIN + "\n  }\n";
export var FetchFastPayments = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  query fastPayments($filter: MainFilter) {\n    fastPayments(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"], ["\n  query fastPayments($filter: MainFilter) {\n    fastPayments(filter: $filter) {\n      data {\n        ", "\n      }\n      count\n    }\n  }\n"])), FAST_PAYMENT_BASE);
export var CreateFastPayment = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  mutation createFastPayment($input: FastPaymentInput!) {\n    createFastPayment(input: $input) {\n      ", "\n    }\n  }\n"], ["\n  mutation createFastPayment($input: FastPaymentInput!) {\n    createFastPayment(input: $input) {\n      ", "\n    }\n  }\n"])), FAST_PAYMENT_BASE);
var templateObject_1, templateObject_2;
