var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Chip, IconButton } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../i18n';
export default function BaseChip(props) {
    var langApp = useTranslation(KEYS.APP);
    return (React.createElement(Chip, __assign({}, props, { deleteIcon: React.createElement(IconButton, { title: langApp.t('remove_from_list') },
            React.createElement(ClearIcon, { color: 'primary' })) })));
}
