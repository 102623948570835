import React from 'react';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const ResetPasswordPage = React.lazy(() => import('@izi-logistics/common/lib/pages/ResetPasswordPage'));

const ResetPassword = () => (
    <PreloadingComponent>
        <ResetPasswordPage />
    </PreloadingComponent>
);
export default ResetPassword;
