var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { createStyles, Divider, Grid, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { OrderRoutes } from '../OrderRoutes';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useQuoteStyles } from './styles';
import Collapse from '@material-ui/core/Collapse/Collapse';
import { onPreventEvent } from '../../../Dialog';
import { OrderQuoteStatus, OrderStatus } from '../../../../models';
import { FetchOrder, FetchOrderQuoteDetails } from '../../../../graphql/order';
import { CircularIndeterminate } from '../../../CircularIndeterminate';
import { TextOverflow } from '../../../TextOverflow';
import { OrderQuoteActions } from '../OrderQuoteActions';
import { useTranslation } from 'react-i18next';
import i18n, { KEYS } from '../../../../i18n';
import { BaseUserAppTYpe } from '../../../../stores/common';
import { inject } from 'mobx-react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { theme } from '../../../../theme';
import OrderMessagesUnread from './OrderMessagesUnread';
import PreloadingComponent from '../../../PreloadingComponent';
import { a11yProps, tabComponentProps, TabPanel } from '../../../BaseTabs';
import { StyledBadge } from '../../../StyledBadge';
import { getQueryParams, wrapTextToId } from '../../../../utils';
import { withOrderListItemStore } from '../Customer/storeListItem';
import { ACTION_CHANGE } from '../../../context.store.enums';
import BaseTooltip from '../../../BaseTooltip';
import { isReviewOffer } from '../../../../pages/Quotes/types';
import { USER_ROLE_TOOLS } from '../../../../models/UserRoles';
import { checkIfOrderDetailsIsHide } from '../../../../utils1';
import { FetchUploadsCount } from '../../../../graphql';
import { NOTIFICATION_ACTIONS } from '../../../../notifications';
import { StyledTab, TabsV1 } from '../../../Settings/Tabs';
import { CUSTOM_EVENTS } from '../../../Socket/Notifications/store';
import { getTourSteps, GuideTypes } from '../../../../guides';
import BasicTour from '../../../BasicTour';
import { FetchIssues } from '../../../../graphql/issues';
import { ISSUE_STATUS } from '../../../../models/Issues';
import OrderProgress from '../OrderProgress';
import BaseIconButton from '../../../BaseIconButton';
import { usePreloadederBlocker } from '../../../../styles';
var OrderLinkV1 = React.lazy(function () { return import('../OrderLinkV1'); });
var OrderChatChannels = React.lazy(function () { return import('./TabOrderChatChannels'); });
var DocumentsListTab = React.lazy(function () { return import('./TabDocumentList'); });
var PreparationAssignDate = React.lazy(function () { return import('../../form/formsV1/PreparationAssignDate'); });
var TabPreparation = React.lazy(function () { return import('./TabPreparation'); });
var ExpandedAreaQuote = React.lazy(function () { return import('./ExpandedArea'); });
var TimeBookingTab = React.lazy(function () { return import('./TimeBookingTab'); });
var OrderTracking = React.lazy(function () { return import('../Customer/OrderQuoteItem/OrderTracking'); });
var OrderTrackingMap = React.lazy(function () { return import('../Customer/OrderQuoteItem/OrderTrackingMap'); });
var OrderUserRating = React.lazy(function () { return import('../OrderUserRating'); });
var LoginWithEmail = React.lazy(function () { return import('../../../LoginWithEmail'); });
var TabIssues = React.lazy(function () { return import('./TabIssues'); });
var OrderQuoteInvoiceRequested = React.lazy(function () { return import('./OrderQuoteInvoiceRequested/index'); });
var IziCollapse = Collapse;
var BookingTab = withStyles(function (theme) {
    var _a;
    return createStyles({
        root: {
            color: theme.palette.primary.main,
        },
        wrapper: (_a = {},
            _a[theme.breakpoints.up('md')] = {
                alignItems: 'flex-start',
            },
            _a),
        selected: {
            fontWeight: 700,
        },
    });
})(function (props) { return (React.createElement(Tab, __assign({ disableRipple: true }, props, (typeof (props.title || props['data-id'] || props.label) === 'string' && {
    id: wrapTextToId(props.title || props['data-id'] || props.label),
})))); });
var BaseOrderQuoteItemComponent = withOrderListItemStore(function (_a) {
    var _b, _c, _d;
    var _this = this;
    var _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
    var store = _a.store, additionalContentBody = _a.additionalContentBody, config = _a.config, onDeleteItem = _a.onDeleteItem, canRedirect = _a.canRedirect, orderListItemProps = _a.orderListItemProps, expanded = _a.expanded, setExpanded = _a.setExpanded, props = __rest(_a, ["store", "additionalContentBody", "config", "onDeleteItem", "canRedirect", "orderListItemProps", "expanded", "setExpanded"]);
    var accountStore = store.accountStore;
    var currentUser = accountStore.currentUser, currentCompany = accountStore.currentCompany;
    var isToDo = (_f = (_e = props === null || props === void 0 ? void 0 : props.defaultFilter) === null || _e === void 0 ? void 0 : _e.fields) === null || _f === void 0 ? void 0 : _f.find(function (e) { return ['withP44Issues', 'to-do-list'].includes(e.name); });
    var isWithP44Issue = (_h = (_g = props === null || props === void 0 ? void 0 : props.defaultFilter) === null || _g === void 0 ? void 0 : _g.fields) === null || _h === void 0 ? void 0 : _h.find(function (e) { return ['withP44Issues'].includes(e.name); });
    var _10 = __read(React.useState(0), 2), tab = _10[0], setTab = _10[1];
    var _11 = __read(React.useState(false), 2), loading = _11[0], setLoading = _11[1];
    var _12 = __read(React.useState(false), 2), loaded = _12[0], setLoaded = _12[1];
    var _13 = __read(React.useState(false), 2), loadingChangeBooking = _13[0], setLoadingChangeBooking = _13[1];
    // const [documentTabIndex, setDocumentTabIndex] = React.useState(0);
    var _14 = __read(React.useState(''), 2), error = _14[0], setError = _14[1];
    var _15 = __read(React.useState(null), 2), issuesCount = _15[0], setIssuesCount = _15[1];
    var classes = useQuoteStyles();
    var langApp = useTranslation(KEYS.APP);
    var langMenu = useTranslation(KEYS.MENU);
    // const langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var _16 = __read(React.useState(1), 2), update = _16[0], setUpdate = _16[1];
    var fileInput = React.useRef();
    var _17 = __read(React.useState([
        {
            name: CUSTOM_EVENTS.ORDER_FOCUS_TAB,
            context: document.body,
            callback: function (_a) {
                var detail = _a.detail;
                return __awaiter(_this, void 0, void 0, function () {
                    var _b;
                    return __generator(this, function (_c) {
                        // const index = tabs.findIndex((e: any) => e.name === detail.tab);
                        // if (index > -1) setTab(index);
                        // console.log(detail, index, tabs);
                        try {
                            (_b = document.body.querySelector("button[data-id=\"" + order._id + "-tracking\"]")) === null || _b === void 0 ? void 0 : _b.click();
                        }
                        catch (e) {
                            console.log(e);
                        }
                        return [2 /*return*/];
                    });
                });
            },
        },
    ]), 1), events = _17[0];
    var order = orderListItemProps.state.record;
    var orderDetails = order && order.full ? order : null;
    React.useEffect(function () {
        events.forEach(function (_a) {
            var name = _a.name, context = _a.context, callback = _a.callback;
            context.addEventListener(name, callback);
        });
        return function () {
            events.forEach(function (_a) {
                var name = _a.name, context = _a.context, callback = _a.callback;
                context.removeEventListener(name, callback);
            });
        };
    }, []);
    React.useEffect(function () {
        var query = getQueryParams(window.location.href);
        if (query && (query.search === order._id || query.search === order.orderId)) {
            setTimeout(function () {
                handleExpandClick(new window.Event('click'));
            }, 500);
        }
    }, [order.orderId]);
    // return <p>{order.orderId}</p>;
    var isCustomerOnly = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isCustomer = isCustomerOnly || currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var isForwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var openOrders = location.href.toLowerCase().match('/open-orders');
    var inProgressOrders = location.href.toLowerCase().match('/in-progress');
    var isPreparation = location.href.toLowerCase().match('/preparation');
    var isSender = [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) { return currentUser.appType.includes(t); });
    var isCarrierFromCustomerList = ((_m = (_l = (_k = (_j = order === null || order === void 0 ? void 0 : order.latestQuote) === null || _j === void 0 ? void 0 : _j.createdBy) === null || _k === void 0 ? void 0 : _k.company) === null || _l === void 0 ? void 0 : _l.companiesParents) === null || _m === void 0 ? void 0 : _m.length) && (order === null || order === void 0 ? void 0 : order.latestQuote.createdBy.company.companiesParents.map(function (e) { return e.toString(); }).includes((_q = (_p = (_o = orderDetails === null || orderDetails === void 0 ? void 0 : orderDetails.createdBy) === null || _o === void 0 ? void 0 : _o.company) === null || _p === void 0 ? void 0 : _p._id) === null || _q === void 0 ? void 0 : _q.toString()));
    var withForwarder = ((_r = orderDetails === null || orderDetails === void 0 ? void 0 : orderDetails.forwarders) === null || _r === void 0 ? void 0 : _r.length) && !isCarrierFromCustomerList;
    var textOverflowStyle = { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' };
    var loadUploadsCount = function (order) { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!order.latestQuote) return [3 /*break*/, 4];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    orderListItemProps.onChange({ loading: true });
                    return [4 /*yield*/, (config &&
                            config.client.query({
                                query: FetchUploadsCount,
                                variables: {
                                    filter: {
                                        fields: [
                                            { name: 'parent', value: order.latestQuote._id },
                                            { name: 'parentOffer', value: 1 },
                                            { name: 'canAccess', value: true },
                                            ((_a = order.view_access) === null || _a === void 0 ? void 0 : _a.length) && {
                                                name: 'view_access',
                                                value: order.view_access,
                                            },
                                            order &&
                                                checkIfOrderDetailsIsHide(store, order) && {
                                                name: 'access',
                                                value: [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
                                            },
                                        ].filter(function (e) { return e; }),
                                        limit: 0,
                                    },
                                },
                            }))];
                case 2:
                    res = _b.sent();
                    order.latestQuote.totalUploads = { count: res.data.uploadsCount.count };
                    orderListItemProps.onChange({ record: order, loading: false });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    orderListItemProps.onChange({ loading: false });
                    throw e_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var loadIssuesCount = function (order) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (!(order.latestQuote && accountStore.isLoggedIn)) return [3 /*break*/, 2];
                    return [4 /*yield*/, (config &&
                            config.client.query({
                                query: FetchIssues,
                                variables: {
                                    filter: {
                                        fields: [
                                            { name: 'parent', value: order.latestQuote._id },
                                            { name: 'parent_collection_name', value: 'order_quotes' },
                                            { name: 'status', value: ISSUE_STATUS.REQUESTED },
                                        ],
                                        limit: 0,
                                    },
                                },
                            }))];
                case 1:
                    res = _c.sent();
                    setIssuesCount(((_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.issues) === null || _b === void 0 ? void 0 : _b.count) || null);
                    _c.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var loadOrder = function (opt) {
        if (opt === void 0) { opt = {}; }
        return __awaiter(_this, void 0, void 0, function () {
            var res, orderFull, res1, quote_1, _documentTabIndex_1, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!config)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 5, , 6]);
                        orderListItemProps.onChange({ loading: true });
                        return [4 /*yield*/, config.client.query({
                                query: FetchOrder,
                                variables: {
                                    _id: order._id,
                                },
                            })];
                    case 2:
                        res = _a.sent();
                        delete res.data.order.price;
                        orderFull = JSON.parse(JSON.stringify(__assign(__assign(__assign(__assign({}, order), res.data.order), ((opt === null || opt === void 0 ? void 0 : opt.softOrderUpdate) && { updatedAt: order.updatedAt })), { full: true, totalUnViewedQuotes: 0 })));
                        if (!(order.latestQuote && !(opt === null || opt === void 0 ? void 0 : opt.withoutQuote))) return [3 /*break*/, 4];
                        return [4 /*yield*/, (config &&
                                config.client.query({
                                    query: FetchOrderQuoteDetails,
                                    variables: {
                                        _id: order.latestQuote._id,
                                    },
                                }))];
                    case 3:
                        res1 = _a.sent();
                        quote_1 = res1.data[Object.keys(res1.data)[0]];
                        orderFull.latestQuote = order.latestQuote = __assign(__assign({}, order.latestQuote), quote_1);
                        orderFull.transportations.forEach(function (e) {
                            var item = quote_1.order.transportations.find(function (t) { return t._id === e._id; });
                            Object.assign(e, item);
                        });
                        if (quote_1 && !(opt === null || opt === void 0 ? void 0 : opt.softOrderUpdate)) {
                            loadUploadsCount(orderFull);
                        }
                        if (quote_1 && !(opt === null || opt === void 0 ? void 0 : opt.softOrderUpdate)) {
                            loadIssuesCount(orderFull);
                        }
                        _a.label = 4;
                    case 4:
                        delete orderFull.firstRoute;
                        delete orderFull.lastRoute;
                        orderListItemProps.onChange({ record: orderFull, loading: false });
                        _documentTabIndex_1 = 0;
                        tabs.forEach(function (e, i) { return e.name === 'documents' && (_documentTabIndex_1 = i); });
                        if (!orderFull.transportations.find(function (e) { return !e.unloadedAt; }) &&
                            isCarrier &&
                            inProgressOrders &&
                            _documentTabIndex_1 !== tab) {
                            setTab(_documentTabIndex_1);
                        }
                        return [2 /*return*/, orderFull];
                    case 5:
                        e_2 = _a.sent();
                        orderListItemProps.onChange({ loading: false });
                        throw e_2;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    var forceRefreshOrder = function (opt) {
        if (opt === void 0) { opt = {}; }
        return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, loadOrder(opt)];
            });
        });
    };
    var onChangeBooking = function () {
        var e = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            e[_i] = arguments[_i];
        }
        return __awaiter(_this, void 0, void 0, function () {
            var order_1, e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, 5, 6]);
                        setLoadingChangeBooking(true);
                        return [4 /*yield*/, forceRefreshOrder(__assign({}, e[0]))];
                    case 1:
                        order_1 = _a.sent();
                        if (e[1] && e[1].rawUpdate) {
                            e[1].record = order_1;
                        }
                        else {
                            e[1] = order_1;
                        }
                        if (!(isToDo && props.onChange)) return [3 /*break*/, 3];
                        return [4 /*yield*/, props.onChange.apply(props, __spread(e))];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [3 /*break*/, 6];
                    case 4:
                        e_3 = _a.sent();
                        return [3 /*break*/, 6];
                    case 5:
                        setLoadingChangeBooking(false);
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    var onChange = function () {
        var e = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            e[_i] = arguments[_i];
        }
        return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (props.onChange)
                    props.onChange.apply(props, __spread(e));
                setUpdate(Date.now());
                return [2 /*return*/];
            });
        });
    };
    var usePreloadederBlockerClass = usePreloadederBlocker();
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var handleChange = function (event, newValue) {
        setTab(newValue);
    };
    var isAdmin = window.location.href.match('localhost:3002') || window.location.href.match('admin');
    var actionsContent = isAdmin || isToDo
        ? null
        : orderDetails &&
            (OrderStatus.ApprovedQuote === order.status ||
                isCustomer ||
                ((isCustomer || isCarrier) && [OrderStatus.Finished].includes(order.status)))
            ? function (quote) { return (React.createElement(OrderQuoteActions, { onChange: props.onChange, onChangeV1: forceRefreshOrder, onChangeV2: function (e) { return loadIssuesCount(orderDetails); }, onDeleteItem: onDeleteItem, order: orderDetails || order, itemRecordOrder: order, quote: quote, config: config, style: { width: 'fit-content' } })); }
            : null;
    var isSystemCancel = (order.cancelComment || '').match(/system cancel/gi);
    var isCancelled = order.canceledAt && order.status === OrderStatus.Finished;
    var isDeleted = order.deletedAt;
    var isCAAllowed = !isCarrier ||
        !((_s = order.approvedCarrier) === null || _s === void 0 ? void 0 : _s._id) ||
        (isCarrier && accountStore.currentCompany._id === order.approvedCarrier._id);
    var isOrderOwn = accountStore.currentCompany._id === ((_u = (_t = order.manager) === null || _t === void 0 ? void 0 : _t.company) === null || _u === void 0 ? void 0 : _u._id);
    var isAprroved = order.status === OrderStatus.ApprovedQuote;
    var isDraftOrTemplate = order.status === OrderStatus.Draft || order.isTemplate;
    var onChangeFiles = isAprroved
        ? function (doc, action) {
            var item = orderListItemProps.state.record;
            orderListItemProps.onChange({
                record: __assign(__assign({}, item), { latestQuote: __assign(__assign({}, item.latestQuote), { totalUploads: (item.latestQuote.totalUploads && __assign(__assign({}, item.latestQuote.totalUploads), { count: item.latestQuote.totalUploads.count + (action === ACTION_CHANGE.DELETED ? -1 : (doc === null || doc === void 0 ? void 0 : doc.length) || 0) })) || { count: (doc === null || doc === void 0 ? void 0 : doc.length) || 0 } }) }),
            });
        }
        : function () { return 0; };
    var item = order.latestQuote;
    var isOrderSenderConsigneeCompany = [OrderStatus.HasQuotes, OrderStatus.WaitingQuotes, OrderStatus.ApprovedQuote].includes(order.status) &&
        order.transportations
            .map(function (transportation) { return transportation.routes; })
            .reduce(function (a, b) { return __spread(a, b); }, [])
            .find(function (route) { var _a, _b, _c; return ((_c = (_b = (_a = route === null || route === void 0 ? void 0 : route.origin) === null || _a === void 0 ? void 0 : _a.companyId) === null || _b === void 0 ? void 0 : _b._id) === null || _c === void 0 ? void 0 : _c.toString()) === currentCompany._id.toString(); });
    var is_review_offer = isReviewOffer(accountStore, item);
    var isBooking = !is_review_offer && item && item.status === OrderQuoteStatus.Approved && order.status === OrderStatus.ApprovedQuote;
    var hasAditionalTabs = isAprroved ||
        (orderDetails &&
            orderDetails.latestQuote &&
            [OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(orderDetails.latestQuote.status));
    var v2 = isCarrier && isBooking;
    var lastTransportation = order && order.transportations && order.transportations[order.transportations.length - 1];
    var isDeliveredLastTransportation = lastTransportation === null || lastTransportation === void 0 ? void 0 : lastTransportation.unloadedAt;
    var isHide = checkIfOrderDetailsIsHide(store, order);
    var urlSearch = new URLSearchParams(window.location.search);
    var isReview = !accountStore.isLoggedIn &&
        (urlSearch.get(NOTIFICATION_ACTIONS.LINK_TO_BOOK) || urlSearch.get(NOTIFICATION_ACTIONS.LINK_TO_VIEW_ORDER));
    var needP44Code = isCarrier &&
        orderDetails &&
        orderDetails.canP44Sync &&
        orderDetails.sorting_hasTransport &&
        !((_x = (_w = (_v = orderDetails === null || orderDetails === void 0 ? void 0 : orderDetails.latestQuote) === null || _v === void 0 ? void 0 : _v.p44ShipmentLog) === null || _w === void 0 ? void 0 : _w.changes) === null || _x === void 0 ? void 0 : _x.match(/mapUrl/gi)) &&
        !currentCompany.p44Code;
    var preparationTab = {
        name: 'preparation',
        access: order.preparedAt && (isOrderOwn || isOrderSenderConsigneeCompany || isReview),
        tab: function (index) { return (React.createElement(StyledTab, __assign({ key: "tab-" + index, label: langApp.t('app_preparation'), color: 'primary' }, a11yProps(index)))); },
        tabContent: function (index) { return React.createElement(TabPreparation, __assign({}, { tab: tab, index: index, forceRefreshOrder: forceRefreshOrder, config: config, orderDetails: orderDetails })); },
    };
    var documentsTab = {
        name: 'documents',
        access: (hasAditionalTabs || isCancelled || isReview) && (orderDetails === null || orderDetails === void 0 ? void 0 : orderDetails.latestQuote),
        tab: function (index) {
            var _a, _b, _c;
            return (React.createElement(StyledTab, __assign({ key: "tab-" + index, "data-id": langOfferForm.t('offer_document_attached'), label: (((_c = (_b = (_a = orderListItemProps.state.record) === null || _a === void 0 ? void 0 : _a.latestQuote) === null || _b === void 0 ? void 0 : _b.totalUploads) === null || _c === void 0 ? void 0 : _c.count) && (React.createElement(Grid, { container: true, alignItems: "center", justify: "center" },
                    React.createElement("div", { style: __assign({ maxWidth: 'calc(100% - 20px)' }, (textOverflowStyle || {})) }, langOfferForm.t('offer_document_attached')),
                    React.createElement(BaseTooltip, { title: langOfferForm.t('offer_document_attached'), style: { marginRight: 12 } },
                        React.createElement(StyledBadge, { badgeContent: orderListItemProps.state.record.latestQuote.totalUploads.count, color: "primary" },
                            React.createElement("div", { style: { width: 8, height: 24 } })))))) ||
                    langOfferForm.t('offer_document_attached'), color: 'primary' }, a11yProps(index))));
        },
        tabContent: function (index) { return (React.createElement(PreloadingComponent, null,
            React.createElement(DocumentsListTab, __assign({}, { tab: tab, index: index, orderDetails: orderDetails, theme: theme, onChangeFiles: onChangeFiles, config: config })))); },
    };
    var bookingTab = {
        name: 'booking',
        access: v2 || !accountStore.isLoggedIn || urlSearch.get(NOTIFICATION_ACTIONS.LINK_TO_BOOK),
        tab: function (index) { return (React.createElement(BookingTab, __assign({ label: langOfferForm.t('offer_time_booking'), color: 'primary', key: "tab-" + index }, a11yProps(index)))); },
        tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
            React.createElement(Grid, { container: true, key: update },
                React.createElement(Divider, { style: { width: '100%' } }),
                React.createElement(PreloadingComponent, __assign({}, tabComponentProps),
                    React.createElement(TimeBookingTab, { order: orderDetails, config: config, onChange: onChangeBooking, listOptions: props.listOptions, additionalContentBodyV1: props.additionalContentBodyV1 }))))); },
    };
    var canP44Create = accountStore.currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_see_p44_tracking_settings); }) ||
        isAdmin || ((_z = (_y = accountStore.currentCompany) === null || _y === void 0 ? void 0 : _y.settings) === null || _z === void 0 ? void 0 : _z.canP44OnBoard);
    var trackingTab = {
        name: 'tracking',
        access: 
        // (gpsDetails?.result?.mapUrl || canP44Create) &&
        order.canP44Sync && [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes(order.status),
        tab: function (index) { return (React.createElement(StyledTab, __assign({ key: "tab-" + index, label: langApp.t('traking_order'), color: 'primary', "data-id": order._id + "-tracking" }, a11yProps(index)))); },
        tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index, style: { width: '100%' } },
            needP44Code ? (React.createElement(PreloadingComponent, null,
                React.createElement(BasicTour, { client: config === null || config === void 0 ? void 0 : config.client, steps: getTourSteps(GuideTypes.CARRIER_JOIN_P44, i18n, KEYS), updateSettings: true }))) : null,
            React.createElement(Divider, { style: { width: '100%' } }),
            React.createElement(Grid, { container: true, id: "order-gps-tracking" + (needP44Code ? '-need-code' : '') },
                canP44Create ? (React.createElement(Grid, { container: true, item: true, xs: 12, md: 4 },
                    React.createElement(PreloadingComponent, __assign({}, tabComponentProps),
                        React.createElement(OrderTracking, { config: config, order: orderDetails || orderListItemProps.state.record })))) : null,
                React.createElement(Grid, { container: true, item: true, xs: 12, md: canP44Create ? 8 : 12 },
                    React.createElement(PreloadingComponent, __assign({}, tabComponentProps),
                        React.createElement(OrderTrackingMap, { config: config, order: orderDetails || orderListItemProps.state.record })))))); },
    };
    var gpsDetails;
    try {
        gpsDetails = JSON.parse((_0 = orderDetails.latestQuote) === null || _0 === void 0 ? void 0 : _0.p44ShipmentLog.changes);
    }
    catch (e) { }
    var KEY_TO_REDRAW = JSON.stringify(['_id', 'updatedAt'].map(function (e) {
        var _a;
        return (_a = {}, _a[e] = (orderDetails || order)[e], _a);
    }));
    var tabs = !orderDetails
        ? []
        : [
            bookingTab,
            {
                name: 'overview',
                access: true,
                tab: function (index) { return (React.createElement(StyledTab, __assign({ label: langOfferForm.t('offer_overview'), color: 'primary', key: "tab-" + index }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
                    React.createElement(Grid, { container: true, key: update },
                        React.createElement(Divider, { style: { width: '100%' } }),
                        React.createElement(PreloadingComponent, __assign({}, tabComponentProps),
                            React.createElement(ExpandedAreaQuote, { key: KEY_TO_REDRAW, order: orderDetails, config: config, onChange: onChange, listOptions: props.listOptions, additionalContentBodyV1: props.additionalContentBodyV1, onChangeV1: forceRefreshOrder }))))); },
            },
            accountStore.isLoggedIn && preparationTab,
            trackingTab,
            accountStore.isLoggedIn && documentsTab,
            {
                name: 'chat',
                access: accountStore.isLoggedIn && !isDraftOrTemplate && ((_1 = orderDetails.latestQuote) === null || _1 === void 0 ? void 0 : _1.approvedAt),
                tab: function (index) { return (React.createElement(StyledTab, __assign({ "data-id": langMenu.t('menu_sidebar_chat_title'), label: React.createElement(OrderMessagesUnread, { order: orderDetails, key: "tab-" + index, label: langMenu.t('menu_sidebar_chat_title') }), color: 'primary' }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(PreloadingComponent, null,
                    React.createElement(OrderChatChannels, __assign({}, { tab: tab, index: index, orderDetails: orderDetails, config: config, theme: theme })))); },
            },
            // {
            //   name: 'goods-loaindg',
            //   access:
            //     accountStore.isLoggedIn &&
            //     !isDraftOrTemplate &&
            //     !order.view_access?.find((e) => [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].includes(e)) &&
            //     !((isCarrier || isCustomer || isForwarder) && openOrders),
            //   tab: (index) => (
            //     <StyledTab
            //       label={langOrderList.t('order_list_good_loading')}
            //       color={'primary'}
            //       key={`tab-${index}`}
            //       {...a11yProps(index)}
            //     />
            //   ),
            //   tabContent: (index) => (
            //     <TabPanel value={tab} index={index} key={`tab-content-${index}`}>
            //       <Divider style={{ width: '100%' }} />
            //       <Grid container style={{ padding: theme.spacing(0, 2), ...tabComponentProps.style }}>
            //         <OrderLoadingComponentTab order={orderDetails} config={config} {...tabComponentProps} />
            //       </Grid>
            //     </TabPanel>
            //   ),
            // },
            {
                name: 'invoice-request',
                access: accountStore.isLoggedIn && hasAditionalTabs && !isSender && !withForwarder,
                tab: function (index) { return (React.createElement(StyledTab, __assign({ key: "tab-" + index, label: langOfferForm.t('offer_form_invoice_request'), color: 'primary' }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(TabPanel, { value: tab, index: index, key: "tab-content-" + index },
                    React.createElement(Divider, { style: { width: '100%' } }),
                    React.createElement(Grid, { container: true, style: __assign({ padding: theme.spacing(0, 2) }, tabComponentProps.style) },
                        React.createElement(PreloadingComponent, null,
                            React.createElement(OrderQuoteInvoiceRequested, { config: config, order: orderDetails }))))); },
            },
            {
                name: 'order-issues',
                access: accountStore.isLoggedIn && ((_2 = orderDetails.latestQuote) === null || _2 === void 0 ? void 0 : _2.approvedAt) && (isCustomer || isForwarder),
                tab: function (index) { return (React.createElement(StyledTab, __assign({ key: "tab-" + index, label: (issuesCount && (React.createElement(Grid, { container: true, alignItems: "center", justify: "center" },
                        React.createElement("div", { style: __assign({ maxWidth: 'calc(100% - 20px)' }, (textOverflowStyle || {})) }, langApp.t('main_issue')),
                        React.createElement(BaseTooltip, { title: langApp.t('main_issue'), style: { marginRight: 12 } },
                            React.createElement(StyledBadge, { badgeContent: issuesCount, color: "primary" },
                                React.createElement("div", { style: { width: 8, height: 24 } })))))) ||
                        langApp.t('main_issue'), color: 'primary' }, a11yProps(index)))); },
                tabContent: function (index) { return (React.createElement(PreloadingComponent, null,
                    React.createElement(TabIssues, __assign({}, {
                        index: index,
                        parentOnChange: function (_a) {
                            var count = _a.count;
                            setIssuesCount(count);
                        },
                        issuesCount: issuesCount,
                        tab: tab,
                        orderDetails: orderDetails,
                        config: config,
                    })))); },
            },
        ].filter(function (e) { return e.access; });
    React.useEffect(function () {
        var _a;
        if (isPreparation && tabs.length > 0) {
            var preparationTabIndex_1 = 0;
            tabs.forEach(function (e, i) { return e.name === 'preparation' && (preparationTabIndex_1 = i); });
            setTab(preparationTabIndex_1);
        }
        if (tabs.length > 0 && ((_a = order === null || order === void 0 ? void 0 : order.transportations) === null || _a === void 0 ? void 0 : _a.length) &&
            !order.transportations.find(function (e) { return !e.unloadedAt; }) &&
            isCarrier &&
            inProgressOrders) {
            var _documentTabIndex_2 = 0;
            tabs.forEach(function (e, i) { return e.name === 'documents' && (_documentTabIndex_2 = i); });
            // console.log('_documentTabIndex', _documentTabIndex);
            setTab(_documentTabIndex_2);
            // setDocumentTabIndex(_documentTabIndex);
        }
        var selectedTab = urlSearch.get('tab');
        if (selectedTab) {
            var tabIndex = tabs.findIndex(function (e) { return e.name === selectedTab; });
            if (tabIndex > -1) {
                setTab(tabIndex);
            }
        }
    }, [tabs.length]);
    var handleExpandClick = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isToDo || isWithP44Issue)
                        return [2 /*return*/, null];
                    setExpanded(!expanded);
                    if (!(!expanded && !orderDetails)) return [3 /*break*/, 5];
                    if (!config) return [3 /*break*/, 5];
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    setLoading(true);
                    return [4 /*yield*/, loadOrder()];
                case 2:
                    _a.sent();
                    setLoaded(true);
                    return [3 /*break*/, 5];
                case 3:
                    e_4 = _a.sent();
                    setError(e_4.message);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/, onPreventEvent(e)];
            }
        });
    }); };
    var additionalContent = isToDo ? null : (React.createElement(Grid, { container: true, alignItems: 'center', style: __assign(__assign(__assign({ width: 'initial', marginTop: theme.spacing(1.6) }, (isDraftOrTemplate && { marginTop: theme.spacing(0) })), (openOrders && { marginTop: theme.spacing(1.5) })), (isSmall && { width: '100%', justifyContent: 'center', marginTop: undefined })), direction: isSmall ? 'column' : 'row' },
        React.createElement(BaseTooltip, { title: (expanded ? langApp.t('collapse_less') : langApp.t('collapse_more')) },
            React.createElement(BaseIconButton, { basic: true, disabled: loading, className: clsx(classes.expand, (_b = {},
                    _b[classes.expandOpen] = expanded,
                    _b)), onClick: handleExpandClick, "aria-expanded": expanded, "aria-label": "show more" },
                React.createElement("span", { style: { width: 24, height: 24 } }, React.createElement(ExpandMoreIcon, null))))));
    if (!accountStore.isLoggedIn) {
        if (urlSearch.get(NOTIFICATION_ACTIONS.LINK_TO_BOOK)) {
            tabs.splice(1, tabs.length);
        }
        else {
            tabs.splice(0, 1);
        }
        tabs.splice(1, tabs.length);
    }
    if (!isCAAllowed) {
        tabs.splice(1, tabs.length);
    }
    var isCompanyOrder = currentCompany._id === ((_3 = order.createdBy) === null || _3 === void 0 ? void 0 : _3.company._id) ||
        currentCompany._id === ((_4 = order.manager) === null || _4 === void 0 ? void 0 : _4.company._id) ||
        currentCompany._id === (((_5 = order.company) === null || _5 === void 0 ? void 0 : _5._id) || order.company);
    var isFoManager = order === null || order === void 0 ? void 0 : order.forwarders.find(function (e) { var _a; return (((_a = e.company) === null || _a === void 0 ? void 0 : _a._id) || e.company) === currentCompany._id; });
    var IZITab = !isSmall ? Tabs : TabsV1;
    // console.log('orderDetails', orderDetails, expanded);
    var opositeStatus = ((_6 = order.approvedCarrier) === null || _6 === void 0 ? void 0 : _6._id) && isPreparation ? (React.createElement(Grid, { container: true, alignItems: "center", style: { width: 'initial', paddingTop: theme.spacing(0.5) } },
        React.createElement(OrderProgress, { order: order, isFocused: true }))) : ((_7 = order.approvedCarrier) === null || _7 === void 0 ? void 0 : _7._id) && ((_8 = order.preparingStatuses) === null || _8 === void 0 ? void 0 : _8.length) ? (React.createElement(Grid, { container: true, alignItems: "center", style: { width: 'initial', paddingTop: theme.spacing(0.5) } },
        React.createElement(Grid, { container: true, alignItems: "center", justify: "center", style: { width: 'initial', minWidth: 170 } },
            React.createElement(PreloadingComponent, null,
                React.createElement(PreparationAssignDate, { v2: true, isFocused: true, isProgress: true, config: config, order: order, preparingStatuses: order.preparingStatuses || [], disabled: !((_9 = order.preparingStatuses) === null || _9 === void 0 ? void 0 : _9.length) }))))) : (React.createElement("span", null));
    return (React.createElement(Grid, { "data-id": "izi-list-order-header", container: true, style: { position: 'relative' }, direction: "row", key: JSON.stringify(['_id'].map(function (e) {
            var _a;
            return (_a = {}, _a[e] = (orderDetails || order)[e], _a);
        })), className: clsx((_c = {},
            _c[classes.canceled] = isCancelled && isSystemCancel,
            _c[classes.userCanceled] = isCancelled && !isSystemCancel,
            _c[classes.userDeleted] = isDeleted,
            _c)) },
        (loadingChangeBooking && (React.createElement("div", { "data-id": 'izi-freezing', className: usePreloadederBlockerClass.root },
            React.createElement(Grid, { container: true, justify: 'center' },
                React.createElement(CircularIndeterminate, null))))) ||
            null,
        React.createElement("input", { "data-id": "file-select-" + wrapTextToId(order === null || order === void 0 ? void 0 : order._id), id: "file-select-record", type: 'file', style: { position: 'absolute', left: "-100vw", zIndex: 99999999 }, ref: fileInput }),
        error ? React.createElement(TextOverflow, { color: 'error' }, error) : null,
        React.createElement(OrderRoutes, __assign({ onDeleteItem: onDeleteItem, config: config, onClick: canRedirect && 0 ? function () { return 0; } : handleExpandClick, canRedirect: canRedirect, canViewAdditional: expanded, additionalContent: additionalContent }, props, { key: KEY_TO_REDRAW, order: order, onChangeV1: forceRefreshOrder, onChangeV2: function (e) { return loadIssuesCount(orderDetails); }, fileInput: fileInput })),
        isWithP44Issue ? (trackingTab.tabContent(0)) : isToDo ? (bookingTab.tabContent(0)) : (React.createElement(IziCollapse, { in: expanded, id: "expaned-container", timeout: "auto", unmountOnExit: true, style: { width: '100%', position: 'relative', minHeight: 40 } },
            loading || orderListItemProps.state.loading ? (React.createElement(Grid, { container: true, justify: 'center', className: usePreloadederBlockerClass.root },
                React.createElement(CircularIndeterminate, null))) : null,
            orderDetails && loaded ? (React.createElement(React.Fragment, null,
                React.createElement(Divider, { style: { width: '100%' } }),
                React.createElement(Grid, { container: true, alignItems: "flex-end", justify: "space-between" },
                    opositeStatus,
                    React.createElement(IZITab, { value: tab, onChange: handleChange, indicatorColor: "primary", textColor: "primary", orientation: isSmall ? 'vertical' : 'horizontal', className: clsx((_d = {},
                            _d[classes.tabsOrder] = 1,
                            _d)), style: __assign({ width: isSmall ? '100%' : 'max-content' }, { alignItems: 'flex-end', justifyContent: 'flex-end', padding: '1px' }) }, tabs.map(function (e, index) { return e.tab(index); }))),
                React.createElement(React.Fragment, null,
                    tabs[tab].tabContent(tab),
                    React.createElement(Grid, { container: true, "data-id": "izi-order-bottom-aditional-content" }, (additionalContentBody && accountStore.isLoggedIn && (React.createElement(React.Fragment, null,
                        React.createElement(Divider, { style: __assign({ width: '100%' }, (isSmall && { marginBottom: theme.spacing(2) })) }),
                        React.createElement(Grid, { container: true, justify: "space-between" },
                            React.createElement(Grid, { container: true, style: __assign({ width: 'fit-content', alignItems: 'center', justifyContent: 'center', margin: theme.spacing(0, 2) }, (isSmall &&
                                    (order.approvedCarrier || order.publishedAt) &&
                                    (isFoManager || isCompanyOrder) && { width: '100%', margin: 0 })) }, (order.approvedCarrier || order.publishedAt) && (isFoManager || isCompanyOrder) ? (React.createElement(PreloadingComponent, null,
                                React.createElement(OrderLinkV1, { order: order, config: config }))) : null),
                            typeof additionalContentBody === 'function'
                                ? additionalContentBody(orderDetails, actionsContent)
                                : additionalContentBody)))) ||
                        (actionsContent && accountStore.isLoggedIn && (React.createElement(React.Fragment, null,
                            React.createElement(Divider, { style: { width: '100%' } }),
                            React.createElement(Grid, { container: true, justify: "space-between" },
                                React.createElement("span", null),
                                actionsContent(orderDetails.latestQuote)))))),
                    (!isHide &&
                        order &&
                        order.latestQuote &&
                        order.createdBy &&
                        order.latestQuote.createdBy &&
                        order.publishedAt &&
                        (isDeliveredLastTransportation || [OrderStatus.Finished].includes(order.status)) && (React.createElement(React.Fragment, null,
                        React.createElement(PreloadingComponent, { withoutLoader: true },
                            React.createElement(OrderUserRating, { v1: true, onChangeV1: forceRefreshOrder, config: config, order: orderDetails, quote: orderDetails.latestQuote }))))) ||
                        null,
                    (!accountStore.isLoggedIn && tabs.length > 0 && (React.createElement(PreloadingComponent, null,
                        React.createElement(Grid, { container: true, style: { zIndex: 11, position: 'relative', padding: theme.spacing(2) }, alignContent: "center" },
                            React.createElement(LoginWithEmail, { config: config, label: langApp.t('login_more') }))))) ||
                        null))) : null))));
});
export var BaseOrderQuoteItem = inject('store')(BaseOrderQuoteItemComponent);
