var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { inject, useLocalStore } from 'mobx-react';
import { Grid, InputAdornment, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import { withSidebarChannelChatStore } from './storeSidebarChannel';
import { i18n, KEYS } from '../../../../i18n';
import { formUserErrorMessage, onlySpacesRegExp } from '../../../../common';
import { onPreventEvent } from '../../../Dialog';
import { FormikTextField } from '../../../Formik';
import { theme } from '../../../../theme';
import Emoji from './Emojii';
import { makeStyles } from '@material-ui/core/styles';
import { MainButton } from '../../../MainButton';
import PreloadingComponent from '../../../PreloadingComponent';
import { UploadList } from '../../../Uploads/List';
import { AddFormButton } from '../../../AddFormButton';
import UploadWithDragAndDrop from '../../../UploadWithDragAndDrop';
import { wrapTextToId } from '../../../../utils';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import BaseTooltip from '../../../BaseTooltip';
import { TextOverflow } from '../../../TextOverflow';
import BaseIconButton from '../../../BaseIconButton';
import clsx from 'clsx';
var AllUploadList = React.lazy(function () { return import('./Uploads'); });
var KEY = "chat-text-input";
var useStyles = makeStyles(function (theme) { return ({
    disabled: {
        pointerEvents: 'none',
        opacity: 0.7,
    },
    root: {
        '& textarea': {
            paddingRight: theme.spacing(2.5),
        },
    },
}); });
export var SidebarChannelItemDetailsChatForm = observer(function (_a) {
    var _b;
    var sidebarChannelChat = _a.sidebarChannelChat, config = _a.config, props = __rest(_a, ["sidebarChannelChat", "config"]);
    var formRef = React.useRef(null);
    var messageInputRef = React.useRef(null);
    var currentUser = props.store.accountStore.currentUser;
    var langForm = useTranslation(KEYS.FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var classes = useStyles();
    var fileInput = React.useRef();
    var _c = __read(React.useState({ files: [] }), 2), tempValue = _c[0], setTempValue = _c[1];
    var localStore = useLocalStore(function () { return ({
        updatedAt: 1,
        defValue: { text: '', upload: null, chat: sidebarChannelChat.state.item && sidebarChannelChat.state.item._id },
        error: '',
        isLoading: false,
        isSent: false,
        onSubmit: function (input, opt) {
            return __awaiter(this, void 0, void 0, function () {
                var res, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            localStore.error = '';
                            localStore.isLoading = true;
                            if (opt === null || opt === void 0 ? void 0 : opt.resetForm) {
                                opt.resetForm({ values: '' });
                            }
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, 4, 5]);
                            localStore.defValue = {
                                text: '',
                                upload: null,
                                chat: sidebarChannelChat.state.item && sidebarChannelChat.state.item._id,
                            };
                            return [4 /*yield*/, sidebarChannelChat.createOrderChatMessage(input, { createdBy: { _id: currentUser._id } })];
                        case 2:
                            res = _a.sent();
                            localStore.updatedAt = Date.now();
                            if (res.isRestored) {
                                props.store.accountStore.refreshSidebarChatList();
                            }
                            localStore.isSent = true;
                            return [3 /*break*/, 5];
                        case 3:
                            error_1 = _a.sent();
                            localStore.error = formUserErrorMessage(error_1);
                            return [3 /*break*/, 5];
                        case 4:
                            localStore.isLoading = false;
                            setTimeout(function () {
                                var _a;
                                (_a = messageInputRef === null || messageInputRef === void 0 ? void 0 : messageInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                            }, 1000);
                            return [7 /*endfinally*/];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onEnter = function (e) {
        formRef.current.submitForm();
        return onPreventEvent(e);
    };
    var onChange = function (e) {
        sidebarChannelChat.typing({ chat: sidebarChannelChat.state.item });
    };
    React.useEffect(function () {
        var _a;
        (_a = messageInputRef === null || messageInputRef === void 0 ? void 0 : messageInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    // const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var chat = sidebarChannelChat.state.item;
    var loading = sidebarChannelChat.state.updating || localStore.isLoading;
    // const key = `${localStore.updatedAt}-${localStore.isLoading ? 1 : 2}-${sidebarChannelChat.state.updating ? 1 : 2}`;
    return (React.createElement(Grid, { container: true, style: { padding: props.v3 ? 0 : theme.spacing(0, 2, 2, 2) }, className: clsx((_b = {}, _b[classes.disabled] = loading, _b)) },
        React.createElement(Formik, { innerRef: formRef, initialValues: localStore.defValue, onSubmit: localStore.onSubmit, validationSchema: Yup.object({
                text: Yup.string()
                    .required(langForm.t('form_input_validate_required'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .max(1500, langForm.t('form_input_validate_max_char').replace(/_X_/g, '1500')),
            }), validateOnBlur: false, validateOnChange: false }, function (formik) {
            var _a, _b, _c;
            var onInput = function (e) {
                formik.setFieldValue(e.target.name, e.target.value);
                onChange(e);
            };
            var _onChange = function (files, opt) {
                if (opt === void 0) { opt = {}; }
                return __awaiter(void 0, void 0, void 0, function () {
                    var i, e;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                i = 0;
                                _a.label = 1;
                            case 1:
                                if (!(files === null || files === void 0 ? void 0 : files.length)) return [3 /*break*/, 5];
                                e = files.shift();
                                return [4 /*yield*/, localStore.onSubmit(__assign(__assign({}, localStore.defValue), { text: i === 0 ? opt.comment : '', upload: e === null || e === void 0 ? void 0 : e._id }))];
                            case 2:
                                _a.sent();
                                return [4 /*yield*/, formik.setFieldValue('text', "")];
                            case 3:
                                _a.sent();
                                return [4 /*yield*/, formik.setFieldValue('upload', null)];
                            case 4:
                                _a.sent();
                                i++;
                                return [3 /*break*/, 1];
                            case 5:
                                setTempValue({
                                    files: [],
                                });
                                return [2 /*return*/];
                        }
                    });
                });
            };
            var getAddDocumentComponent = function (initAction) { return (React.createElement(UploadList, __assign({ v4: true, onChange: _onChange, onClose: function (e) {
                    if (props.setIsDragging)
                        props.setIsDragging(false);
                    if (props.setIsUploading)
                        props.setIsUploading(false);
                    setTempValue({
                        files: [],
                    });
                }, config: config, defaultValue: __assign(__assign({}, tempValue), { text: formik.values.text }), noEmail: true, actionInit: initAction, quote: {
                    parent_collection_name: 'order_chats',
                    parent: chat === null || chat === void 0 ? void 0 : chat._id,
                } }, {
                parent_collection_name: 'order_chats',
                parent: chat === null || chat === void 0 ? void 0 : chat._id,
                folder: "private/chats/" + ((chat === null || chat === void 0 ? void 0 : chat._id) || 'create') + "/",
            }))); };
            var allUploads = (React.createElement(PreloadingComponent, null,
                React.createElement(AllUploadList, __assign({}, (props.v3 && { v3: true }), { hideHeader: true, config: config, defaultFilter: { fields: [{ name: 'parent', value: localStore.defValue.chat }] } }))));
            var uploadNew = (React.createElement(UploadList, __assign({ onChange: _onChange, config: config, actionInit: function (onShowModal) {
                    return props.v3 ? (React.createElement(AddFormButton, { onClick: onShowModal, title: langOfferForm.t('offer_form_document_add') })) : (React.createElement(MainButton, { fullWidth: true, 
                        // loading={loading}
                        onClick: onShowModal, title: langOfferForm.t('offer_form_document_add') }));
                }, quote: {
                    parent_collection_name: 'order_chats',
                    parent: chat === null || chat === void 0 ? void 0 : chat._id,
                } }, {
                parent_collection_name: 'order_chats',
                parent: chat === null || chat === void 0 ? void 0 : chat._id,
                folder: "private/chats/" + ((chat === null || chat === void 0 ? void 0 : chat._id) || 'create') + "/",
            })));
            if ((_b = (_a = sidebarChannelChat.state) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.isDeleted) {
                return (React.createElement(Grid, { container: true, alignItems: "flex-end", justify: "flex-end" },
                    React.createElement(Divider, { style: { width: '100%' } }),
                    React.createElement(TextOverflow, { color: "primary", variant: "h5" }, i18n.getResource(i18n.language, KEYS.MENU, 'menu_sidebar_archive_title'))));
            }
            var sendBtn = (React.createElement(MainButton, __assign({}, (props.v3 && { v3: true }), { fullWidth: !props.v3, type: "submit", loading: loading, title: langForm.t('form_btn_to_send_from_forwarder_to_customer') })));
            return (React.createElement(Form, { id: 'creation-form', style: { width: '100%' } },
                React.createElement("input", { "data-id": "file-select-chat-" + wrapTextToId((_c = sidebarChannelChat.state) === null || _c === void 0 ? void 0 : _c._id), id: 'file-select-record', type: 'file', style: { position: 'absolute', left: "-100vw", zIndex: 99999999 }, ref: fileInput }),
                props.v3 ? (React.createElement(Grid, { item: true, container: true, xs: 12, style: { position: 'relative' }, alignItems: 'center', spacing: 1 },
                    React.createElement(Grid, { item: true, container: true, xs: 12, md: 4, alignItems: 'center' },
                        allUploads,
                        React.createElement("div", null, uploadNew)),
                    React.createElement(Grid, { item: true, container: true, xs: 12, md: true, justify: 'flex-end' },
                        React.createElement(Grid, { item: true, xs: 12, md: 7 },
                            React.createElement(FormikTextField, { id: KEY, title: langForm.t('form_input_comment'), variant: "outlined", name: "text", value: formik.values.text, disabled: loading, className: classes.root, onEnter: onEnter, onChange: onChange, inputRef: messageInputRef }))),
                    React.createElement(Grid, { item: true, xs: 12, md: 'auto', style: { paddingRight: 0 } }, sendBtn))) : (React.createElement(Grid, { container: true, direction: "column", spacing: 2 },
                    React.createElement(Grid, { item: true, xs: 12, style: { position: 'relative' } }, !props.isDragging ? (React.createElement(React.Fragment, null,
                        getAddDocumentComponent(function (handleClickOpen) { return (React.createElement(FormikTextField, { id: KEY, multiline: true, rows: 4, disabled: loading, title: langForm.t('form_input_comment'), variant: "outlined", name: "text", value: formik.values.text, className: classes.root, onPaste: function (e) {
                                var clipboardItems = e.clipboardData.items;
                                var items = [].slice.call(clipboardItems).filter(function (item) {
                                    return /^image\//.test(item.type);
                                });
                                if (items.length === 0) {
                                    return;
                                }
                                var input = Object.assign({
                                    file: items[0].getAsFile(),
                                    folder: "private/chats/" + ((chat === null || chat === void 0 ? void 0 : chat._id) || 'create') + "/",
                                });
                                var item = __assign(__assign({}, input), { _id: Date.now() + "-custom-0", size: input.file.size, name: input.file.name, file: input.file, path: URL.createObjectURL(input.file), pathBlob: URL.createObjectURL(input.file), isUserAction: true, createdBy: { _id: currentUser._id } });
                                setTempValue({
                                    files: [item],
                                });
                                handleClickOpen();
                                if (props.setIsUploading)
                                    props.setIsUploading(true);
                            }, onEnter: onEnter, onChange: onChange, inputRef: messageInputRef, InputProps: {
                                endAdornment: (React.createElement(InputAdornment, { position: "end", style: { alignItems: 'flex-end' } },
                                    React.createElement(BaseTooltip, { title: langForm.t('form_upload_document') },
                                        React.createElement(BaseIconButton, { style: { padding: 0, marginBottom: theme.spacing(2) }, color: 'primary', onMouseEnter: function (e) { return onPreventEvent(e); }, onClick: function () {
                                                var e = [];
                                                for (var _i = 0; _i < arguments.length; _i++) {
                                                    e[_i] = arguments[_i];
                                                }
                                                handleClickOpen.apply(void 0, __spread(e));
                                                if (props.setIsUploading)
                                                    props.setIsUploading(true);
                                            } },
                                            React.createElement(AttachFileIcon, { style: { color: 'rgba(0, 0, 0, 0.54)' } }))))),
                            } })); }),
                        React.createElement(Emoji, { onChange: function (emoji) {
                                return onInput({ target: { name: 'text', value: (formik.values.text || '') + emoji } });
                            } }))) : (getAddDocumentComponent(function (handleClickOpen) {
                        var _a;
                        return (React.createElement(UploadWithDragAndDrop, { singleFileV1: true, v3: true, config: config, name: 'files', onShow: function () {
                                handleClickOpen();
                                setTempValue({
                                    files: [],
                                });
                            }, value: [], onChange: function (e) {
                                var _a;
                                setTempValue({
                                    files: (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value,
                                });
                                handleClickOpen();
                                if (props.setIsUploading)
                                    props.setIsUploading(true);
                            }, UploadProps: {
                                _id: (_a = sidebarChannelChat.state.item) === null || _a === void 0 ? void 0 : _a._id,
                                folder: "private/chats/" + ((chat === null || chat === void 0 ? void 0 : chat._id) || 'create') + "/",
                            }, item: sidebarChannelChat.state.item, parent_collection_name: 'order_chats', noUpload: true }));
                    }))),
                    React.createElement(Grid, { item: true, xs: 12, container: true, style: { width: 'initial', paddingRight: 0 }, direction: 'row', spacing: 2, justify: 'flex-end' },
                        React.createElement(Grid, { container: true, item: true, xs: 12, style: { paddingRight: 0 } }, sendBtn))))));
        })));
});
// @ts-ignore
export default inject('store')(withSidebarChannelChatStore(SidebarChannelItemDetailsChatForm));
