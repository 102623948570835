var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import clsx from 'clsx';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
var EditorV1 = Editor;
var styles = function (theme) { return ({
    errorText: {
        color: theme.palette.error.main,
        marginTop: 4,
        fontSize: '0.75rem',
        fontWeight: 400,
        lineHeight: 1.66,
        marginLeft: 14,
        marginRight: 14,
    },
    root: {
        '& .rdw-editor-toolbar': {
            border: 'none',
            margin: 0,
        },
        '& .demo-editor': {
            height: 'initial',
            borderTop: "1px solid rgba(0, 0, 0, 0.23)",
            padding: theme.spacing(2),
            background: theme.palette.common.white,
        },
        '& .demo-wrapper': {
            height: 'initial',
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: 4,
        },
    },
    error: {
        '& .demo-editor': {
            borderTop: "1px solid rgba(0, 0, 0, " + theme.palette.error.main + ")",
        },
        '& .demo-wrapper': {
            border: "1px solid " + theme.palette.error.main,
        },
    },
}); };
var HtmlEditor = /** @class */ (function (_super) {
    __extends(HtmlEditor, _super);
    function HtmlEditor(props) {
        var _this = _super.call(this, props) || this;
        _this.onEditorStateChange = function (editorState) {
            _this.setState({
                editorState: editorState,
            });
            if (_this.props.onChange)
                _this.props.onChange({
                    target: { name: _this.props.name, value: _this.parseValue(editorState) },
                });
        };
        _this.onBlur = function (event, editorState) {
            if (_this.props.onBlur)
                _this.props.onBlur({
                    target: { name: _this.props.name, value: _this.parseValue(editorState) },
                });
        };
        var contentBlock = htmlToDraft(props.value || '');
        var contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        var editorState = EditorState.createWithContent(contentState);
        _this.state = {
            editorState: editorState,
        };
        return _this;
    }
    HtmlEditor.prototype.parseValue = function (editorState) {
        var val = draftToHtml(convertToRaw(editorState.getCurrentContent()));
        if (!val)
            return val;
        var div = document.createElement('div');
        div.innerHTML = val;
        if (!div.children[0].innerHTML)
            return '';
        return val;
    };
    HtmlEditor.prototype.render = function () {
        var _a;
        var _b = this.props, classes = _b.classes, className = _b.className;
        var editorState = this.state.editorState;
        return (React.createElement(Grid, { container: true, className: clsx(classes.root, className, (_a = {}, _a[classes.error] = this.props.error, _a)) },
            React.createElement(EditorV1, { readOnly: this.props.disabled, editorState: editorState, wrapperClassName: "demo-wrapper", editorClassName: "demo-editor", onEditorStateChange: this.onEditorStateChange, onBlur: this.onBlur }),
            (this.props.helperText && React.createElement("p", { className: classes.errorText }, this.props.helperText)) || null));
    };
    return HtmlEditor;
}(Component));
export default withStyles(styles, { withTheme: true })(HtmlEditor);
