var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid, MenuItem, useMediaQuery } from '@material-ui/core';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { toast } from 'react-toastify';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { Chip } from '@material-ui/core';
import { Form, Formik } from 'formik';
import FormGroup from '@material-ui/core/FormGroup/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import LinkIcon from '@material-ui/icons/Link';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { BaseUserAppTYpe, CompanyState, USER_STATES, USER_STATUS } from '../../stores/common';
import { BasicDialog, DeleteDialog } from '../../components/Dialog';
import { ApproveJoinCompany, ApproveRegisterUser, DeleteUser, LogInAs, ReInviteMember, UpdateCurrentUser, } from '../../graphql';
import { KEYS, i18n } from '../../i18n';
import { ACTION_CHANGE } from '../../components/BasicList';
import { theme } from '../../theme';
import { AvatarItemView } from '../../components/Account/Avatar';
import { FormikSelect, FormikTextField, LanguageMenuSmall, TextOverflow } from '../../components';
import { dateViewFormat } from '../../components/DateView';
import * as Yup from 'yup';
import { LoginAs } from './LoginAs';
import { AddMembersFormModal } from './AddMembersFormModal';
import EditPasswordForm from './EditPassword';
import { toDateOnly, copyLink } from '../../utils';
import { USER_ROLE_TOOLS } from '../../models/UserRoles';
import { AppType } from '../Quotes/Documents/DocumentItemVisibleTo';
import BaseTooltip from '../../components/BaseTooltip';
import BusinessIcon from '@material-ui/icons/Business';
import UserHistory from './UserHistory';
import MenuListItem from '../../components/MenuListItem';
import OrderRouteMenuItem from '../../components/Order/List/OrderRoutesMenuItem';
import EditIcon from '@material-ui/icons/Edit';
import PersonIcon from '@material-ui/icons/Person';
import SecurityIcon from '@material-ui/icons/Security';
import { ApproveCompanyDialog } from './ApproveCompanyDialog';
import { BaseListItemV1 } from '../../components/BasicList/ListItem/BaseListItemV1';
import { StyledBadgeV1 } from '../../components/StyledBadge';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { useSettingsStyles } from '../../components/Settings/styles';
import { formatPhoneNumber, googleTagManager } from '../../utils1';
import LoginWithQR from './LoginWithQR';
import BaseIconButton from '../../components/BaseIconButton';
import PreloadingComponent from '../../components/PreloadingComponent';
var SectionHistory = React.lazy(function () { return import('../../components/SectionDetails/SectionHistory'); });
export var CopyLinkToAccessAs = function (_a) {
    var record = _a.record, onCopy = _a.onCopy, config = _a.config, props = __rest(_a, ["record", "onCopy", "config"]);
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var langApp = useTranslation(KEYS.APP);
    var _copyLink = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    if (onCopy) {
                        copyLink(onCopy());
                        return [2 /*return*/];
                    }
                    if (record.status === USER_STATUS.APPROVE_REGISTER) {
                        if (!record.token)
                            return [2 /*return*/];
                        copyLink(record.token.link);
                        return [2 /*return*/];
                    }
                    setLoading(true);
                    _a = config;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: LogInAs,
                            variables: {
                                input: {
                                    email: record.email,
                                },
                            },
                        })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    res = _a;
                    copyLink(res.data.logInAs.appLink + ("?isManual=1&token=" + res.data.logInAs.token.replace("Bearer ", '')));
                    return [3 /*break*/, 5];
                case 3:
                    e_1 = _b.sent();
                    toast.error(e_1.message || e_1);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(OrderRouteMenuItem, __assign({}, {
        handleClickOpen: _copyLink,
        icon: props.v1 ? (React.createElement(BaseIconButton, { onClick: _copyLink, disabled: loading, style: __assign(__assign({}, props.style), { color: theme.palette.primary.light }) },
            React.createElement(LinkIcon, null))) : (React.createElement(BaseTooltip, { title: langApp.t('copy_link_access') },
            React.createElement(BaseIconButton, { onClick: _copyLink, disabled: loading, style: props.v1 ? { color: theme.palette.primary.light } : { padding: 0 } },
                React.createElement(FileCopyIcon, null)))),
        v3: props.v1,
        label: props.v1 ? null : langApp.t('copy_link_access'),
    })));
};
export var UserItem = inject('store')(observer(function (_a) {
    var _this = this;
    var _b, _c, _d, _e;
    var record = _a.record, onChange = _a.onChange, config = _a.config, isAdmin = _a.isAdmin, hideCompany = _a.hideCompany, isTeam = _a.isTeam, _f = _a.store.accountStore, currentUser = _f.currentUser, currentCompany = _f.currentCompany, headerColumns = _a.headerColumns, props = __rest(_a, ["record", "onChange", "config", "isAdmin", "hideCompany", "isTeam", "store", "headerColumns"]);
    var formRef = React.useRef();
    var classes = useSettingsStyles();
    var _g = __read(React.useState(record), 2), item = _g[0], setItem = _g[1];
    var _h = __read(React.useState(false), 2), loading = _h[0], setLoading = _h[1];
    var langApp = useTranslation(KEYS.APP);
    var langToast = useTranslation(KEYS.TOAST);
    var langForm = useTranslation(KEYS.FORM);
    var client = config && config.client;
    var is_admin = isAdmin;
    var isDiffCompany = ((_b = record.company) === null || _b === void 0 ? void 0 : _b.type) !== currentUser.company.type;
    var is_owner = record && record.createdBy && record.createdBy._id === currentUser._id && !isDiffCompany;
    var is_ownerCompany = ((_d = (_c = record === null || record === void 0 ? void 0 : record.createdBy) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d._id) === currentCompany._id && !isDiffCompany;
    var isMiddle = useMediaQuery(theme.breakpoints.down('sm'));
    var _onApproveRestrictedByLocationAccess = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, client.mutate({
                            mutation: UpdateCurrentUser,
                            variables: {
                                input: {
                                    _id: item._id,
                                    state: item.state.filter(function (e) { return e !== USER_STATES.RESTRICTED_INVITED_VIA_LOCATION; }),
                                },
                            },
                        })];
                case 1:
                    _c.sent();
                    setItem(__assign(__assign({}, item), { state: item.state.filter(function (e) { return e !== USER_STATES.RESTRICTED_INVITED_VIA_LOCATION; }) }));
                    googleTagManager('event', 'izi_user_approved_allow_access_invited_by_location_created', {
                        approved_user: record._id,
                        approved_user_company: (_b = (_a = record === null || record === void 0 ? void 0 : record.createdBy) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b._id,
                    });
                    toast.success(i18n
                        .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                        .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_user')));
                    setTimeout(function () {
                        onChange && onChange(item, ACTION_CHANGE.DELETED);
                    }, 1000);
                    return [3 /*break*/, 4];
                case 2:
                    e_2 = _c.sent();
                    toast.error(e_2.message);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _onApprove = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_3;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, client.mutate({
                            mutation: ApproveRegisterUser,
                            variables: {
                                input: {
                                    _id: record._id,
                                },
                            },
                        })];
                case 1:
                    res = _c.sent();
                    setItem(__assign(__assign({}, item), res.data.approveRegisterUser));
                    googleTagManager('event', 'izi_user_approved_register', {
                        approved_user: record._id,
                        approved_user_company: (_b = (_a = record === null || record === void 0 ? void 0 : record.createdBy) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b._id,
                    });
                    toast.success('User Approved');
                    setTimeout(function () {
                        onChange && onChange(item, ACTION_CHANGE.DELETED);
                    }, 1000);
                    return [3 /*break*/, 4];
                case 2:
                    e_3 = _c.sent();
                    toast.error(e_3.message);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _onApproveCompanyJoin = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, prevCompanyId, e_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, client.mutate({
                            mutation: ApproveJoinCompany,
                            variables: {
                                input: {
                                    user: record._id,
                                    company: record.company._id,
                                },
                            },
                        })];
                case 1:
                    res = _a.sent();
                    prevCompanyId = record.company._id;
                    setItem(__assign(__assign({}, item), { joinRequest: null, company: res.data.approveJoinCompany }));
                    googleTagManager('event', 'izi_user_approved_company_joined', {
                        approved_company: record.company._id,
                    });
                    toast.success(res.data.approveJoinCompany._id === prevCompanyId
                        ? langToast.t('toast_items_saved').replace(/_X_/, langApp.t('company_label'))
                        : langApp.t('join_approved'));
                    setTimeout(function () {
                        onChange && onChange(item, ACTION_CHANGE.DELETED);
                    }, 1000);
                    return [3 /*break*/, 4];
                case 2:
                    e_4 = _a.sent();
                    toast.error(e_4.message);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _onDeclineCompanyJoin = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var e_5;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, client.mutate({
                            mutation: ApproveJoinCompany,
                            variables: {
                                input: {
                                    user: record._id,
                                    company: record.company._id,
                                    state: 1,
                                },
                            },
                        })];
                case 1:
                    _a.sent();
                    toast.success(langToast.t('toast_items_saved').replace(/_X_/, langApp.t('company_label')));
                    setTimeout(function () {
                        onChange && onChange(item, ACTION_CHANGE.DELETED);
                    }, 1000);
                    return [3 /*break*/, 4];
                case 2:
                    e_5 = _a.sent();
                    toast.error(e_5.message);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _copyLink = function () {
        if (!item.token)
            return;
        copyLink(item.token.link);
    };
    var onReInviteUser = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_6;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, client.mutate({
                            mutation: ReInviteMember,
                            variables: {
                                _id: item._id,
                            },
                        })];
                case 1:
                    _a.sent();
                    toast.success(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_items_invitation_sent_repeat'));
                    return [3 /*break*/, 4];
                case 2:
                    e_6 = _a.sent();
                    toast.error(e_6.message);
                    return [3 /*break*/, 4];
                case 3:
                    setTimeout(function () {
                        onChange && onChange(item, ACTION_CHANGE.DELETED);
                        setLoading(false);
                    }, 300);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _onChange = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var _a, name, value, updates, e_7;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _a = e.target, name = _a.name, value = _a.value;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 5, 6, 7]);
                    setLoading(true);
                    updates = (_b = {},
                        _b[name] = name === 'roles'
                            ? [value]
                            : (name === 'status' &&
                                ((item.status === USER_STATUS.INACTIVE && USER_STATUS.ACTIVE) || USER_STATUS.INACTIVE)) ||
                                value,
                        _b);
                    if (name === 'email' && item.email === value)
                        return [2 /*return*/];
                    if (!(name === 'email')) return [3 /*break*/, 3];
                    return [4 /*yield*/, formRef.current.setFieldValue(name, value)];
                case 2:
                    _c.sent();
                    if (formRef.current.errors[name])
                        return [2 /*return*/, formRef.current.setFieldTouched(name)];
                    _c.label = 3;
                case 3: return [4 /*yield*/, client.mutate({
                        mutation: UpdateCurrentUser,
                        variables: {
                            input: __assign({ _id: item._id }, updates),
                        },
                    })];
                case 4:
                    _c.sent();
                    setItem(__assign(__assign({}, item), updates));
                    toast.success('User updated');
                    return [3 /*break*/, 7];
                case 5:
                    e_7 = _c.sent();
                    toast.error(e_7.message);
                    return [3 /*break*/, 7];
                case 6:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var onDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _a = config;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: DeleteUser,
                            variables: { _id: record._id },
                        })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    _a;
                    setTimeout(function () {
                        onChange && onChange(item, ACTION_CHANGE.DELETED);
                    }, 300);
                    return [2 /*return*/];
            }
        });
    }); };
    var validationSchema = Yup.object().shape(Object.assign({}, (is_admin && {
        email: Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/g, '50'))
            .email(langForm.t('form_input_validate_invalid_email'))
            .required(langForm.t('form_input_validate_required')),
    }) ||
        null));
    var canEdit = currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); });
    var restrictedAccess = item.status === USER_STATUS.ACTIVE && (((_e = item.company) === null || _e === void 0 ? void 0 : _e.state) || []).includes(CompanyState.ORDER_ACCESS_RESTRICTION);
    var restrictedInvitedViaLocation = item.status === USER_STATUS.ACTIVE && ((item === null || item === void 0 ? void 0 : item.state) || []).includes(USER_STATES.RESTRICTED_INVITED_VIA_LOCATION);
    var status = (React.createElement(Chip, { label: React.createElement(TextOverflow, { variant: 'caption', style: { color: theme.palette.common.white } }, item.status), style: __assign({ maxWidth: "calc(100% - 23px)", color: theme.palette.common.white }, (item.status === USER_STATUS.REGISTER
            ? { backgroundColor: theme.palette.success.light }
            : item.status === USER_STATUS.APPROVE_REGISTER
                ? { backgroundColor: theme.palette.warning.light }
                : item.status === USER_STATUS.INVITE_AFFILIATE
                    ? { backgroundColor: theme.palette.error.light }
                    : null)) }));
    return (React.createElement(Formik, { initialValues: item, validationSchema: validationSchema, onSubmit: function (values, actions) { return values; }, innerRef: formRef }, function (_a) {
        var _b, _c, _d;
        var values = _a.values, handleChange = _a.handleChange;
        var childs = [
            {
                value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center', style: { minHeight: theme.spacing(10) } },
                    React.createElement(Grid, { container: true, direction: 'column' },
                        React.createElement(AvatarItemView, { isAdmin: is_admin, more: true, typographyProps: {
                                variant: 'h6',
                                style: {
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: theme.palette.common.white,
                                },
                            }, user: record }),
                        React.createElement(TextOverflow, { variant: 'caption' }, record.rolesV1.map(function (e) { return e.name; }).join(', '))))),
            },
            {
                value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' }, is_admin ? (React.createElement(FormikTextField, { variant: "outlined", name: "email", onBlur: _onChange })) : (React.createElement(TextOverflow, { variant: 'subtitle1' }, record.email)))),
            },
            {
                value: (React.createElement(Grid, { item: true, xs: 12, container: true, justify: isMiddle ? 'flex-start' : 'flex-end' },
                    React.createElement(TextOverflow, { variant: 'subtitle1' }, formatPhoneNumber(record.phone)))),
            },
            !hideCompany && !props.v3
                ? {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' },
                        React.createElement(TextOverflow, { variant: 'subtitle1' }, (record.company &&
                            [record.company.name, record.company.vatCode].filter(function (e) { return e; }).join(', ')) ||
                            '-'))),
                }
                : null,
            is_admin
                ? {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' },
                        is_admin ? (React.createElement("div", null,
                            React.createElement(FormikSelect, { variant: "standard", value: values.appType, disabled: values.appType === BaseUserAppTYpe.SYSTEM, name: "appType", multiple: true, renderValue: function (selected) {
                                    return (React.createElement(Grid, { container: true, alignItems: 'center' }, selected.map(function (item) { return (React.createElement(MenuItem, { key: item, value: item, style: { padding: theme.spacing(0), marginRight: theme.spacing(0.5) } },
                                        React.createElement(AppType, { type: item }))); })));
                                }, onChange: _onChange }, Object.values(BaseUserAppTYpe).map(function (type, index) {
                                return (React.createElement(MenuItem, { key: type, value: type },
                                    React.createElement(AppType, { type: type })));
                            })))) : (React.createElement(React.Fragment, null, Object.values(BaseUserAppTYpe)
                            .filter(function (e) { return record.appType.includes(e); })
                            .map(function (e) { return (React.createElement(AppType, { type: e, title: e, key: e })); }))),
                        React.createElement("div", { style: { paddingLeft: theme.spacing(0.5) } },
                            React.createElement(LanguageMenuSmall, { hideLabel: true, defaultValue: record })))),
                }
                : null,
            {
                value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center', justify: isMiddle ? 'flex-start' : 'flex-end' },
                    React.createElement(TextOverflow, { variant: 'caption' }, [record.createdAt]
                        .filter(function (e) { return e; })
                        .map(function (e) { return dateViewFormat(e, 1); })
                        .join('/')))),
            },
            isAdmin
                ? {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center' },
                        React.createElement(TextOverflow, { variant: 'caption' }, [record.latestVisitAt]
                            .filter(function (e) { return e; })
                            .map(function (e) { return dateViewFormat(e, 1); })
                            .join('/')))),
                }
                : null,
            !props.v3
                ? {
                    value: (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: 'center', justify: isMiddle ? 'flex-start' : 'flex-end' },
                        (is_admin && item.lastNote && (React.createElement(TextOverflow, { variant: "caption", style: { width: '100%' } }, item.lastNote.text + " at " + toDateOnly(item.lastNote.createdAt)))) ||
                            null,
                        (isAdmin || (canEdit && (isTeam || is_ownerCompany))) &&
                            [USER_STATUS.INACTIVE, USER_STATUS.ACTIVE].includes(item.status) ? (React.createElement(FormGroup, null,
                            React.createElement(FormControlLabel, { control: React.createElement(BaseTooltip, { title: (item.status === USER_STATUS.INACTIVE
                                        ? 'User was temporary restricted. Click to allow access'
                                        : 'User is active. Click to temporary restrict access') },
                                    React.createElement(Switch, { color: 'primary', checked: item.status === USER_STATUS.ACTIVE, onChange: _onChange, name: 'status' })), label: React.createElement(Grid, null) }))) : (status),
                        React.createElement(MenuListItem, { id: [record.email, record._id].join('_'), show: true, list: [
                                {
                                    content: (React.createElement(OrderRouteMenuItem, __assign({}, {
                                        handleClickOpen: onReInviteUser,
                                        icon: (React.createElement(BaseIconButton, { edge: "start", color: "primary", "aria-label": "menu", id: 're-invite', style: { padding: 0 }, disabled: loading },
                                            React.createElement(AutorenewIcon, null))),
                                        label: langApp.t('team_re_invite'),
                                    }))),
                                    available: (is_admin || is_owner || (canEdit && (is_ownerCompany || isTeam))) &&
                                        [USER_STATUS.APPROVE_REGISTER].includes(item.status),
                                },
                                {
                                    content: (React.createElement(BasicDialog, { body: React.createElement(TextOverflow, { style: { textAlign: 'center', margin: 'auto' }, variant: 'subtitle1' }, item.status === USER_STATUS.REGISTER
                                            ? 'Are you sure to allow user access to work in our platform?'
                                            : 'Are you sure to allow user full access to work in our platform?'), withoutWrapper: true, title: ['Approve user', restrictedAccess && '(Restricted access)']
                                            .filter(function (e) { return e; })
                                            .join('. '), onOk: _onApprove, actionInit: function (handleClickOpen) {
                                            return (React.createElement(OrderRouteMenuItem, __assign({}, {
                                                handleClickOpen: handleClickOpen,
                                                icon: (React.createElement(BaseIconButton, { disabled: loading, style: { padding: 0 } },
                                                    React.createElement(ThumbUpIcon, { color: 'primary' }))),
                                                label: ['Approve user', restrictedAccess && '(Restricted access)']
                                                    .filter(function (e) { return e; })
                                                    .join('. '),
                                            })));
                                        } })),
                                    available: is_admin &&
                                        ([USER_STATUS.REGISTER, USER_STATUS.INVITE_AFFILIATE].includes(item.status) ||
                                            restrictedAccess),
                                },
                                {
                                    content: (React.createElement(BasicDialog, { body: React.createElement(TextOverflow, { style: { textAlign: 'center', margin: 'auto' }, variant: 'subtitle1' }, langApp.t('ask_allow_access')), withoutWrapper: true, title: [langForm.t('form_btn_confirm')].filter(function (e) { return e; }).join('. '), onOk: _onApproveRestrictedByLocationAccess, actionInit: function (handleClickOpen) {
                                            return (React.createElement(OrderRouteMenuItem, __assign({}, {
                                                handleClickOpen: handleClickOpen,
                                                icon: (React.createElement(BaseIconButton, { disabled: loading, style: { padding: 0 } },
                                                    React.createElement(ThumbUpIcon, { color: 'primary' }))),
                                                label: [langForm.t('form_btn_confirm')].filter(function (e) { return e; }).join('. '),
                                            })));
                                        } })),
                                    available: (is_admin || isTeam) && restrictedInvitedViaLocation,
                                },
                                {
                                    content: (React.createElement(OrderRouteMenuItem, __assign({}, {
                                        handleClickOpen: _copyLink,
                                        icon: (React.createElement(BaseIconButton, { style: { padding: 0 } },
                                            React.createElement(CheckCircleOutlineIcon, null))),
                                        label: "Link to change password",
                                    }))),
                                    available: is_admin &&
                                        [USER_STATUS.APPROVE_REGISTER, USER_STATUS.INVITE_AFFILIATE].includes(item.status) &&
                                        item.token,
                                },
                                {
                                    content: (React.createElement(ApproveCompanyDialog, { item: record, onOk: _onApproveCompanyJoin, onDeclineCompanyJoin: _onDeclineCompanyJoin, isAdmin: isAdmin, client: config.client, loading: loading, handleClickOpen: function (handleClickOpen) {
                                            var _a, _b;
                                            return (React.createElement(OrderRouteMenuItem, { handleClickOpen: handleClickOpen, icon: ((_a = item.joinRequest) === null || _a === void 0 ? void 0 : _a._id) === item.company._id ? (React.createElement(BaseIconButton, { style: { padding: 0 } },
                                                    React.createElement(BusinessIcon, { style: { color: theme.palette.success.light } }))) : (React.createElement(BaseIconButton, { style: { padding: 0 } },
                                                    React.createElement(BusinessIcon, { style: { color: theme.palette.warning.light } }))), label: ((_b = item.joinRequest) === null || _b === void 0 ? void 0 : _b._id) === item.company._id
                                                    ? langApp.t('app_approve_create_company')
                                                    : langApp.t('app_approve_join_company').replace('_X_', '') }));
                                        } })),
                                    available: is_admin && item.joinRequest,
                                },
                                {
                                    content: (React.createElement(AddMembersFormModal, { tab: true, item: record, onChange: onChange, isAdmin: isAdmin, client: config.client, handleClickOpen: function (handleClickOpen) {
                                            return (React.createElement(OrderRouteMenuItem, __assign({}, {
                                                handleClickOpen: handleClickOpen,
                                                icon: (React.createElement(BaseTooltip, { title: langApp.t('team_label_edit') },
                                                    React.createElement(BaseIconButton, { color: 'primary', id: 'edit-btn', style: { padding: 0 } },
                                                        React.createElement(EditIcon, null)))),
                                                label: langApp.t('team_label_edit'),
                                            })));
                                        } })),
                                    available: is_admin || is_owner || (canEdit && (is_ownerCompany || isTeam)),
                                    style: { width: '100%' },
                                },
                                {
                                    content: (React.createElement(LoginAs, { config: config, record: record, handleClickOpen: function (handleClickOpen) {
                                            return (React.createElement(OrderRouteMenuItem, __assign({}, {
                                                handleClickOpen: handleClickOpen,
                                                icon: (React.createElement(BaseTooltip, { title: langApp.t('team_label_edit') },
                                                    React.createElement(BaseIconButton, { disabled: loading, "aria-label": "menu", color: 'primary', onClick: handleClickOpen, style: { padding: 0 } },
                                                        React.createElement(PersonIcon, null)))),
                                                label: 'Login in as user',
                                            })));
                                        } })),
                                    available: [USER_STATUS.ACTIVE].includes(item.status) &&
                                        (is_admin || is_owner || (canEdit && (is_ownerCompany || isTeam))),
                                },
                                {
                                    content: (React.createElement(LoginWithQR, { config: config, record: record, handleClickOpen: function (handleClickOpen) {
                                            return (React.createElement(OrderRouteMenuItem, __assign({}, {
                                                handleClickOpen: handleClickOpen,
                                                icon: (React.createElement(BaseIconButton, { disabled: loading, "aria-label": "menu", color: 'primary', onClick: handleClickOpen, style: { padding: 0 } },
                                                    React.createElement(PersonIcon, null))),
                                                label: 'Login in with QR',
                                            })));
                                        } })),
                                    available: [USER_STATUS.ACTIVE].includes(item.status) &&
                                        (is_admin || is_owner || (canEdit && (is_ownerCompany || isTeam))) &&
                                        [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (e) {
                                            return record.appType.includes(e);
                                        }),
                                },
                                {
                                    content: React.createElement(CopyLinkToAccessAs, { record: record, config: config }),
                                    available: (is_admin || is_owner || (canEdit && (is_ownerCompany || isTeam))) &&
                                        [USER_STATUS.ACTIVE].includes(item.status),
                                },
                                {
                                    content: (React.createElement(EditPasswordForm, { record: record, config: config, onChange: onChange, handleClickOpen: function (handleClickOpen) {
                                            return (React.createElement(OrderRouteMenuItem, __assign({}, {
                                                handleClickOpen: handleClickOpen,
                                                icon: (React.createElement(BaseIconButton, { onClick: handleClickOpen, color: 'primary', id: 'edit-btn', style: { padding: 0 } },
                                                    React.createElement(SecurityIcon, null))),
                                                label: langForm
                                                    .t('form_invoice_create_edit')
                                                    .replace(/_X_/, langForm.t('form_input_name_password').toLowerCase()),
                                            })));
                                        } })),
                                    available: (is_admin || is_owner || (canEdit && (is_ownerCompany || isTeam))) &&
                                        [USER_STATUS.ACTIVE].includes(item.status),
                                },
                                {
                                    content: React.createElement(DeleteDialog, { withoutWrapper: true, withText: true, value: record.email, onOk: onDelete }),
                                    available: is_admin || is_owner || (canEdit && (is_ownerCompany || isTeam)),
                                },
                                {
                                    id: 'btn-order-history',
                                    content: (React.createElement(PreloadingComponent, null,
                                        React.createElement(SectionHistory, { v2: true, filters: {
                                                fields: [{ name: 'object', value: [record._id] }],
                                            }, record: record, config: config }))),
                                    available: is_admin || is_owner || (canEdit && (is_ownerCompany || isTeam)),
                                },
                            ] }),
                        is_admin ? React.createElement(UserHistory, { record: record }) : null)),
                }
                : null,
        ].filter(function (e) { return e; });
        return (React.createElement(Form, { style: { width: '100%', position: 'relative' } },
            restrictedAccess && isAdmin ? (React.createElement(BaseTooltip, { title: 'Limited access. Has invited during order sharing' },
                React.createElement("div", { className: classes.button, style: { position: 'absolute', right: 14, top: -6, zIndex: 1, width: 0 } },
                    React.createElement(StyledBadgeV1, { color: "primary", badgeContent: React.createElement(NotificationsActiveIcon, { color: "primary" }) })))) : null,
            restrictedInvitedViaLocation && (isAdmin || isTeam) ? (React.createElement(BaseTooltip, { title: langApp.t('restricted_access') },
                React.createElement("div", { className: classes.button, style: { position: 'absolute', right: 14, top: -6, zIndex: 1, width: 0 } },
                    React.createElement(StyledBadgeV1, { color: "primary", badgeContent: React.createElement(NotificationsActiveIcon, { color: "primary" }) })))) : null,
            ((_b = item.joinRequest) === null || _b === void 0 ? void 0 : _b._id) ? (React.createElement(BaseTooltip, { title: ((_c = item.joinRequest) === null || _c === void 0 ? void 0 : _c._id) === item.company._id ? 'Request to create company' : 'Request to join company' },
                React.createElement("div", { style: { position: 'absolute', right: 50, top: -10, zIndex: 1, width: 0 } }, ((_d = item.joinRequest) === null || _d === void 0 ? void 0 : _d._id) === item.company._id ? (React.createElement(BusinessIcon, { style: { color: theme.palette.success.light } })) : (React.createElement(BusinessIcon, { style: { fill: theme.palette.warning.light } }))))) : null,
            React.createElement(BaseListItemV1, { childs: childs, useHeaderSize: true, headerMobileSize: 4, headerColumns: headerColumns })));
    }));
}));
