import React from 'react';
import { client } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';

const LocationsComponent = React.lazy(() => import('@izi-logistics/common/lib/pages/Locations'));
export default function LocationsList(props) {
  return (
    <PreloadingComponent>
      <LocationsComponent {...props} config={{ client }} isAdmin />
    </PreloadingComponent>
  );
}
