var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { LocationCountryFilterItem } from './List/ListFilterLocationCountryFilter';
import { TextOverflow } from '../TextOverflow';
import { dateViewFormat } from '../DateView';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useMainStyles } from '../../styles';
import { Link } from 'react-router-dom';
export default (function (_a) {
    var record = _a.record, props = __rest(_a, ["record"]);
    var isAdmin = window.location.href.match('localhost:3002') || window.location.href.match('admin');
    var withLink = isAdmin;
    var mainClasses = useMainStyles();
    return (React.createElement(Grid, { container: true, direction: 'column', className: mainClasses.widthInitial },
        React.createElement(Grid, { container: true, alignItems: 'center', className: mainClasses.widthInitial },
            React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', className: mainClasses.widthInitial },
                React.createElement(LocationCountryFilterItem, { short: true, _id: record.order.sorting_countryPickup }),
                React.createElement(TextOverflow, { color: 'primary', variant: 'caption', style: { lineHeight: 1 } }, dateViewFormat(record.order.sorting_pickupAt))),
            React.createElement(ArrowForwardIosIcon, null),
            React.createElement(Grid, { container: true, alignItems: 'center', direction: 'column', className: mainClasses.widthInitial },
                React.createElement(LocationCountryFilterItem, { short: true, _id: record.order.sorting_countryDelivery }),
                React.createElement(TextOverflow, { color: 'primary', variant: 'caption', style: { lineHeight: 1 } }, dateViewFormat(record.order.sorting_deliveryAt)))),
        withLink ? (React.createElement(Grid, { container: true },
            React.createElement(Link, { style: { textDecoration: 'none', color: 'initial' }, "data-id": record.order.orderId, to: "/orders?search=" + record.order._id },
                React.createElement(TextOverflow, { style: { textDecoration: 'underline' } }, [record.order.orderId, record.order.sorting_totalWeight && ' '].filter(function (e) { return e; }).join(', '))),
            record.order.sorting_totalWeight ? (React.createElement(React.Fragment, null,
                "\u2002",
                React.createElement(TextOverflow, null, "" + record.order.sorting_totalWeight))) : null)) : (React.createElement(TextOverflow, null, [record.order.orderId, record.order.sorting_totalWeight].filter(function (e) { return e; }).join(', ')))));
});
