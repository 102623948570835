var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import moment from 'moment';
import { FormHelperText, useTheme } from '@material-ui/core';
import { useObserver } from 'mobx-react';
import { Grid, Typography, TextField, InputAdornment, makeStyles } from '@material-ui/core';
import { DateRangePicker, MobileDateRangePicker } from '@material-ui/pickers';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CloseIcon from '@material-ui/icons/Close';
import { useField } from 'formik';
import { TextOverflow } from './TextOverflow';
import clsx from 'clsx';
import InputTitle from './Formik/InputTitle';
import BaseTooltip from './BaseTooltip';
import { i18n, KEYS } from '../i18n';
import { useStyles as useStylesFormik } from './Formik/styles';
import BaseIconButton from './BaseIconButton';
export var useRangeStyles = makeStyles(function (theme) { return ({
    startDate: {
        '& .MuiOutlinedInput-root': {
            paddingRight: 4,
        },
        '& .MuiInputAdornment-root ': {
            marginRight: 2,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderRight: 'none',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
    },
    startDateV1: {
        '& .MuiOutlinedInput-root': {
            paddingRight: 0,
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
            marginRight: 0,
            paddingBottom: 0,
            '& input': {
                paddingLeft: theme.spacing(1),
            },
        },
        '& .MuiInputAdornment-root ': {
            marginRight: theme.spacing(0.7),
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderRight: 'none',
            borderTop: 'none',
            borderLeft: 'none',
            borderRadius: 0,
        },
    },
    endDate: {
        '& .MuiOutlinedInput-root': {
            paddingRight: 4,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderLeft: 'none',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        },
        '& input': {
            paddingLeft: 0,
        },
    },
    endDateV1: {
        '& .MuiOutlinedInput-root': {
            paddingRight: 4,
            paddingBottom: 0,
            borderBottomLeftRadius: 0,
            borderTopLeftRadius: 0,
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderRight: 'none',
            borderTop: 'none',
            borderLeft: 'none',
            borderRadius: 0,
        },
        '& input': {
            paddingLeft: 0,
        },
        '& .MuiInputAdornment-root ': {
            margin: 0,
            '& button': {
                padding: "0!important",
            },
        },
    },
    root: {
        position: 'relative',
        width: '100%',
        '&>.MuiPickersDateRangePickerInput-root': {
            width: '100%',
        },
    },
    icon: {
        color: theme.palette.secondary.dark,
    },
    styleV1: {
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.grey['900'],
        },
    },
    input: {
        '&::placeholder': {
            color: theme.palette.grey['200'],
        },
    },
    between: {
        color: theme.palette.grey['200'],
    },
    rootWithClear: {
        '& .MuiInputAdornment-positionEnd': {
            position: 'absolute',
            right: 0,
        },
        '& input': {
            width: 'calc(100% - 16px)',
            minWidth: '1px !important',
            whiteSpace: 'nowrap',
            overflow: 'hidden !important',
            textOverflow: 'ellipsis',
        },
    },
}); });
export var RangeDatePicker = function (_a) {
    var title = _a.title, props = __rest(_a, ["title"]);
    var value = props.value, onClose = props.onClose, onChange = props.onChange, moreProps = __rest(props, ["value", "onClose", "onChange"]);
    var _b = __read(React.useState(false), 2), open = _b[0], setOpen = _b[1];
    var classes = useRangeStyles();
    var classesFormik = useStylesFormik();
    var _c = __read(React.useState([null, null]), 2), errors = _c[0], setError = _c[1];
    var theme = useTheme();
    var _d = __read(useField(props), 3), field = _d[0], meta = _d[1], helpers = _d[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = (touched && error) || '';
    if (touchedError && typeof touchedError === 'object') {
        if (Object.keys(touchedError).length) {
            touchedError = touchedError.toString();
        }
        else {
            touchedError = '';
        }
    }
    var _onChange = function (value) {
        helpers.setValue(value);
        onChange && onChange(value);
        if (value[0] && value[1] && value[0].isSame(value[1], 'day')) {
            setOpen(false);
            if (onClose)
                onClose(value);
        }
    };
    var style = { marginTop: 0, marginBottom: 0 };
    if (props.style)
        Object.assign(style, props.style);
    var titlePlace = props.placeholder || (typeof title === 'string' && title) || null;
    var inputClass = props.styleV1 ? classes.input : undefined;
    var inputStyle = props.styleV1 ? {} : {};
    var isTitleComponent = props.v1 || props.v14;
    var endAdorment = null;
    if (props.styleV1 || props.onClear) {
        if (value[0] && value[1]) {
            endAdorment = (React.createElement(BaseTooltip, { title: props.onClear
                    ? i18n.getResource(i18n.language, KEYS.APP, 'form_btn_clear')
                    : i18n.getResource(i18n.language, KEYS.ORDER_LIST, 'order_list_filter_reset_filters') },
                React.createElement(BaseIconButton, { onClick: props.onClear ||
                        (function () {
                            _onChange([null, null, 'closed']);
                            onClose && onClose([null, null, 'closed']);
                        }), style: { padding: 2 } },
                    React.createElement(CloseIcon, { className: classes.icon, style: { transform: 'scale(0.8)' } }))));
        }
    }
    else {
        endAdorment = React.createElement(DateRangeIcon, { className: classes.icon, style: { pointerEvents: 'none' } });
    }
    var propsDateRangePicker = __assign(__assign({ allowSameDateSelection: true, showTodayButton: true, startText: null, endText: null, value: value, minDate: props.minDate ? props.minDate : new Date(), inputFormat: 'DD MMM', inputPlaceholder: 'DD MM', mask: '' }, moreProps), { onClose: function () {
            if (onClose)
                onClose(value);
            setOpen(false);
        }, open: open, onChange: _onChange, onError: function (_a, _b) {
            var _c = __read(_a, 2), startReason = _c[0], endReason = _c[1];
            var _d = __read(_b, 2), start = _d[0], end = _d[1];
            if (startReason === 'invalidRange' || (start && end && moment(start).isSame(end, 'day'))) {
                setError([null, null]);
                return;
            }
            setError([startReason, endReason]);
        }, renderInput: function (startDate, endDate) {
            var _a, _b;
            if (!isTitleComponent) {
                Object.assign(startDate.inputProps, __assign(__assign({}, startDate.inputProps), { placeholder: '' }));
                Object.assign(endDate.inputProps, __assign(__assign({}, endDate.inputProps), { placeholder: '' }));
            }
            return (React.createElement(Grid, __assign({ container: true, direction: "row", justify: "space-between", spacing: 0 }, (_a = {}, _a['data-title-1'] = titlePlace, _a), { onClick: function () { return setOpen(true); } }),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, __assign({ id: props.id || ((props.title || 'date-range-input') + '-left').toString().replace(/ /gi, '').toLowerCase(), fullWidth: true, margin: "dense", name: 'startDate', autoComplete: "off", className: clsx(classesFormik.base, props.styleV1 ? classes.startDateV1 : classes.startDate) }, startDate, { error: Boolean(errors[0] || touchedError), InputProps: {
                            classes: { input: inputClass },
                            style: inputStyle,
                            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                                React.createElement(Typography, { className: props.styleV1 && !value[0] ? classes.between : '' }, "-"))),
                        }, helperText: "", style: style, placeholder: isTitleComponent ? 'dd mmm' : '', label: !isTitleComponent ? 'dd mmm' : '' }))),
                React.createElement(Grid, { item: true, xs: 6 },
                    React.createElement(TextField, __assign({ id: ((props.title || 'date-range-input') + '-right').toString().replace(/ /gi, '').toLowerCase(), fullWidth: true, margin: "dense", autoComplete: "off", className: clsx(classesFormik.base, props.styleV1 ? classes.endDateV1 : classes.endDate, (_b = {},
                            _b[classes.rootWithClear] = props.onClear,
                            _b)), name: 'endDate' }, endDate, { error: Boolean(errors[1] || touchedError), InputProps: {
                            classes: { input: inputClass },
                            style: inputStyle,
                            endAdornment: React.createElement(InputAdornment, { position: "end" }, endAdorment),
                        }, helperText: "", style: style, placeholder: isTitleComponent ? 'dd mmm' : '', label: !isTitleComponent ? 'dd mmm' : '' })))));
        } });
    return useObserver(function () { return (React.createElement(Grid, { container: true, className: clsx(classes.root, props.styleV1 ? classes.styleV1 : '') },
        React.createElement(InputTitle, __assign({}, props)),
        props.isMobile ? (React.createElement(MobileDateRangePicker, __assign({}, propsDateRangePicker))) : (React.createElement(DateRangePicker, __assign({}, propsDateRangePicker))),
        touchedError && (React.createElement(FormHelperText, { error: true },
            React.createElement(TextOverflow, { v2: true }, touchedError))))); });
};
