var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import ReactDOM from 'react-dom';
import { TextOverflow } from '../TextOverflow';
import { i18n, KEYS } from '../../i18n';
import { BasicDialog } from '../Dialog';
import GoogleMapReactV1 from 'google-map-react';
import { createMapOptions, MAP_DEFAULT_COORDINATES } from '../../utils1';
import { GridV1 } from '../Order/List/HTMLElements';
import { inject } from 'mobx-react';
import { Grid } from '@material-ui/core';
import { FormLocationInputAddress } from '../Order';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import LinearProgress from '@material-ui/core/LinearProgress/LinearProgress';
import { FormikGoogleTextFieldV1, theme } from '../..';
import { OrderLocation } from '../../models';
var GoogleMapReact = GoogleMapReactV1;
var mapKey = process.env.REACT_APP_GOOGLE_PLACES_API_KEY;
var is_map_hidden = 0; // parseInt(process.env.REACT_APP_HIDE_MAP || '0') === 1;
export default inject('store')(function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var currentCompany = _a.store.accountStore.currentCompany, props = __rest(_a, ["store"]);
    var _h = __read(React.useState(null), 2), infoWindow = _h[0], setInfoWindow = _h[1];
    var _j = __read(React.useState([]), 2), addresses = _j[0], setAddresses = _j[1];
    var _k = __read(React.useState(null), 2), address = _k[0], setAddress = _k[1];
    var _l = __read(React.useState(false), 2), loading = _l[0], setLoading = _l[1];
    var _m = __read(React.useState(null), 2), mapRef = _m[0], setMapRef = _m[1];
    var classes = useStyles();
    var title = i18n.getResource(i18n.language, KEYS.FORM, 'form_btn_pick_the_address') || 'form_btn_pick_the_address';
    React.useEffect(function () {
        return function () {
            setInfoWindow(null);
        };
    }, []);
    var defaultProps = __assign({}, MAP_DEFAULT_COORDINATES);
    var handleApiLoaded = function (_a) {
        var map = _a.map;
        navigator.geolocation.getCurrentPosition(function (opt) {
            var position = { lat: opt.coords.latitude, lng: opt.coords.longitude };
            map.setCenter(position);
            initPicker({ position: position, map: map });
        }, function () {
            initPicker({ position: map.getCenter(), map: map });
        });
    };
    var initPicker = function (_a) {
        var position = _a.position, map = _a.map;
        return __awaiter(void 0, void 0, void 0, function () {
            var _infoWindow;
            return __generator(this, function (_b) {
                _infoWindow = infoWindow;
                if (!infoWindow) {
                    _infoWindow = new google.maps.InfoWindow({
                        content: i18n.getResource(i18n.language, KEYS.FORM, 'form_btn_click_map'),
                        position: position,
                    });
                    setInfoWindow(_infoWindow);
                }
                _infoWindow.open(map);
                refreshAddresses(position);
                map.addListener('click', function (mapsMouseEvent) {
                    // Close the current InfoWindow.
                    infoWindow === null || infoWindow === void 0 ? void 0 : infoWindow.close();
                    // Create a new InfoWindow.
                    var _infoWindow = new google.maps.InfoWindow({
                        position: mapsMouseEvent.latLng,
                    });
                    _infoWindow.setContent(JSON.stringify(mapsMouseEvent.latLng.toJSON(), null, 2));
                    _infoWindow.open(map);
                    setInfoWindow(_infoWindow);
                    refreshAddresses({ lat: mapsMouseEvent.latLng.lat(), lng: mapsMouseEvent.latLng.lng() });
                });
                setMapRef(map);
                return [2 /*return*/];
            });
        });
    };
    var refreshAddresses = function (position, opt) { return __awaiter(void 0, void 0, void 0, function () {
        var _address, places, selected, lanLng, _infoWindow;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _address = opt === null || opt === void 0 ? void 0 : opt.address;
                    setLoading(true);
                    return [4 /*yield*/, currentCompany.locationGeoCodes({
                            fields: [{ name: 'name', value: _address || "latlng=" + (position === null || position === void 0 ? void 0 : position.lat) + "," + (position === null || position === void 0 ? void 0 : position.lng) }],
                        })];
                case 1:
                    places = _c.sent();
                    selected = places.length ? places[0] : __assign(__assign({}, position), { country: navigator.language.split('-')[0] });
                    setAddress(selected);
                    setAddresses(__spread(places, (_address ? [] : [selected])).filter(function (e) { return e; }));
                    if (opt === null || opt === void 0 ? void 0 : opt.isRefresh) {
                        lanLng = { lat: (_a = selected === null || selected === void 0 ? void 0 : selected.location) === null || _a === void 0 ? void 0 : _a.coordinates[1], lng: (_b = selected === null || selected === void 0 ? void 0 : selected.location) === null || _b === void 0 ? void 0 : _b.coordinates[0] };
                        infoWindow === null || infoWindow === void 0 ? void 0 : infoWindow.close();
                        _infoWindow = new google.maps.InfoWindow({
                            position: lanLng,
                        });
                        _infoWindow.setContent(OrderLocation.create(JSON.parse(JSON.stringify({ address: selected }))).fullAddress);
                        _infoWindow.open(mapRef);
                        setInfoWindow(_infoWindow);
                    }
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var onOk = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res, places, e_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    res = {};
                    [
                        'address',
                        'company',
                        'place_id',
                        'streetNumber',
                        'streetName',
                        'companyAddress',
                        'state',
                        'country',
                        'postal',
                        'area',
                    ].forEach(function (key) { return (res[key] = address[key]); });
                    res.location = {};
                    ['type', 'coordinates', 'rawOffset', 'zone'].forEach(function (key) { return (res['location'][key] = address['location'][key]); });
                    if (!res.location.type)
                        throw { message: i18n.getResource(i18n.language, KEYS.ERRORS, 'error_address_not_selected') };
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, currentCompany.locationGeoCode({
                            fields: [
                                { name: 'name', value: "latlng=" + ((_a = address.location) === null || _a === void 0 ? void 0 : _a.coordinates[1]) + "," + ((_b = address.location) === null || _b === void 0 ? void 0 : _b.coordinates[0]) },
                            ],
                        })];
                case 2:
                    places = _c.sent();
                    if (places)
                        Object.assign(res.location, places.location);
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _c.sent();
                    return [3 /*break*/, 4];
                case 4:
                    props.onSelect(res);
                    return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = !is_map_hidden && (React.createElement(GridV1, { style: { height: Math.max(300, window.innerHeight - 220), position: 'relative' } },
        React.createElement(GoogleMapReact, { bootstrapURLKeys: {
                key: ((_c = (_b = window.envirements) === null || _b === void 0 ? void 0 : _b.find(function (e) { return e.name === 'GOOGLE_PLACES_API_KEY'; })) === null || _c === void 0 ? void 0 : _c.value) || mapKey || '',
                libraries: 'places',
                language: 'en',
            }, center: ((_e = (_d = address === null || address === void 0 ? void 0 : address.location) === null || _d === void 0 ? void 0 : _d.coordinates) === null || _e === void 0 ? void 0 : _e.length) ? { lat: (_f = address === null || address === void 0 ? void 0 : address.location) === null || _f === void 0 ? void 0 : _f.coordinates[1], lng: (_g = address === null || address === void 0 ? void 0 : address.location) === null || _g === void 0 ? void 0 : _g.coordinates[0] }
                : defaultProps.center, defaultCenter: defaultProps.center, defaultZoom: defaultProps.zoom, onGoogleApiLoaded: handleApiLoaded, options: createMapOptions }),
        React.createElement(AddressesHelper, { list: addresses, address: address, loading: loading, onClick: function (e, _address) {
                var _a, _b;
                setAddress(_address);
                if ((_b = (_a = _address === null || _address === void 0 ? void 0 : _address.location) === null || _a === void 0 ? void 0 : _a.coordinates) === null || _b === void 0 ? void 0 : _b.length) {
                    infoWindow === null || infoWindow === void 0 ? void 0 : infoWindow.close();
                    var latLng = { lat: _address.location.coordinates[1], lng: _address.location.coordinates[0] };
                    // Create a new InfoWindow.
                    var _infoWindow = new google.maps.InfoWindow({
                        position: latLng,
                    });
                    _infoWindow.setContent(OrderLocation.create(JSON.parse(JSON.stringify({ address: _address }))).fullAddress);
                    _infoWindow.open(mapRef);
                    setInfoWindow(_infoWindow);
                }
            } }),
        React.createElement(Grid, { className: classes.root },
            React.createElement(FormikGoogleTextFieldV1, { v17: true, v25: true, v29: true, 
                // disabled={canNotEdit || !isClientExists}
                variant: "outlined", name: "client_address", title: i18n.getResource(i18n.language, KEYS.FORM, 'form_search_location'), onChange: function (e) { var _a, _b; return refreshAddresses(null, { address: (_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.address, isRefresh: true }); }, value: {} }))));
    var dialog = (React.createElement(BasicDialog, { body: dialogBody, title: title, onOk: onOk, dialogProps: { fullWidth: true, maxWidth: 'md' }, bodyStyle: { alignItems: 'flex-start' }, actionInit: props.actionInit
            ? props.actionInit
            : function (handleClickOpen) { return (React.createElement("a", { href: '#', onClick: handleClickOpen },
                React.createElement(TextOverflow, null, title))); } }));
    if (props.parent) {
        return ReactDOM.createPortal(dialog, props.parent);
    }
    return dialog;
});
var useStyles = makeStyles(function (theme) { return ({
    root: {
        zIndex: 1,
        position: 'absolute',
        top: theme.spacing(2),
        width: 280,
        left: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.common.white,
        opacity: 0.7,
        borderBottomRightRadius: 4,
        borderBottomLeftRadius: 4,
        '&:hover': {
            opacity: 1,
        },
    },
    option: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.grey[300],
            '& .checker': {
                '& path': {
                    fill: theme.palette.success.light,
                },
            },
        },
        borderRadius: 4,
    },
    optionSelected: {
        backgroundColor: theme.palette.grey[300],
        '& .checker': {
            '& path': {
                fill: theme.palette.success.light,
            },
        },
    },
}); });
var AddressesHelper = function (_a) {
    var list = _a.list, address = _a.address, loading = _a.loading, onClick = _a.onClick;
    var classes = useStyles();
    return (React.createElement(Grid, { container: true, direction: 'column', className: classes.root, style: { top: theme.spacing(14), borderTopRightRadius: 4, borderTopLeftRadius: 4 } },
        React.createElement(TextOverflow, { variant: 'subtitle1' }, i18n.getResource(i18n.language, KEYS.APP, 'your_addresses')),
        (loading && React.createElement(LinearProgress, { color: 'primary', style: { width: '100%' } })) || null,
        React.createElement(Grid, { container: true, style: { maxHeight: 240, overflowY: 'auto' } },
            list.map(function (option) {
                var _a;
                return (React.createElement(FormLocationInputAddress, { onClick: function (e) { return onClick(e, option); }, className: clsx(classes.option, (_a = {},
                        _a[classes.optionSelected] = JSON.stringify(option) === JSON.stringify(address),
                        _a)), key: option.place_id || JSON.stringify(option), option: { address: option }, content: React.createElement(CheckIcon, { className: 'checker' }) }));
            }),
            (!list.length && (React.createElement(TextOverflow, { variant: 'caption' }, i18n.getResource(i18n.language, KEYS.ORDER_LIST, 'order_list_filter_no_data_general')))) ||
                null)));
};
