var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import * as material from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import { theme } from '../../theme';
import { UploadItemFile } from '../Uploads/UploadItem';
import { useMainStyles } from '../../styles';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { TextOverflow } from '../TextOverflow';
import { AppType } from '../../pages/Quotes/Documents/DocumentItemVisibleTo';
import BaseTooltip from '../BaseTooltip';
import { createAppTypeUser } from '../../utils1';
import { browserStorage } from '../../browserStorage';
import { priceValue } from '../PriceComponent';
import { OrderCurrency } from '../../models';
var Box = material.Box;
var Avatar = material.Avatar, Grid = material.Grid, Popover = material.Popover, Typography = material.Typography, CircularProgress = material.CircularProgress;
export var useAvatarStyles = makeStyles(function (theme) { return ({
    root: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        right: 3,
        top: -9,
        width: 20,
        height: 20,
        background: 'transparent',
    },
    rootProgress: {
        textAlign: 'center',
        position: 'absolute',
        left: -4,
        top: -4,
        background: 'transparent',
        zIndex: 2,
    },
    rootProgressBack: {
        textAlign: 'center',
        position: 'absolute',
        left: -4,
        top: -4,
        background: 'transparent',
        zIndex: 1,
        color: theme.palette.grey['300'],
    },
    awfull: {
        color: theme.palette.error.light,
    },
    good: {
        color: theme.palette.warning.light,
    },
    ok: {
        color: theme.palette.warning.main,
    },
    awesome: {
        color: theme.palette.success.main,
    },
    great: {
        color: theme.palette.success.light,
    },
}); });
export var useAvatarStylesV1 = makeStyles(function (theme) { return ({
    avatarListed: {
        width: 40,
        height: 40,
        position: 'absolute!important',
        '&:hover': {
            zIndex: 999 + "!important",
            '& .avatar-item': {
                boxShadow: '-1px 10px 17px 1px rgba(0,0,0,0.75)',
            },
        },
    },
    avatar: {
        backgroundColor: theme.palette.primary.main,
    },
    avatarSmall: {
        height: theme.spacing(5),
        width: theme.spacing(5),
    },
    avatarInverted: {
        '&.avatar-item': {
            background: theme.palette.common.white,
            '& *': {
                color: theme.palette.primary.light + "!important",
                fill: theme.palette.primary.light + "!important",
            },
        },
    },
    avatarBorder: {
    // border: `2px solid ${theme.palette.common.white} `,
    },
    avatarBorderV1: {
        border: "2px solid " + theme.palette.error.light + " ",
    },
    avatarEdit: {
        width: '100%',
        '& .MuiAvatar-root': {
            margin: 'auto',
            width: theme.spacing(20),
            height: theme.spacing(20),
        },
    },
    photoButton: {
        cursor: 'pointer',
        textDecoration: 'underline',
        textAlign: 'center',
        '&:hover': {
            color: theme.palette.primary.main,
        },
    },
}); });
var images = {
    cacheBad: {},
    cache: {},
    loadImage: function (path) {
        return new Promise(function (resolve, reject) {
            var img = new Image();
            img.crossOrigin = 'anonymous';
            img.onload = function () { return resolve(img); };
            img.onerror = img.onError = function (e) {
                console.log('error', e);
                if (!images.cacheBad[path])
                    images.cacheBad[path] = 0;
                images.cacheBad[path]++;
                if (images.cacheBad[path] > 2) {
                    resolve('');
                }
                else {
                    reject(path);
                }
                // resolve(img);
            };
            var src = '';
            if (path.match(/qr\//)) {
                src =
                    path +
                        (path.match(/\?/gi) ? '&' : '?') +
                        ("token=" + browserStorage.getItem('token') + "&izi_client_id=" + browserStorage.getItem('client_id'));
            }
            else {
                src = window.APP_API_URL.replace(/\/graphql/gi, '') + "/source?url=" + path + "&token=" + browserStorage.getItem('token');
                // if (!Object.keys(images.cacheBad).find((f) => src.match(f))) {
                //   src = path;
                // }
            }
            img.src = src;
        });
    },
    check: function (path) { return __awaiter(void 0, void 0, void 0, function () {
        var canvas, img, cntx;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (images.cache[path])
                        return [2 /*return*/, images.cache[path]];
                    canvas = document.createElement('canvas');
                    return [4 /*yield*/, images.loadImage(path)];
                case 1:
                    img = _a.sent();
                    if (!img)
                        return [2 /*return*/, ''];
                    canvas.width = canvas.height = Math.max(160, Math.min(img.width, img.height));
                    cntx = canvas.getContext('2d');
                    cntx.drawImage(img, canvas.width / 2 - img.width / 2, canvas.height / 2 - img.height / 2);
                    images.cache[path] = canvas.toDataURL();
                    return [2 /*return*/, images.cache[path]];
            }
        });
    }); },
};
var useStylesAvatar = makeStyles(function (theme) { return ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}); });
var DEF_IMAGE = 'https://media3.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif';
export var ImagePreview = observer(function (_a) {
    var path = _a.path, style = _a.style, props = __rest(_a, ["path", "style"]);
    var _b = __read(React.useState(DEF_IMAGE), 2), imgPath = _b[0], setImgPath = _b[1];
    var _c = __read(React.useState(null), 2), timeout = _c[0], setTimeout1 = _c[1];
    /*eslint-disable */
    var checkAvatar = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    if (timeout)
                        clearTimeout(timeout);
                    _a = setImgPath;
                    return [4 /*yield*/, images.check(path)];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _b.sent();
                    setTimeout1(setTimeout(checkAvatar, 2000));
                    console.log('===---', e_1);
                    setImgPath('');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [path]);
    useEffect(function () {
        if (path) {
            checkAvatar();
        }
        else {
            setImgPath('');
        }
        return function () {
            if (timeout)
                clearTimeout(timeout);
        };
    }, [path]);
    if (!imgPath)
        return React.createElement(PersonIcon, null);
    return React.createElement("img", { style: __assign({ width: '100%' }, style), src: imgPath, alt: 'IZI' });
});
var AvatarItemComponent = function (_a) {
    var _b, _c;
    var _d, _e;
    var store = _a.store, user = _a.user, withoutRole = _a.withoutRole, withRating = _a.withRating, isRoleOnly = _a.isRoleOnly, props = __rest(_a, ["store", "user", "withoutRole", "withRating", "isRoleOnly"]);
    if (isRoleOnly)
        withoutRole = true;
    var _f = __read(React.useState(null), 2), timeout = _f[0], setTimeout1 = _f[1];
    var _g = __read(React.useState(DEF_IMAGE), 2), imgPath = _g[0], setImgPath = _g[1];
    var classesAvatar = useStylesAvatar();
    var classes = useAvatarStylesV1();
    var classsName = useAvatarStyles();
    var _h = __read(React.useState(null), 2), anchorEl = _h[0], setAnchorEl = _h[1];
    if (!user)
        return null;
    /*eslint-disable */
    var checkAvatar = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    if (timeout)
                        clearTimeout(timeout);
                    _a = setImgPath;
                    return [4 /*yield*/, images.check(user.avatar.path)];
                case 1:
                    _a.apply(void 0, [_b.sent()]);
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _b.sent();
                    setTimeout1(setTimeout(checkAvatar, 2000));
                    console.log('===---', e_2);
                    setImgPath('');
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, [user.avatar && user.avatar.path]);
    useEffect(function () {
        if (user.avatar && user.avatar.path) {
            checkAvatar();
        }
        else {
            setImgPath('');
        }
        return function () {
            if (timeout)
                clearTimeout(timeout);
        };
    }, [user.avatar && user.avatar.path]);
    /*eslint-enable */
    var item;
    var appUser = {};
    if (isRoleOnly) {
        appUser = createAppTypeUser(user.appType[0]);
    }
    if (user.avatar && user.avatar.path && !isRoleOnly) {
        var classList = clsx('avatar-item', props.className, classes.avatar, (_b = {},
            _b[classes.avatarSmall] = props.isSmall,
            _b[classes.avatarBorder] = props.border,
            _b[classes.avatarBorderV1] = props.isBorder,
            _b));
        item = (React.createElement(Avatar, { className: classList, key: user.updatedAt, style: props.avatarStyle }, imgPath ? React.createElement("img", { style: { width: '100%' }, src: imgPath, alt: 'Avatar' }) : React.createElement(PersonIcon, null)));
        if (props.more) {
            item = React.createElement(AvatarItemMore, __assign({}, props, { item: item, user: user }));
        }
    }
    else {
        item = React.createElement(AvatarItemDetails, __assign({}, props, { user: __assign(__assign({}, user), appUser), detailed: !withoutRole }));
        if (props.more) {
            item = React.createElement(AvatarItemMore, __assign({}, props, { item: item, user: user }));
        }
    }
    if (withRating && !props.more && user.rating) {
        item = (React.createElement(Box, { position: 'relative' },
            item,
            React.createElement(Box, { position: 'absolute', top: '11.5%', left: '11.5%', style: { transform: 'scale(1.0)' } },
                React.createElement(CircularProgress, { variant: "static", style: { width: 28, height: 28, transform: 'rotate(-90deg)', zIndex: 1 }, className: clsx(classsName.rootProgress, (_c = {},
                        _c[classsName.awfull] = user.rating < 0.3,
                        _c[classsName.ok] = user.rating >= 0.3 && user.rating < 0.5,
                        _c[classsName.good] = user.rating >= 0.5 && user.rating < 0.7,
                        _c[classsName.great] = user.rating >= 0.7 && user.rating < 1,
                        _c[classsName.awesome] = user.rating >= 1,
                        _c)), value: user.rating * 100 }))));
    }
    var affiliate = (((_d = user === null || user === void 0 ? void 0 : user.settings) === null || _d === void 0 ? void 0 : _d.personalAffiliateRevenue) && (React.createElement(TextOverflow, { style: {
            color: theme.palette.warning.light,
            fontSize: 10,
            lineHeight: 1,
            fontWeight: 700,
            textAlign: 'center',
        } }, priceValue({
        price: (_e = user.settings) === null || _e === void 0 ? void 0 : _e.personalAffiliateRevenue,
        currency: OrderCurrency.EUR,
    })))) ||
        null;
    if (props.hoverDetail) {
        return (React.createElement(BaseTooltip, { title: [
                [user.firstName, user.lastName].filter(function (e) { return e; }).join(' '),
                [user.email].filter(function (e) { return e; }).join(' '),
                [user.company && user.company.name].filter(function (e) { return e; }).join(' '),
                [user.company && user.company.vatCode].filter(function (e) { return e; }).join(' '),
            ]
                .filter(function (e) { return e; })
                .join(', ') },
            React.createElement("div", null, item)));
    }
    else if (props.moreDetail) {
        var onMouseEnter = function (event) {
            setAnchorEl(event.currentTarget);
        };
        var onMouseLeave = function () {
            setAnchorEl(null);
        };
        var avatarStyle = props.avatarStyle, moreProps = __rest(props, ["avatarStyle"]);
        return (React.createElement("div", { style: { position: 'relative', width: 'fit-content' } },
            !withoutRole ? React.createElement(AvatarItemMoreDetails, __assign({}, props, { user: user })) : null,
            React.createElement("div", { onMouseEnter: onMouseEnter, onTouchStart: onMouseEnter, onMouseLeave: onMouseLeave },
                item,
                affiliate),
            React.createElement(Popover, { open: Boolean(anchorEl), anchorEl: anchorEl, disableRestoreFocus: true, disableScrollLock: true, className: classesAvatar.popover, onClose: onMouseLeave, style: { zIndex: 1302 }, classes: {
                    paper: classesAvatar.paper,
                }, anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                }, transformOrigin: {
                    vertical: 'top',
                    horizontal: 'center',
                } },
                React.createElement(AvatarItemMore, __assign({}, props, { key: imgPath || 1, item: React.createElement(AvatarItemDetails, __assign({}, moreProps, { noInverted: true, imgPath: imgPath, user: user })), user: user })))));
    }
    return (React.createElement(React.Fragment, null,
        item,
        affiliate));
};
export var AvatarItemView = inject('store')(observer(AvatarItemComponent));
var AvatarItemDetails = function (_a) {
    var _b;
    var user = _a.user, imgPath = _a.imgPath, detailed = _a.detailed, noInverted = _a.noInverted, props = __rest(_a, ["user", "imgPath", "detailed", "noInverted"]);
    var classes = useAvatarStylesV1();
    var firstName = user.firstName, lastName = user.lastName;
    var icon;
    var classList = clsx(props.className, classes.avatar, (_b = {},
        _b['avatar-item'] = !!user.company && !noInverted,
        _b[classes.avatarSmall] = props.isSmall,
        _b[classes.avatarBorder] = props.border,
        _b[classes.avatarBorderV1] = props.isBorder,
        _b));
    if (imgPath) {
        icon = (React.createElement(Avatar, { className: classList, key: user.updatedAt },
            React.createElement("img", { style: { width: '100%' }, src: imgPath, alt: 'Avatar' })));
    }
    else {
        icon = (React.createElement(Avatar, { style: __assign({ position: 'relative' }, props.avatarStyle), className: classList, key: user.updatedAt }, (firstName || lastName) && !(firstName === '-' || lastName === '-') ? (React.createElement(TextOverflow, __assign({ variant: 'h3', style: __assign({ fontSize: 24, fontWeight: 600, color: theme.palette.common.white }, props.typographyPropsStyle) }, props.typographyProps),
            (firstName || '').charAt(0).toUpperCase(),
            (lastName || '').charAt(0).toUpperCase())) : (React.createElement(PersonIcon, null))));
    }
    if (detailed) {
        return (React.createElement("div", { style: { position: 'relative' } },
            React.createElement(AvatarItemMoreDetails, __assign({}, props, { user: user })),
            icon));
    }
    return icon;
};
var AvatarItemMoreRating = function (_a) {
    var _b, _c;
    var user = _a.user, item = _a.item;
    var classsName = useAvatarStyles();
    if (!user.rating)
        return null;
    return (React.createElement(Grid, { container: true, className: clsx(classsName.root, (_b = {},
            _b[classsName.awfull] = user.rating < 0.3,
            _b[classsName.ok] = user.rating >= 0.3 && user.rating < 0.5,
            _b[classsName.good] = user.rating >= 0.5 && user.rating < 0.7,
            _b[classsName.great] = user.rating >= 0.7 && user.rating < 1,
            _b[classsName.awesome] = user.rating >= 1,
            _b)) },
        React.createElement(CircularProgress, { variant: "static", style: { width: 28, height: 28, transform: 'rotate(-90deg)' }, className: clsx(classsName.rootProgress, (_c = {},
                _c[classsName.awfull] = user.rating < 0.3,
                _c[classsName.ok] = user.rating >= 0.3 && user.rating < 0.5,
                _c[classsName.good] = user.rating >= 0.5 && user.rating < 0.7,
                _c[classsName.great] = user.rating >= 0.7 && user.rating < 1,
                _c[classsName.awesome] = user.rating >= 1,
                _c)), value: user.rating * 100 }),
        React.createElement(CircularProgress, { variant: "determinate", style: { width: 28, height: 28, transform: 'rotate(-90deg)' }, className: clsx(classsName.rootProgressBack), value: 100 }),
        React.createElement("span", { style: { fontSize: 8, fontWeight: 600 } },
            Math.round(user.rating * 100),
            "%")));
};
var AvatarItemMoreDetails = function (props) {
    var _a;
    var user = props.user, item = props.item;
    return (React.createElement("div", { style: { position: 'relative' } },
        user.appType && user.appType[0] && !props.hideType && (React.createElement(AppType, { type: user.appType[0], avatarStyle: { width: '100%', height: '100%' }, fixed: true })),
        (props.isAdmin && ((_a = user.forwarders) === null || _a === void 0 ? void 0 : _a.length) && (React.createElement("div", { style: { position: 'absolute', top: -16, left: -16, transform: 'scale(0.6)', zIndex: 1 } },
            React.createElement(AvatarItemView, { user: user.forwarders[0], isBorder: true, hoverDetail: true })))) ||
            null,
        item));
};
var AvatarItemMore = function (props) {
    var user = props.user;
    return (React.createElement(Grid, { container: true, alignItems: 'center' },
        React.createElement(AvatarItemMoreDetails, __assign({}, props)),
        React.createElement(Grid, { container: true, alignItems: 'flex-start', direction: 'column', style: {
                maxWidth: 'calc(100% - 40px)',
                paddingLeft: theme.spacing(1),
                paddingTop: theme.spacing(1),
                position: 'relative',
            } },
            React.createElement("div", { style: {
                    position: 'relative',
                    maxWidth: '100%',
                    paddingRight: theme.spacing(4),
                    marginBottom: theme.spacing(1),
                } },
                React.createElement(AvatarItemMoreRating, __assign({}, props)),
                React.createElement(TextOverflow, { variant: 'caption', style: {
                        lineHeight: 1.2,
                        display: 'block',
                        maxWidth: 'calc(100% - 4px)',
                    } }, [user.firstName, user.lastName].filter(function (e) { return e; }).join(' ') || [user.email].filter(function (e) { return e; }).join(' '))),
            React.createElement(TextOverflow, { variant: 'caption', style: {
                    lineHeight: 1,
                    fontSize: 10,
                } }, [user.company && user.company.name, user.company && user.company.vatCode].filter(function (e) { return e; }).join(' ')),
            React.createElement("div", { style: { width: 80 } }))));
};
export var AvatarItem = function (props) {
    var langApp = useTranslation(KEYS.APP);
    var user = props.user;
    var classes = useAvatarStylesV1();
    var classesMain = useMainStyles();
    if (!user)
        return null;
    return (React.createElement(UploadItemFile, { config: props.config, InputProps: { accept: 'image/*' }, UploadProps: __assign(__assign({ type: { _id: '' } }, (props.parent_collection_name && { parent_collection_name: props.parent_collection_name })), { folder: "public/" + (props.folder || 'user') + "/" + user._id + "/", parent: user._id }), onChange: props.onChange, actionInit: function (loading, onShow) {
            var _a;
            return (React.createElement(Grid, { container: true, onClick: onShow, direction: 'column', className: clsx(classes.avatarEdit, (_a = {},
                    _a[classesMain.disabled] = loading,
                    _a)) },
                React.createElement(AvatarItemView, __assign({}, props)),
                React.createElement(Typography, { color: "secondary", className: classes.photoButton }, langApp.t((props.folder || 'profile') + "_picture"))));
        } }));
};
