var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { OrderQuoteState, OrderQuoteStatus, OrderStatus, } from '../../../../../models';
import { OrderQuoteView } from '../../..';
import { BaseOrderQuoteItem } from '../../OrderQuoteItem';
import { OrderQuoteDetails } from './OrderQuoteDetails';
import { BaseUserAppTYpe } from '../../../../../stores';
var OrderQuoteItem = inject('store')(function (p) {
    var order = p.order, match = p.match, store = p.store, status = p.status, onDeleteItem = p.onDeleteItem, expandContent = p.expandContent, onChange = p.onChange, props = __rest(p, ["order", "match", "store", "status", "onDeleteItem", "expandContent", "onChange"]);
    var config = props.config;
    var isCarrier = store.accountStore.currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isForwarder = store.accountStore.currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var statusO = order.status.toLowerCase();
    var additionalContentBody;
    var additionalContentBodyV1;
    var _onChange = function () {
        var e = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            e[_i] = arguments[_i];
        }
        return onChange.apply(void 0, __spread(e, [{ refreshFilter: 1 }]));
    };
    if ([OrderStatus.WaitingQuotes, OrderStatus.HasQuotes].includes(order.status) && (isForwarder || isCarrier)) {
        additionalContentBodyV1 = function (orderDetails) { return (React.createElement(OrderQuoteDetails, { client: config === null || config === void 0 ? void 0 : config.client, order: orderDetails, onChange: _onChange, record: order.latestQuote })); };
    }
    var withTransport = __spread([
        OrderQuoteStatus.Approved.toLowerCase(),
        OrderQuoteStatus.Finished.toLowerCase()
    ], Object.values(OrderQuoteState)).includes(statusO);
    additionalContentBody = function (order, actionsContent) {
        return (React.createElement(OrderQuoteView, { actionsContent: actionsContent, store: store, withTransport: withTransport, order: order, quote: order.latestQuote, config: config }));
    };
    var _props = {
        listOptions: props.listOptions,
        onChange: props.onChange,
        defaultFilter: props.defaultFilter,
        expanded: props.expanded,
        setExpanded: props.setExpanded,
        additionalContentBody: function (order, actionsContent) { return (React.createElement(React.Fragment, null, additionalContentBody && additionalContentBody(order, actionsContent))); },
        additionalContentBodyV1: additionalContentBodyV1,
    };
    if (onDeleteItem) {
        _props.onDeleteItem = onDeleteItem;
    }
    return (React.createElement(BaseOrderQuoteItem, __assign({ canRedirect: true, config: config }, _props, { onChange: onChange, noPadding: withTransport, order: order })));
});
var component = withRouter(OrderQuoteItem);
export default component;
