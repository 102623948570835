var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, useMediaQuery } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Grid } from '@material-ui/core';
import { useIconStyles } from '../styles';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import BaseTooltip from './BaseTooltip';
import { KEYS, i18n } from '../i18n';
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        root: {
            '& input': {
                padding: theme.spacing(1.4, 0),
                paddingLeft: theme.spacing(1.4),
            },
        },
        hidden: {
            display: 'none',
        },
        textarea: (_a = {
                display: 'flex',
                position: 'absolute',
                borderRadius: 0,
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                zIndex: 100
            },
            _a["& fieldset"] = {
                borderRadius: 0,
                border: 'none',
            },
            _a),
        input: {
            height: '100%',
        },
    });
});
var delay;
var BaseSearchComponent = function BaseSearch(_a, ref) {
    var _b;
    var isGlobal = _a.isGlobal, props = __rest(_a, ["isGlobal"]);
    var classes = useStyles();
    var globalIconClasses = useIconStyles();
    var _c = __read(useState(false), 2), openMobileInput = _c[0], setOpenMobileInput = _c[1];
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var propsC = {
        title: 'Search',
    };
    // if (ref) {
    //   debugger;
    //   Object.assign(propsC, { ref });
    // }
    var onRemove = function (e) {
        if (props.onRemove)
            props.onRemove({ target: { name: props.name, value: '' } });
    };
    var onRemoveMobile = function (e) {
        if (props.onRemove)
            props.onRemove({ target: { name: props.name, value: '' } });
        setOpenMobileInput(false);
    };
    var _onChange = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        if (delay)
            clearTimeout(delay);
        // return (delay = setTimeout(() => {
        // try {
        //   if (window.gtag) {
        //     window.gtag.call(window, 'event', 'search', { search_term: value });
        //   }
        // } catch (e) {
        //   console.log(e);
        // }
        if (props.onChange)
            props.onChange({ target: { name: name, value: value } });
        // }, 1000));
    };
    /*eslint-disable */
    useEffect(function () {
        return function () {
            if (delay)
                clearTimeout(delay);
            delay = null;
        };
    }, []);
    /*eslint-enable */
    var textArealClass = openMobileInput ? classes.textarea : classes.hidden;
    if (props.placeholder) {
        propsC.title = props.placeholder;
    }
    propsC["data-title-1"] = propsC.placeholder || propsC.title;
    delete propsC.title;
    var res = (React.createElement(TextField, __assign({ id: props.id || (props.placeholder || 'search').toString().replace(/ /gi, '').toLowerCase() }, propsC, { variant: "outlined", autoComplete: "off", classes: {
            root: classes.root,
        } }, props, { onChange: _onChange, InputProps: {
            endAdornment: (React.createElement(Grid, { container: true, alignItems: "center", style: { width: 'initial', flexWrap: 'nowrap' } },
                (props.defaultValue && (React.createElement(BaseTooltip, { title: i18n.getResource(i18n.language, KEYS.APP, 'form_btn_clear') },
                    React.createElement(InputAdornment, { onClick: onRemove, position: "start", className: globalIconClasses.menuIcon, style: { cursor: 'pointer', marginRight: theme.spacing(1) } },
                        React.createElement(CloseIcon, null))))) ||
                    null,
                React.createElement(InputAdornment, { position: "start", className: globalIconClasses.menuIcon },
                    React.createElement(SearchIcon, null)))),
        } })));
    if (isGlobal && isSmall) {
        res = (React.createElement(React.Fragment, null,
            React.createElement(SearchIcon, { className: globalIconClasses.menuIcon, onClick: function () { return setOpenMobileInput(true); } }),
            React.createElement(TextField, __assign({}, (_b = {}, _b["data-title-1"] = propsC.placeholder || propsC.title, _b), { id: props.id || (props.placeholder || 'search').toString().replace(/ /gi, '').toLowerCase() }, propsC, { variant: "outlined", autoComplete: "off", classes: {
                    root: textArealClass,
                } }, props, { style: __assign({ position: 'absolute' }, (props.style || propsC.style || {})), onChange: _onChange, InputProps: {
                    className: classes.input,
                    endAdornment: (React.createElement(InputAdornment, { className: globalIconClasses.menuIcon, position: "start" },
                        React.createElement(CloseIcon, { onClick: onRemoveMobile }))),
                } }))));
    }
    return res;
};
export default BaseSearchComponent;
export var BaseSearchComponentRef = BaseSearchComponent; //React.forwardRef(BaseSearchComponent);
